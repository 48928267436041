export class Page {
    constructor(
     public pageNumber: number,
     public totalPages: number,
     public totalItems: number,
     public showingItems: number,
     public hasPreviousPage: boolean,
     public hasNextPage: boolean,
     public previousPage: number,
     public nextPage: number,
     public firstPage: number,
     public lastPage: number
    ){}
}