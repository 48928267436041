import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";

export class FlatUpdate{
    constructor(
        public id: number,
        public title?: string,
        public description?: string,
        public city?: string,
        public street?: string,
        public building?: string,
        public apartment?: string,
        public postcode?: string,
        public internalId?: number,
        public accountNumber?: string,
        public accountNumber2?: string,
        public accountNumber3?: string,
        public balance?: number,
        public price?: number,
        public size?: number,
        public countRooms?: number,
        public deposit?: number,
        public adminFees?: number,
        public floor?: number,
        public hasBalcony?: boolean,
        public hasTerrace?: boolean,
        public countParkingSpots?: number,
        public petAllowed?: boolean,
        public yearBuilt?: number,
        public landlordId?: number
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class FlatUpdateAdapter implements Adapter<FlatUpdate> {
    adapt(item: any): FlatUpdate {
        return new FlatUpdate(
            item.id,
            item.title,
            item.description,
            item.city,
            item.street,
            item.building,
            item.apartment,
            item.postcode,
            item.internalId,
            item.accountNumber,
            item.accountNumber2,
            item.accountNumber3,
            item.balance,
            item.price,
            item.size,
            item.countRooms,
            item.deposit,
            item.adminFees,
            item.floor,
            item.hasBalcony,
            item.hasTerrace,
            item.countParkingSpots,
            item.petAllowed,
            item.yearBuilt,
            item.landlordId
        )
    }
    
}