import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";

import { CompanyUpdate } from '../models/settings/companyUpdate';
import { Company, CompanyAdapter } from '../models/settings/company';
import { environment } from 'environments/environment';

@Injectable()
export class CompanyService {
    private url = environment.baseApiurl + "/admincompany";

    constructor(private http: HttpClient, private adapter: CompanyAdapter) {
    }

    updateCompany(updatedCompany: CompanyUpdate) {
        return this.http.put(this.url + '/UpdateCompanyInfo', updatedCompany);
    }

    getCompany() {
        return this.http.get(this.url + '/GetCompanySettings')
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }
}