import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Notification, NotificationAdapter} from '../models/notification/notification';
import { NotificationCreate, NotificationCreateAdapter} from '../models/notification/notificationCreate';
import { NotificationService } from './notification.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
  providers: [NotificationService]
})
export class NotificationComponent implements OnInit {

    rows: any[];
    temp = [];
    item: NotificationCreate = new NotificationCreate(); 

    constructor(private notificationService: NotificationService,
                private route: ActivatedRoute,
                private router: Router,
                private toastr: ToastrService,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
        this.loadNotifications();
    }

    sendNotification(): void {
        if (this.item.isClient)
        {
            this.notificationService.sendClientNotification(this.item).subscribe(data => {
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
                this.loadNotifications();
            }); 
        } 
        else
        {
            this.notificationService.sendDriverNotification(this.item).subscribe(data => {
                this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
                this.loadNotifications();
            });
        }      
    }


    private loadNotifications() {
        this.notificationService.getNotification().subscribe((data: Notification[]) => {
            this.temp = [...data];
            this.rows = data;        
        })
    }

    updateFilter1(event) {
        const val = event.target.value.toLowerCase();
        const temp = this.temp.filter(function (d) {
            return d.title.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.rows = temp;
    }
}