<!--
<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'ClientManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'ClientManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title" id="horz-layout-colored-controls">{{'ProfileClientTitle' | translate}} (ID: {{client.id}})</h4>
                    <h6 class="info">{{'RegistrationDate' | translate}}: {{client.registrationDate | date:'dd/MM/yy h:mm:ss a'}}</h6>
                    <p class="mb-0">{{'ProfileClientDescription' | translate}}</p>
                </div>
                <div class="card-content">
                    <div class="px-3">
                    <form class="form">
                        <div class="form-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="userinput1">{{'Phone' | translate}} *</label>
                                        <input [(ngModel)]="client.phoneNumber" placeholder="{{'EnterThe' | translate}}{{'Phone' | translate}}" 
                                                name="phonenumber" class="form-control" id="phone" readonly="readonly">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="userinput1">{{'Name' | translate}}</label>
                                        <input [(ngModel)]="client.name" placeholder="{{'EnterThe' | translate}}{{'Name' | translate}}" name="name" 
                                        class="form-control" id="name" #name="ngModel" 
                                        pattern="[a-zA-Zа-яА-Я- ]{1,50}">
                                        <div [hidden]="name.valid || name.untouched" class="text-danger">
                                            {{'FieldValidation1-50' | translate}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="userinput2">{{'Email' | translate}}</label>
                                        <input type="email" [(ngModel)]="client.email" placeholder="{{'EnterThe' | translate}}{{'Email' | translate}}" name="email" 
                                                class="form-control" id="email" #email="ngModel" 
                                                pattern="[a-zA-Z0-9@-_-.]{10,50}">
                                        <div [hidden]="email.valid || email.untouched" class="text-danger">
                                            {{'FieldEmailValidation' | translate}}
                                        </div>
                                    </div>
                                </div>                          
                            </div> 
                        </div>
        
                        <div class="right">
                            <button type="button" [disabled]="name.invalid || email.invalid" 
                                    class="btn btn-raised btn-primary" (click)="updateClient()"> 
                                <i class="fa fa-check-square-o"></i> {{'Save' | translate}}
                            </button>
                            <button type="button" class="btn btn-raised btn-warning mr-1" (click)="confirmCancel()"> 
                                <i class="ft-x"></i> {{'Cancel' | translate}}
                            </button>                       
                        </div>
                    </form>
        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title" id="horz-layout-colored-controls">{{'ClientFeedbackTitle' | translate}}</h4>
                    <p class="mb-0">{{'ClientFeedbackDescription' | translate}}</p>
                </div>
                <div class="card-content">
                    <div *ngIf="rows.length == 0 ; else clientFeedbacks">
                        <div class="col-md-12">
                            <div class="content-header text-center">{{'ClientNotFeedbackTitle' | translate}}</div>    
                        </div>                                                                             
                    </div>                      
                    <ng-template #clientFeedbacks>
                        <div class="col-md-12">
                                <section id="basic-datatable" class="mb-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <ngx-datatable class="bootstrap" [rows]="rows" 
                                                        [columns]="[{name:'id'},{name:'rating'},{name:'comment'}]"
                                                        [columns]="columns"
                                                        [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                                                        [rowHeight]="'auto'" [limit]="3">
                                            </ngx-datatable>
                                        </div>
                                    </div>
                                </section>
                            </div> 
                    </ng-template>                           
                </div>
            </div>
        </div>
    </div>    
</section>-->

<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'ClientManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'ClientManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-content">
            <div class="card-header pb-2">
                <h4 class="card-title" id="horz-layout-colored-controls">{{'ProfileClientTitle' | translate}} (ID: {{client.id}})</h4>
            </div>
            <div class="card-body">
              <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs justify-content-left">
                <li [ngbNavItem]="1">
                  <a ngbNavLink class="nav-link d-flex align-items-center">
                    <i class="ft-user mr-1"></i>
                    <span class="d-none d-sm-block">Account</span>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="mt-2" id="account">
                        <form class="form">
                            <div class="form-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <p class="mb-0">{{'ProfileClientDescription' | translate}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="userinput1">{{'Phone' | translate}} *</label>
                                            <input [(ngModel)]="client.phoneNumber" placeholder="{{'EnterThe' | translate}}{{'Phone' | translate}}" 
                                                    name="phonenumber" class="form-control" id="phone" readonly="readonly">
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="userinput1">{{'Name' | translate}}</label>
                                            <input [(ngModel)]="client.name" placeholder="{{'EnterThe' | translate}}{{'Name' | translate}}" name="name" 
                                            class="form-control" id="name" #name="ngModel" 
                                            pattern="[a-zA-Zа-яА-Я- ]{1,50}">
                                            <div [hidden]="name.valid || name.untouched" class="text-danger">
                                                {{'FieldValidation1-50' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="userinput2">{{'Email' | translate}}</label>
                                            <input type="email" [(ngModel)]="client.email" placeholder="{{'EnterThe' | translate}}{{'Email' | translate}}" name="email" 
                                                    class="form-control" id="email" #email="ngModel" 
                                                    pattern="[a-zA-Z0-9@-_-.]{10,50}">
                                            <div [hidden]="email.valid || email.untouched" class="text-danger">
                                                {{'FieldEmailValidation' | translate}}
                                            </div>
                                        </div>
                                    </div>                          
                                </div>
                                <div class="row">
                                    <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                        <button type="button" [disabled]="name.invalid || email.invalid" 
                                            class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateClient()"> 
                                            {{'Save' | translate}}
                                        </button>
                                        <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                            {{'Cancel' | translate}}
                                        </button> 
                                    </div>
                                </div> 
                            </div>
                        </form>
                    </div>
                  </ng-template>
                </li>

                <li [ngbNavItem]="2">
                  <a ngbNavLink class="nav-link d-flex align-items-center">
                    <i class="ft-info mr-1"></i>
                    <span class="d-none d-sm-block">Information</span>
                  </a>
                  <ng-template ngbNavContent>
                    <div class="mt-2">
                        <div *ngIf="orders.length == 0 ; else clientOrders">
                            <div class="col-md-12">
                                <div class="content-header text-center">Сlient has no trips yet</div>    
                            </div>                                                                             
                        </div>                      
                        <ng-template #clientOrders> <!--TODO: FIX styles later-->
                            <div class="col-md-12">
                                    <!--Basic DataTable Starts-->
                                    <section  class="mb-3">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <ngx-datatable 
                                                [rows]="orders" 
                                                [columnMode]="'force'"
                                                class="bootstrap core-bootstrap" 
                                                [headerHeight]="50" 
                                                [footerHeight]="50"
                                                [externalPaging]="true"
                                                [count]="20"
                                                [limit]="10"
                                                rowHeight="auto" 
                                                (page)="setPage($event)">
                                                <ngx-datatable-column name="ID" prop="id" [width]="20"></ngx-datatable-column>
                                                <ngx-datatable-column name="{{'StartDate' | translate}}" >
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.startTime | date:'dd/MM h:mm a'}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Pick up">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.points[0]?.address}}
                                                    </ng-template>
                                                </ngx-datatable-column>                     
                                                <ngx-datatable-column name="{{'EndDate' | translate}}" >
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.endTime | date:'dd/MM h:mm a'}}
                                                    </ng-template>
                                                </ngx-datatable-column>
                                                <ngx-datatable-column name="Drop off" >
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                        {{row.points[1]?.address}}
                                                    </ng-template>
                                                </ngx-datatable-column>  
                                                <ngx-datatable-column name="{{'Status' | translate}}" prop="orderStatus">
                                                    <ng-template let-row="row" ngx-datatable-cell-template>
                                                      <div class="badge" [ngClass]="{
                                                        'bg-light-info': row.orderStatus === 'NewOrder',
                                                        'bg-light-secondary': row.orderStatus === 'DriverRides',
                                                        'bg-light-secondary': row.orderStatus === 'DriverWaiting',
                                                        'bg-light-secondary': row.orderStatus === 'ExecutingOrder',
                                                        'bg-light-success': row.orderStatus === 'OrderCompleted', 
                                                        'bg-light-danger': row.orderStatus === 'ClientCancellation', 
                                                        'bg-light-danger': row.orderStatus === 'DriverCancellation', 
                                                        'bg-light-warning': row.orderStatus === 'DriverNotFound' }">
                                                        {{row.orderStatus === 'DriverNotFound' ? 'Not found' : 
                                                            (row.orderStatus === 'DriverCancellation' ? 'Cancel' : 
                                                              (row.orderStatus === 'OrderCompleted' ? 'Completed' : 
                                                                (row.orderStatus === 'ExecutingOrder' ? 'On the way' : 
                                                                  (row.orderStatus === 'DriverWaiting' ? 'Pickup' : 
                                                                    (row.orderStatus === 'DriverRides' ? 'Arriving' : 
                                                                      (row.orderStatus === 'NewOrder' ? 'Search' : 'Search' )))))) }}
                                                      </div>
                                                    </ng-template>
                                                </ngx-datatable-column>      
                                                <ngx-datatable-column name="{{'Cost' | translate}}" prop="totalSum" ></ngx-datatable-column> 
                                                <ngx-datatable-column name="Actions">
                                                    <ng-template let-row="row"  ngx-datatable-cell-template>
                                                        <a class="info p-0" data-original-title="" title="view" (click)="viewOrdersPage(row.id)">
                                                            <i class="ft-more-vertical text-primary cursor-pointer"></i>
                                                        </a>
                                                    </ng-template>
                                                </ngx-datatable-column>
                                            </ngx-datatable>
                                            </div>
                                        </div>
                                    </section>
                                    <!--Basic DataTable Ends-->
                                </div> 
                        </ng-template>       
                    </div>

                    <div class="mt-2">
                        <div *ngIf="rows.length == 0 ; else clientFeedbacks">
                            <div class="col-md-12">
                                <div class="content-header text-center">{{'ClientNotFeedbackTitle' | translate}}</div>    
                            </div>                                                                             
                        </div>                      
                        <ng-template #clientFeedbacks> <!--TODO: FIX styles later-->
                            <div class="col-md-12">
                                    <!--Basic DataTable Starts-->
                                    <section id="basic-datatable" class="mb-3">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <ngx-datatable class="bootstrap" [rows]="rows" 
                                                            [columns]="[{name:'id'},{name:'rating'},{name:'comment'}]"
                                                            [columns]="columns"
                                                            [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
                                                            [rowHeight]="'auto'" [limit]="3">
                                                </ngx-datatable>
                                            </div>
                                        </div>
                                    </section>
                                    <!--Basic DataTable Ends-->
                                </div> 
                        </ng-template>       
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div class="tab-content">
                <div [ngbNavOutlet]="nav"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  