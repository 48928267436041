import { Sex } from '../enums/sex';

export class DriverCreate {
    constructor(
        public firstName?: string,
        public secondName?: string,
        public lastName?: string,
        public email?: string,
        public phoneNumber?: string,
        public hasPrivateCar?: boolean,
        public sex?: Sex,
        public comment?: string
    ) {};
}