import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { ChangePassword } from 'app/models/profile/changepPassword';
import { UserProfileUpdate } from 'app/models/profile/userProfileUpdate';
import { UserProfileService } from './user.service';
import { UserProfile } from 'app/models/profile/userProfile';


@Component({
    selector: 'app-order',
    templateUrl: './profile.component.html',
    providers: [UserProfileService]
})
export class ProfileComponent implements OnInit {

    item: UserProfileUpdate;
    message: string;

    constructor(private router: Router,
                private profileService: UserProfileService,
                private route: ActivatedRoute,
                private toastr: ToastrService,
                private translate: TranslateService) {

    }
    
    ngOnInit(): void {
        this.getById();
    }

    private getById() {
        this.profileService.getById().subscribe((data: UserProfile) => {
            this.item = data;
        });
    }

    update(): void {
        this.profileService.update(this.item).subscribe(data => {
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        });
    }

    changePassword(): void {
        /*if (this.item.newPassword !== this.item.repeatNewPassword) {
            this.message = "New passwords do not match";
            return;
        };

        this.profileService.updatePassword(this.item).subscribe(data => {
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        });*/
    }

    confirmCancel() {
        console.log('start');
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/flats/allflats']);
            }
        })
    }
}