import { Injectable } from "@angular/core";
import { EmployeeRole } from "../enums/employeeRole";
import { Adapter } from "app/core/interfaces/adapter";

export class StaffUpdate {
    constructor(
        public id: number,
        public email?: string,
        public name?: string,
        public comment?: string,
        public phoneNumber?: string,
        public role?: EmployeeRole,
        public isBlocked?: boolean
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class StaffUpdateAdapter implements Adapter<StaffUpdate> {
    adapt(item: any): StaffUpdate {
        return new StaffUpdate(
            item.id,
            item.email,
            item.name,
            item.comment,
            item.phoneNumber,
            item.role,
            item.isBlocked
        )
    }
}