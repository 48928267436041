import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';
import { Page } from '../common/page';
import { Order, OrderDetails, Point } from './order';
import { OrderSortState } from './orderSortState';
import { Feedback } from '../feedback/feedback';
import { Currency } from './currency';

export class OrderIndex {
    constructor(
        public items: Order[],
        public page: Page,
        public sort: OrderSortState
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class OrderIndexAdapter implements Adapter<OrderIndex> {
    adapt(data: any): OrderIndex {
        let mappedOrders: Order[] = [];
        if (data.orders != null) {
            for (let order of data.orders) {
                let points = order.points.map(item => {
                    let convertedTimeArrivedPoint = (item.startTime == null) ? null : new Date(item.startTime * 1000);
                    let convertedTimeLeftPoint = (item.endTime == null) ? null : new Date(item.endTime * 1000);
                    return new Point(
                        item.id,
                        item.indexNumber,
                        item.lat,
                        item.lng,
                        item.status,
                        convertedTimeArrivedPoint,
                        convertedTimeLeftPoint,
                        item.address
                    )
                })

                let convertedStartTimeWaiting = (order.orderDetails.startTimeWaiting == null) ? null : new Date(order.orderDetails.startTimeWaiting * 1000);
                let details: OrderDetails =  new OrderDetails(
                    order.orderDetails.id,
                    order.orderDetails.percentageCompany,
                    order.orderDetails.percentageCancellation,
                    convertedStartTimeWaiting,
                    order.orderDetails.distanceCoveredCity,
                    order.orderDetails.distanceCoveredCountrySide,
                    order.orderDetails.timeWaiting,
                    order.orderDetails.timeFreeWaiting,
                    order.orderDetails.freeDistance,
                    order.orderDetails.costPaidWaiting,
                    order.orderDetails.costDistanceCity,
                    order.orderDetails.costDistanceCountrySide
                )


                let currency: Currency;
                if (order.currency != null) {
                    currency = new Currency(
                        order.currency.isBegin,
                        order.currency.currency
                    )
                }

                let feedback: Feedback;
                if (order.feedback != null) {
                    let convertedCreateTime = (order.feedback.createTime == null) ? null :  new Date(order.feedback.createTime * 1000);
                    feedback = new Feedback(
                        order.feedback.id,
                        order.feedback.rating,
                        order.feedback.comment,
                        convertedCreateTime
                    )
                }

                let convertStartTimeOrder = (order.startTime == null) ? null :  new Date(order.startTime * 1000);
                let convertedEndTimeOrder = (order.endTime == null) ? null : new Date(order.endTime * 1000);
                let newOrder = new Order(
                    order.id,
                    order.taxiFareType,
                    convertStartTimeOrder,
                    convertedEndTimeOrder,
                    order.orderStatus,
                    order.totalSum,
                    order.paymentMethod,
                    new Date(order.createTime * 1000),
                    points,
                    order.user,
                    order.driver,
                    order.car,
                    details,
                    currency,
                    feedback
                )
                mappedOrders.push(newOrder);
            }

            let page: Page;
            if (data.page != null) {
                page = new Page(
                    data.page.pageNumber,
                    data.page.totalPages,
                    data.page.totalItems,
                    mappedOrders.length,
                    data.page.hasPreviousPage,
                    data.page.hasNextPage,
                    data.page.previousPage,
                    data.page.nextPage,
                    data.page.firstPage,
                    data.page.lastPage
                )
            }

            return new OrderIndex(
                mappedOrders,
                page,
                data.sort.currentOrder
            )
        }
    }
}