import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

import { User, UserUpdateAdapter } from '../models/client/user';
import { UserUpdate } from '../models/client/userUpdate';
import { ClientService } from '../user/client.service';
import { FeedbackService } from '../feedbacks/feedback.service';
import { Feedback, FeedbackAdapter} from '../models/feedback/feedback';
import { TranslateService } from '@ngx-translate/core';

@Component( {
    selector: 'app-root',
    templateUrl: './profile-user.component.html',
    providers: [ClientService, FeedbackService]
})
export class ProfileUserComponent implements OnInit {

    client: User;
    rows = [];
    orders = [];

    constructor(private clientService: ClientService,
                private feedbackService: FeedbackService,
                private route: ActivatedRoute,
                private router: Router,
                private adapter: UserUpdateAdapter,
                private toastr: ToastrService,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        let userId : number = Number(this.route.snapshot.paramMap.get("id"));
        this.getById(userId);
        this.getFeedbacks(userId);
    }

    updateClient(): void {
        let updatedUser = this.adapter.adapt(this.client);
        this.clientService.updateClient(updatedUser).subscribe(data => {
            //this.router.navigate(['/clients/allclients']);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    getRowHeight(row) {
        return row.height;
    }

    private getById(id: number): void {
        this.clientService.getClient(id).subscribe((data: User) => {
            this.client = data;
        })
    };

    private getFeedbacks(id: number): void {
        this.feedbackService.getClientFeedbacks(id).subscribe((data: Feedback[]) => {
            //this.feedbacks = data;
            this.rows = data;
        })
    }

    // Confirm Button Action
    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/clients/allclients']);
            }
        })

    }

}
