import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from 'environments/environment';
import { UserProfileUpdate } from "app/models/profile/userProfileUpdate";
import { UserProfileAdapter } from "app/models/profile/userProfile";

@Injectable()
export class UserProfileService {
    private url = environment.baseApiurl + "/user";

    constructor(private http: HttpClient,
                private adapter: UserProfileAdapter) {
    }

    getById() {
        return this.http.get(this.url + '/getProfile')
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }

    update(updatedItem: UserProfileUpdate) {
        return this.http.put(this.url + '/update', updatedItem);
    }
}