<section  id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'LandlordManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'LandlordManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-content">
                    <div class="card-header pb-2">
                        <div class="row">
                            <div class="col-md-8">
                                <h4 class="card-title" id="horz-layout-colored-controls">{{'ProfileLandlordTitle' | translate}} (ID: {{item.id}})</h4>
                                <p class="secondary">{{'RegistrationDate' | translate}}: {{item.createTime | date:'dd/MM/yy h:mm:ss a'}} 
                                    | {{'LastUpdate' | translate}}: {{item.updateTime | date:'dd/MM/yy h:mm:ss a'}}</p>
                            </div>
                            <div class="col-md-4">
                                <h2 class="card-title text-right">
                                    Balance: {{balance}} PLN
                                </h2>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs justify-content-left">
                          <li [ngbNavItem]="1">
                            <a ngbNavLink class="nav-link d-flex align-items-center">
                              <i class="ft-user mr-1"></i>
                              <span class="d-none d-sm-block">{{'Account' | translate}}</span>
                            </a>
                            <ng-template ngbNavContent>
                              <div class="mt-2">
                                <form class="form">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p class="mb-0">{{'ProfileLandlordDescription' | translate}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>{{'FirstName' | translate}} *</label>
                                                    <input [(ngModel)]="item.firstName" placeholder="{{'EnterThe' | translate}}{{'FirstName' | translate}}" 
                                                        name="firstName" #firstName="ngModel" class="form-control"
                                                        pattern="[a-zA-Zа-яА-Я- ]{1,50}" required>
                                                    <div [hidden]="firstName.valid || firstName.untouched" class="text-danger">
                                                        {{'FieldValidation1-50' | translate}}
                                                    </div>
                                                </div>
                                            </div>    
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>{{'LastName' | translate}} *</label>
                                                    <input [(ngModel)]="item.lastName" placeholder="{{'EnterThe' | translate}}{{'LastName' | translate}}" name="name" 
                                                        class="form-control" id="lastName" #lastName="ngModel" 
                                                        pattern="[a-zA-Zа-яА-Я- ]{1,50}" required>
                                                    <div [hidden]="lastName.valid || lastName.untouched" class="text-danger">
                                                        {{'FieldValidation1-50' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>{{'Email' | translate}} *</label>
                                                    <input type="email" [(ngModel)]="item.email" placeholder="{{'EnterThe' | translate}}{{'Email' | translate}}" name="email" 
                                                        class="form-control" id="email" #email="ngModel" 
                                                        pattern="[a-zA-Z0-9@-_-.]{10,50}" required>
                                                    <div [hidden]="email.valid || email.untouched" class="text-danger">
                                                        {{'FieldEmailValidation' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>{{'PhoneNumber' | translate}} *</label>
                                                    <input [(ngModel)]="item.phoneNumber" 
                                                        placeholder="{{'EnterThe' | translate}} {{'PhoneNumber' | translate}}" 
                                                        name="phoneNumber" class="form-control" 
                                                        #phoneNumber="ngModel" maxlength="50" required>
                                                    <div [hidden]="phoneNumber.valid || phoneNumber.untouched" class="text-danger">
                                                        {{'FieldEmailValidation' | translate}}
                                                    </div>
                                                </div>
                                            </div>                   
                                        </div>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>{{'AccountNumber' | translate}}</label>
                                                    <input [(ngModel)]="item.accountNumber" 
                                                        placeholder="{{'EnterThe' | translate}}{{'AccountNumber' | translate}}" 
                                                        name="accountNumber" class="form-control" 
                                                        id="accountNumber" #accountNumber="ngModel"
                                                        mask="00 0000 0000 0000 0000 0000 0000">
                                                    <div [hidden]="accountNumber.valid || accountNumber.untouched" class="text-danger">
                                                        {{'FieldValidation1-50' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>{{'Status' | translate}} *</label>
                                                    <select class="form-control" [(ngModel)]="item.status" 
                                                        name="itemStatus" required>
                                                        <option *ngFor="let status of userStatus" value="{{status}}">
                                                            {{status}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>                                            
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label>{{'Comment' | translate}}</label>
                                                    <textarea [(ngModel)]="item.comment" id="bdescription" rows="3" 
                                                        class="form-control" name="description"
                                                        #comment="ngModel" maxlength="200"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                                <button type="button" [disabled]="firstName.invalid || lastName.invalid || 
                                                    email.invalid || accountNumber.invalid || phoneNumber.invalid" 
                                                    class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="update()"> 
                                                    {{'Save' | translate}}
                                                </button>
                                                <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                                    {{'Cancel' | translate}}
                                                </button> 
                                            </div>
                                        </div> 
                                       
                                    </div>
                                </form>
                              </div>
                            </ng-template>
                          </li>
          
                          <li [ngbNavItem]="2">
                            <a ngbNavLink class="nav-link d-flex align-items-center">
                              <i class="ft-home mr-1"></i>
                              <span class="d-none d-sm-block">Flats</span>
                            </a>
                            <ng-template ngbNavContent>
                                <ngx-datatable class="bootstrap core-bootstrap" 
                                    [rows]="flats" 
                                    [columnMode]="'force'" 
                                    [headerHeight]="50" 
                                    [footerHeight]="50"
                                    [rowHeight]="'auto'"
                                    [limit]="15">
                                    <ngx-datatable-column name="#" prop="flat.id"></ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Title' | translate}}" prop="flat.title">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <a class="primary p-0" data-original-title="" (click)="viewPage(row.flat.id)">
                                                {{row.flat.title}}
                                            </a>
                                        </ng-template> 
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Balance' | translate}}" prop="flat.balance"  ></ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Rent' | translate}}" prop="rent"></ngx-datatable-column>
                                    <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{row.flat.createTime | date:'dd/MM/yy h:mm a'}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Actions">
                                        <ng-template let-row="row"  ngx-datatable-cell-template>
                                          <a class="danger p-0" data-original-title="" title="edit" (click)="viewPage(row.flat.id)">
                                            <i class="ft-edit text-primary cursor-pointer"></i>
                                          </a>
                                        </ng-template>
                                    </ngx-datatable-column> 
                                </ngx-datatable>    
                            </ng-template>
                          </li>
                          
                          <li [ngbNavItem]="3">
                            <a ngbNavLink class="nav-link d-flex align-items-center">
                              <i class="ft-dollar-sign mr-1"></i>
                              <span class="d-none d-sm-block">Bills</span>
                            </a>
                            <ng-template ngbNavContent>
                                <ngx-datatable class="bootstrap core-bootstrap" 
                                    [rows]="invoices" 
                                    [columnMode]="'force'" 
                                    [headerHeight]="50" 
                                    [footerHeight]="50"
                                    [rowHeight]="'auto'"
                                    [limit]="15">
                                    <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                    {{row.status}}
                                                </span>
                                            </div>                      
                                            <ng-template #nonActiveBadge>
                                                <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                    {{row.status}}
                                                </span>                       
                                            </ng-template>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Title' | translate}}" prop="title"  >
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <a class="primary p-0" data-original-title="" (click)="viewPage(row.flat.id)">
                                                {{row.title}}
                                            </a>
                                        </ng-template> 
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Description' | translate}}" prop="description"></ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                    <ngx-datatable-column name="{{'Apartment' | translate}}" >
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            <a class="primary p-0" data-original-title="" title="edit" (click)="viewPage(row.flat.id)">
                                                {{row.flat.title}}
                                            </a>
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                        <ng-template let-row="row" ngx-datatable-cell-template>
                                            {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                        </ng-template>
                                    </ngx-datatable-column>
                                    <ngx-datatable-column name="Actions">
                                        <ng-template let-row="row"  ngx-datatable-cell-template>
                                          <a class="danger p-0" data-original-title="" title="edit" (click)="viewPage(row.flat.id)">
                                            <i class="ft-edit text-primary cursor-pointer"></i>
                                          </a>
                                        </ng-template>
                                    </ngx-datatable-column> 
                                </ngx-datatable>
                            </ng-template>
                          </li>
                        </ul>
                        <div class="tab-content">
                          <div [ngbNavOutlet]="nav"></div>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>
</section>