<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'DriverManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'DriverManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
            <div class="card-header pb-2">
                <h4 class="card-title" id="horz-layout-colored-controls">{{'CreateDriverTitle' | translate}}</h4>
                <div [innerHTML]="'CreateDriverDescription' | translate"></div>
            </div>
            <div class="card-content">
                <div class="px-3">
                <form class="form">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="userinput1">{{'FirstName' | translate}}: </label>
                                    <input [(ngModel)]="driver.firstName" placeholder="{{'EnterThe' | translate}} {{'FirstName' | translate}}" name="firstName" 
                                            class="form-control" id="secondName" #firstName="ngModel" 
                                            pattern="[a-zA-Zа-яА-Я- ]{1,50}">
                                    <div [hidden]="firstName.valid || firstName.untouched" class="text-danger">
                                        {{'FieldValidation1-50' | translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="userinput2">{{'SecondName' | translate}}: </label>
                                    <input [(ngModel)]="driver.secondName" placeholder="{{'EnterThe' | translate}} {{'SecondName' | translate}}" 
                                            name="secondName"  class="form-control" id="secondName" #secondName="ngModel" 
                                            pattern="[a-zA-Zа-яА-Я- ]{1,50}">
                                    <div [hidden]="secondName.valid || secondName.untouched" class="text-danger">
                                        {{'FieldValidation1-50' | translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                 <div class="form-group">
                                    <label for="userinput2">{{'LastName' | translate}}: </label>
                                    <input [(ngModel)]="driver.lastName" placeholder="{{'EnterThe' | translate}} {{'LastName' | translate}}" 
                                            name="lastName"  class="form-control" id="lastName" #lastName="ngModel" 
                                            pattern="[a-zA-Zа-яА-Я- ]{1,50}">
                                    <div [hidden]="lastName.valid || lastName.untouched" class="text-danger">
                                        {{'FieldValidation1-50' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div class="row">
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="userinput2">{{'Email' | translate}}: </label>
                                    <input type="email" [(ngModel)]="driver.email" placeholder="{{'EnterThe' | translate}} {{'Email' | translate}}" name="email" 
                                            class="form-control" id="email" #email="ngModel" 
                                            pattern="[a-zA-Z0-9@-_-.]{10,50}">
                                    <div [hidden]="email.valid || email.untouched" class="text-danger">
                                        {{'FieldEmailValidation' | translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group">
                                    <label for="userinput1">{{'Phone' | translate}}*: </label>
                                    <input [(ngModel)]="driver.phoneNumber" placeholder="{{'EnterThe' | translate}} {{'Phone' | translate}}" name="phoneNumber" 
                                            class="form-control" id="phone" #phoneNumber="ngModel" 
                                            required pattern="[+][0-9]{8,12}">
                                    <div [hidden]="phoneNumber.valid || phoneNumber.untouched" class="text-danger">
                                        {{'FieldPhoneValidation' | translate}}
                                    </div>
                                </div>
                            </div> 
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="userinput2">{{'Comment' | translate}}: </label>
                                    <input [(ngModel)]="driver.comment" placeholder="{{'EnterThe' | translate}} {{'Comment' | translate}}" 
                                            name="comment"  class="form-control" id="comment" #comment="ngModel" 
                                            pattern="^.{1,2000}$">
                                    <div [hidden]="comment.valid || comment.untouched" class="text-danger">
                                        {{'FieldValidation1-2000' | translate}}
                                    </div>
                                </div>
                            </div>  
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="carcolor">{{'Sex' | translate}}*: </label>
                                    <select class="form-control" [(ngModel)]="driver.sex" 
                                            name="sex" #sex="ngModel" required>
                                        <option *ngFor="let sex of listSex" value="{{sex}}">
                                            {{sex}}
                                        </option>
                                    </select>
                                    <div [hidden]="sex.valid || sex.untouched" class="text-danger">
                                        {{'FieldRequired' | translate}}
                                    </div>                          
                                </div>
                            </div>                       
                        </div>

                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" [(ngModel)]="driver.hasPrivateCar" checked="driver.hasPrivateCar" 
                                            name="hasPrivateCar" class="custom-control-input" id="checkCash">
                                        <label class="custom-control-label" for="checkCash">{{'DriverWorkOnOwnCar' | translate}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
  
                    <div class="form-actions right">
                            <!--<a href="/clients/allclients" class="btn btn-raised btn-warning mr-1"><i class="ft-x"></i> Cancel</a>-->
                        <button type="button" class="btn btn-raised btn-warning mr-1" (click)="confirmCancel()"> 
                            <i class="ft-x"></i> {{'Cancel' | translate}}
                        </button>
                        <button type="button" [disabled]="firstName.invalid || secondName.invalid ||
                                    lastName.invalid || email.invalid || phoneNumber.invalid || comment.invalid" 
                                class="btn btn-raised btn-primary" (click)="createDriver()"> 
                            <i class="fa fa-check-square-o"></i> {{'Save' | translate}}
                        </button>
                    </div>
                </form>
    
                </div>
            </div>
            </div>
        </div>
    </div>    
</section>