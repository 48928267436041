import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';

import { User } from '../models/client/user';
import { UserIndex } from '../models/client/userIndex';
import { ClientService } from '../user/client.service';
import { ClientSortState } from '../models/client/clientSortState';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css', '../../assets/sass/libs/datatables.scss'],
  providers: [ClientService]
})
export class UserComponent implements OnInit {

  clients: User[];
  user: UserIndex;

  page: number;
  size: number;
  sort: ClientSortState;

  private routeSubscription: Subscription;
  private querySubscription: Subscription;

  constructor(private clientService: ClientService,
              private route: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private toastr: ToastrService) {

  }

  ngOnInit(): void {
    this.querySubscription = this.route.queryParams.subscribe(
      (queryParam: any) => {
          this.page = queryParam['page'];
          this.size = queryParam['size'];
          this.sort = queryParam['sort'];
      }
    );
    this.size = 20;
    this.loadPartClients(this.page, this.size, this.sort);
  };

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo) {
    this.loadPartClients(this.page, this.size, this.sort);
  }

  viewPage(id: any){
    this.router.navigate(['/clients/'+ id]);
  }

  blockClient(id: number): void {
    this.clientService.blockClient(id).subscribe(data => {
      //this.loadClients(); old method
      this.loadPartClients(this.page, this.size, this.sort);
      this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
    })
  }

  unblockClient(id: number): void {
    this.clientService.unblockClient(id).subscribe(data => {
      this.loadPartClients(this.page, this.size, this.sort);
      this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
    })
  }

  private loadClients(): void {
    this.clientService.getClients().subscribe((data: User[]) => {
      this.clients = data;
    });
  }

  private loadPartClients(page: number = 1, size: number, sort: ClientSortState = ClientSortState.IdDesc): void {
    this.clientService.getPage(page, size, sort).subscribe((data: UserIndex) => {
      this.user = data;
    })
  }


  confirmBlockClient(id: number) {
    swal.fire({
        title: this.translate.instant('BlockIt?'),
        text: this.translate.instant('AbleRevert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('Cancel'),
        confirmButtonText: this.translate.instant('Yes')
    }).then((result) => {
        if (result.value) {
            this.blockClient(id);
        }
    })
  }


  confirmUnblockClient(id: number) {
    swal.fire({
        title: this.translate.instant('UnblockIt?'),
        text: this.translate.instant('AbleRevert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('Cancel'),
        confirmButtonText: this.translate.instant('Yes')
    }).then((result) => {
        if (result.value) {
            this.unblockClient(id);
        }
    })
  }

  getLinkOnReport() {
    this.clientService.getXLSXwithClients().subscribe((data: string) => {
      window.open(data);
    })
  }

}
