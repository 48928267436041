import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";

export class EquipmentUpdate{
    constructor(
        public files: File[] = [],
        public id: number,
        public title?: string,
        public description?: string,
        public comment?: string
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class EquipmentUpdateAdapter implements Adapter<EquipmentUpdate> {
    adapt(item: any): EquipmentUpdate {
        return new EquipmentUpdate(
            item.files,
            item.id,
            item.title,
            item.description,
            item.comment
        )
    }

}