import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';

import { User } from '../client/user';
import { Driver } from '../driver/driver';
import { OrderStatus } from '../enums/orderstatus';
import { TaxiFareType } from '../enums/taxifaretype';
import { PaymentMethodType } from '../enums/paymentMethod';
import { Car } from '../car/car';
import { PointStatus } from '../enums/pointstatus';
import { Feedback } from '../feedback/feedback';
import { Currency } from './currency';

export class Order {
    constructor(
        public id?: number,
        public taxiFareType?: TaxiFareType,
        public startTime?: Date,
        public endTime?: Date,
        public orderStatus?: OrderStatus,
        public totalSum?: number,
        public paymentMethodType?: PaymentMethodType,
        public createTime?: Date,
        public points?: Point[],
        public user?: User,
        public driver?: Driver,
        public car?: Car,
        public orderDetails?: OrderDetails,
        public currency?: Currency,
        public feedback?: Feedback
        //paymentcard
        // taxifarebase
    ) {};
}

export class OrderDetails {
    constructor(
        public id: number,
        public percentageCompany: number,
        public percentageCancellation: number,
        public startTimeWaiting?: Date,
        public distanceCoveredCity?: number,
        public distanceCoveredCountrySide?: number,
        public timeWaiting?: number,
        public timeFreeWaiting?: number,
        public freeDistance?: number,
        public costPaidWaiting?: number,
        public costDistanceCity?: number,
        public costDistanceCountrySide?: number
    ) {};
}

export class Point {
    constructor(
        public id: number,
        public indexNumber: number,
        public lat: number,
        public lng: number,
        public status: PointStatus,
        public startTime?: Date,
        public endTime?: Date,
        public address?: string
    ) {}
}

//Adapter for nested items https://stackoverflow.com/questions/59875080/in-angular-how-to-use-adapter-pattern-when-http-response-has-array-of-objects
@Injectable({
    providedIn: 'root'
})
export class OrderAdapter implements Adapter<Order> {
    adapt(item: any): Order {
        let points = item.points.map(item => {
            let convertedTimeArrivedPoint = (item.startTime == null) ? null : new Date(item.startTime * 1000);
            let convertedTimeLeftPoint = (item.endTime == null) ? null : new Date(item.endTime * 1000);
            return new Point(
                item.id,
                item.indexNumber,
                item.lat,
                item.lng,
                item.status,
                convertedTimeArrivedPoint,
                convertedTimeLeftPoint,
                item.address
            )
        })

        let convertedStartTimeWaiting = (item.orderDetails.startTimeWaiting == null) ? null : new Date(item.orderDetails.startTimeWaiting * 1000);
        let details: OrderDetails =  new OrderDetails(
            item.orderDetails.id,
            item.orderDetails.percentageCompany,
            item.orderDetails.percentageCancellation,
            convertedStartTimeWaiting,
            item.orderDetails.distanceCoveredCity,
            item.orderDetails.distanceCoveredCountrySide,
            item.orderDetails.timeWaiting,
            item.orderDetails.timeFreeWaiting,
            item.orderDetails.freeDistance,
            item.orderDetails.costPaidWaiting,
            item.orderDetails.costDistanceCity,
            item.orderDetails.costDistanceCountrySide
        )

        let currency: Currency;
        if (item.currency != null) {
            currency = new Currency(
                item.currency.isBegin,
                item.currency.currency
            )
        }

        let feedback: Feedback;
        if (item.feedback != null) {
            let convertedCreateTime = (item.feedback.createTime == null) ? null :  new Date(item.feedback.createTime * 1000);
            feedback = new Feedback(
                item.feedback.id,
                item.feedback.rating,
                item.feedback.comment,
                convertedCreateTime
            )
        }

        let convertStartTimeOrder = (item.startTime == null) ? null :  new Date(item.startTime * 1000);
        let convertedEndTimeOrder = (item.endTime == null) ? null : new Date(item.endTime * 1000);
        return new Order(
            item.id,
            item.taxiFareType,
            convertStartTimeOrder,
            convertedEndTimeOrder,
            item.orderStatus,
            item.totalSum,
            item.paymentMethodType,
            new Date(item.createTime * 1000),
            points,
            item.user,
            item.driver,
            item.car,
            details,
            currency,
            feedback
        )
    }
}

@Injectable({
    providedIn: 'root'
})
export class OrderDetailsAdapter implements Adapter<OrderDetails> {
    adapt(item: any): OrderDetails {
        return new OrderDetails(
            item.id,
            item.percentageCompany,
            item.percentageCancellation,
            new Date(item.startTimeWaiting * 1000),
            item.distanceCoveredCity,
            item.distanceCoveredCountrySide,
            item.timeWaiting,
            item.timeFreeWaiting,
            item.freeDistance,
            item.costPaidWaiting,
            item.costDistanceCity,
            item.costDistanceCountrySide
        )
    }
}

@Injectable({
    providedIn: 'root'
})
export class PointAdapter implements Adapter<Point> {
    adapt(item: any): Point {
        return new Point(
            item.id,
            item.indexNumber,
            item.lat,
            item.lng,
            item.status,
            new Date(item.startTime * 1000),
            new Date(item.endTime * 1000),
            item.address
        )
    }
}