import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';

import { AutoStatus } from '../enums/autostatus';
import { AutoColor } from '../enums/autocolor';
import { CarPublicUpdate } from './carPublicUpdate';
import { CarPublicCreate } from './carPublicCreate';

export class Car {
    constructor(
        public id: number,
        public make: string,
        public model: string,
        public year: number,
        public govermentNumber: string,
        public cabNumber: string,
        public countPlaces: number,
        public isPrivate: boolean,
        public status: AutoStatus,
        public color: AutoColor
    ) {};
}

@Injectable({
    providedIn: 'root'
})
export class CarAdapter implements Adapter<Car> {
    adapt(item: any): Car {
        var converterYear: Date = new Date(item.year * 1000);
        return new Car(
            item.id,
            item.make,
            item.model,
            converterYear.getFullYear(),
            item.govermentNumber,
            item.cabNumber,
            item.countPlaces,
            item.isPrivate,
            item.status,
            item.color
        )
    }
}

@Injectable({
    providedIn: 'root'
})
export class CarUpdateAdapter implements Adapter<CarPublicUpdate> {
    adapt(item: any): CarPublicUpdate {
        return new CarPublicUpdate (
            item.id,
            item.make,
            item.model,
            (new Date(item.year, 1, 1, 12, 0, 0, 0)).valueOf() / 1000,
            item.govermentNumber,
            item.cabNumber,
            item.countPlaces,
            item.status,
            item.color
        )
    }
}
