import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";

import { Driver, DriverAdapter } from '../models/driver/driver';
import { AggregateStatisticAdapter } from '../models/driver/statistic';
import { DriverCreate} from '../models/driver/driverCreate';
import { DriverUpdate } from 'app/models/driver/driverUpdate';
import { DriverSortState } from 'app/models/driver/driverSortState';
import { DriverIndexAdapter } from 'app/models/driver/driverIndex';
import { DriverWorkStatus } from 'app/models/enums/driverworkstatus';
import { environment } from 'environments/environment';

@Injectable()
export class DriverService {
    private url = environment.baseApiurl + "/admindriver";

    constructor(private http: HttpClient,
                private dAdapter: DriverAdapter,
                private adapterIndex: DriverIndexAdapter,
                private sAdapter: AggregateStatisticAdapter) {
    }

    getDrivers() {
        return this.http.get(this.url + '/getall')
            .pipe(map((data: any[]) => data.map(item => this.dAdapter.adapt(item))));
    }

    getDriver(id: number) {
        return this.http.get(this.url + '/getbyid?id=' + id)
            .pipe(map((data: any) =>  this.dAdapter.adapt(data)));
    }

    getPage(page: number, size: number = 20, sort: DriverSortState = DriverSortState.IdDesc) {
        return this.http.get(this.url + '/getpage?page=' + page + '&order=' + sort + '&size=' + size)
            .pipe(map((data: any) =>  this.adapterIndex.adapt(data)));
    }

    getDriverStatistics(id: number) {
        return this.http.get(this.url + '/getDriverStatistics?id=' + id)
            .pipe(map((data: any) =>  this.sAdapter.adapt(data)));
    }

    createDriver(newDriver: DriverCreate) {
        return this.http.post(this.url + '/createDriver', newDriver);
    }

    updateDriver(updatedDriver: DriverUpdate) {
        return this.http.put(this.url + '/updateDriver', updatedDriver);
    }

    deleteDriver(id: number) {
        return this.http.delete(this.url + '/deleteDriver?id=' + id);
    }

    uploadDriverLicense(id: number, front: File, back: File) {
        const fullUrl = this.url + '/uploadLicense?id=' + id;
        let uploadData: FormData = new FormData();

        let fileName: string;
        if (front != null) {
            fileName = 'frontSide-' + front.name;
            uploadData.append('frontSide', front, front.name);
        }

        if (back != null) {
            fileName = 'backSide-' + back.name;
            uploadData.append('backSide', back, back.name);
        }

        return this.http.post(fullUrl, uploadData);
    }

    getXLSXwithDrivers() {
        return this.http.get(this.url + '/exportDriversToXLSX');
    }

    getDriverLicense(driverId: number, isFrontSide: boolean) {
        const url = `${this.url}/GetDriverLicense`; 
    
        return this.http.get(url, { params: { driverId: driverId.toString(), isFrontSide: isFrontSide.toString(), }, responseType: 'text'});
    }
}