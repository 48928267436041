import { Injectable } from "@angular/core";
import { EmployeeRole } from "../enums/employeeRole";
import { Adapter } from "app/core/interfaces/adapter";

export class Staff {
    constructor(
    public id?: number,
    public email?: string,
    public name?: string,
    public comment?: string,
    public role?: EmployeeRole,
    public isBlocked?: boolean,
    public phoneNumber?: string,
    public createTime?: Date,
    public updateTime?: Date
    ){}
}

@Injectable( {
    providedIn: 'root'
})
export class StaffAdapter implements Adapter<Staff> {
    adapt(item: any): Staff {
        console.log(item);
        return new Staff(
            item.id,
            item.email,
            item.name,
            item.comment,
            item.role,
            item.isBlocked,
            item.phoneNumber,
            new Date(item.createTime * 1000),
            new Date(item.updateTime * 1000)
        )
    }
}