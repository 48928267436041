import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";

import { Notification, NotificationAdapter} from '../models/notification/notification';
import { NotificationCreate, NotificationCreateAdapter} from '../models/notification/notificationCreate';
import { environment } from 'environments/environment';

@Injectable()
export class NotificationService {
    private url = environment.baseApiurl + "/AdminNotification";

    constructor(private http: HttpClient,
                private adapter: NotificationAdapter,
                private adapterCreate: NotificationCreateAdapter) {
        }

    getNotification() {
        return this.http.get(this.url + '/getAll')
                .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    sendDriverNotification(newNotify: NotificationCreate) {
        return this.http.post(this.url + '/sendDrivers', newNotify);
    }

    sendClientNotification(newNotify: NotificationCreate) {
        return this.http.post(this.url + '/sendClients', newNotify);
    }
}