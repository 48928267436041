import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";

import { Car, CarAdapter } from '../models/car/car';
import {CarIndex, CarIndexAdapter } from '../models/car/carIndex';
import { CarPublicCreate } from '../models/car/carPublicCreate';
import { CarPublicUpdate} from '../models/car/carPublicUpdate';
import { CarSortState } from 'app/models/car/carSortState';
import { environment } from 'environments/environment';


//for test of paging
import { PagedData } from '../models/common/paged-data';
import { PageItem } from '../models/common/pageItem';


@Injectable()
export class CarService {
    private url = environment.baseApiurl + "/car";

    constructor(private http: HttpClient,
                private adapter: CarAdapter,
                private adapterIndex: CarIndexAdapter) {
    }

    getCars() {
        return this.http.get(this.url + '/getall')
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getPage(page: number, size: number = 1, sort: CarSortState = CarSortState.IdDesc) {
        return this.http.get(this.url + '/getpage?page=' + page + '&order=' + sort + '&size=' + size)
            .pipe(map((data: any) =>  this.adapterIndex.adapt(data)));
    }

    getAvailablePublicCars() {
        return this.http.get(this.url + '/GetAvailable')
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getCar(id: number) {
        return this.http.get(this.url + '/getbyid?id=' + id)
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }

    createPublicCar(newCar: CarPublicCreate) {
        return this.http.post(this.url + '/createcar', newCar);
    }

    updatePublicCar(updatedCar: CarPublicUpdate) {
        return this.http.put(this.url + '/updateCar', updatedCar);
    }

    deleteCar(id: number) {
        return this.http.delete(this.url + '/deletecar?id=' + id)
            .pipe(
                catchError((err) => {
                    return throwError(err)
                })
            );
    }

    getXLSXwithCars() {
        return this.http.get(this.url + '/exportCarsToXLSX');
    }
}