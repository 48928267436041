import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';
import { map } from "rxjs/operators";

import { ClientSortState } from './clientSortState';
import { Page } from '../common/page';
import { User } from './user';

export class UserIndex {
    constructor(
        public users: User[],
        public page: Page,
        public sort: ClientSortState
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class UserIndexAdapter implements Adapter<UserIndex> {
    adapt(data: any): UserIndex {
        let mappedUsers: User[] = [];
        if (data.users != null) {
            for (let item of data.users) {
                let converterYear: Date = new Date(item.registrationDate * 1000);
                let newUser: User = new User(
                    item.id,
                    item.name,
                    item.email,
                    item.phoneNumber,
                    item.isBlocked,
                    item.isVerify,
                    converterYear
                )
                mappedUsers.push(newUser);
            }
        }

        let page: Page;
        if (data.page != null) {
            page = new Page(
                data.page.pageNumber,
                data.page.totalPages,
                data.page.totalItems,
                mappedUsers.length,
                data.page.hasPreviousPage,
                data.page.hasNextPage,
                data.page.previousPage,
                data.page.nextPage,
                data.page.firstPage,
                data.page.lastPage
            )
        }

        return new UserIndex(
            mappedUsers,
            page,
            data.sort.currentOrder
        )
    }
}