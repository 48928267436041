import { Injectable } from "@angular/core";
import { Driver, DriverAdapter } from "./driver";
import { Adapter } from "app/core/interfaces/adapter";

export class DriverOrders {
    constructor(
        driver: Driver,
        countOrders: number,
        revenue: number
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class DriverOrdersAdapter implements Adapter<DriverOrders> {
    constructor (private adapter: DriverAdapter) {}

    adapt(item: any): DriverOrders {
        const adaptedDriver = this.adapter.adapt(item.driver);

        return new DriverOrders (
        adaptedDriver,
        item.countOrders,
        item.revenue 
        )
    }
}