import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';

export class Feedback {
    constructor(
        public id?: number,
        public rating?: number,
        public comment?: string,
        public createTime?: Date
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class FeedbackAdapter implements Adapter<Feedback> {
    adapt(item: any): Feedback {
        let convertedCreateTime = (item.createTime == null) ? null :  new Date(item.createTime * 1000);
        
        return new Feedback(
            item.id,
            item.rating,
            item.comment,
            convertedCreateTime
        )
    }
}