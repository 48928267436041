import { Injectable } from '@angular/core';
import { Page } from '../common/page';
import { Promocode } from './promocode';
import { Adapter } from 'app/core/interfaces/adapter';

export class PromocodeIndex {
    constructor(
        public items: Promocode[],
        public page: Page
        //public sort: DriverSortState - add the sorting for codes after
    ){}
}


@Injectable({
    providedIn: 'root'
})
export class PromocodeIndexAdapter implements Adapter<PromocodeIndex> { 
    adapt(data: any): PromocodeIndex {
        let mappedItems: Promocode[] = [];
        if (data.codes != null) {
            for (let item of data.codes) {
                let newCode = new Promocode(
                    item.id,
                    item.name,
                    item.discountPercentage,
                    item.description,
                    item.type,
                    item.state,
                    new Date(item.createTime * 1000),
                    new Date(item.startAt * 1000),
                    new Date(item.expirateAt * 1000)
                )
                mappedItems.push(newCode);
            }
        }

        let page: Page;
        if (data.page != null) {
            page = new Page(
                data.page.pageNumber,
                data.page.totalPages,
                data.page.totalItems,
                mappedItems.length,
                data.page.hasPreviousPage,
                data.page.hasNextPage,
                data.page.previousPage,
                data.page.nextPage,
                data.page.firstPage,
                data.page.lastPage
            )
        }

        return new PromocodeIndex(
            mappedItems,
            page
            //add sorting after
        )
    }
}