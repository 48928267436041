import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from 'environments/environment';
import { DocumentAdapter } from "app/models/document/document";
import { DocumentCreate } from "app/models/document/documentCreate";



@Injectable()
export class DocumentService {
    private url = environment.baseApiurl + "/Document";

    constructor(private http: HttpClient, 
                private adapter: DocumentAdapter) {
    }

    getAll() {
        return this.http.get(this.url + '/getAll')
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    create(newItem: DocumentCreate) {
        const formData: FormData = new FormData();     
        for (const key in newItem) {
            if (newItem.hasOwnProperty(key)) {
                formData.append(key, (newItem as any)[key]);
            }
        }

        newItem.files.forEach(file => {
          formData.append('files', file, file.name);
        });

        return this.http.post(this.url + '/create', formData);
    }

    delete(id: number) {
        return this.http.delete(this.url + '/delete?id=' + id);
    }


}