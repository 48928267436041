import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from 'environments/environment';
import { TransactionAdapter } from "app/models/transaction/transaction";

@Injectable()
export class TransactionService {
    private url = environment.baseApiurl + "/transaction";

    constructor(private http: HttpClient, 
                private adapter: TransactionAdapter) {
    }

    getAll() {
        return this.http.get(this.url + '/getAll')
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getById(id: number) {
        return this.http.get(this.url + '/getById?id=' + id)
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }
/*
    create(newTransaction: TransactionCreate) {
        return this.http.post(this.url + '/create', newTransaction);
    }

    update(updatedTransaction: TransactionUpdate) {
        return this.http.put(this.url + '/update', updatedTransaction);
    }
*/

    delete(id: number) {
        return this.http.delete(this.url + '/delete?id=' + id);
    }
}