import { Component, OnInit } from "@angular/core";
import { LandlordService } from "./landlord.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import swal from 'sweetalert2';
import { LandlordCreate } from "app/models/landlord/landlordCreate";
import { UserStatus } from "app/models/enums/userStatus";

@Component({
    selector: 'app-root',
    templateUrl: './add-landlord.component.html',
    providers: [LandlordService]
})
export class AddLandlordComponent implements OnInit {
    
    item: LandlordCreate = new LandlordCreate();
    userStatus: string[];

    constructor(private landlordService: LandlordService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
        this.userStatus = Object.keys(UserStatus);
    }
    
    create(): void {
        this.landlordService.create(this.item).subscribe(data => {
            this.router.navigate(['/landlords/alllandlords']);
        })
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/landlords/alllandlords']);
            }
        })
    }

}