import { Injectable } from "@angular/core";
import { Page } from "../common/page";
import { Staff } from "./staff";
import { Adapter } from "app/core/interfaces/adapter";
import { StaffSortState } from "./staffSortState";

export class StaffIndex {
    constructor(
    public staff: Staff[],
    public page: Page,
    public sort: StaffSortState
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class StaffIndexAdapter implements Adapter<StaffIndex> {
    adapt(data: any): StaffIndex {
        let mappedStaff: Staff[] = [];
        if (data.staff != null) {
            for (let item of data.staff) {
                let newStaff: Staff = new Staff(
                    item.id,
                    item.email,
                    item.name,
                    item.comment,
                    item.role,
                    item.isBlocked,
                    item.phoneNumber,
                    new Date(item.createTime * 1000),
                    new Date(item.updateTime * 1000)
                )
                mappedStaff.push(newStaff);
            }
        }

        let page: Page;
        if (data.page != null) {
            page = new Page(
                data.page.pageNumber,
                data.page.totalPages,
                data.page.totalItems,
                mappedStaff.length,
                data.page.hasPreviousPage,
                data.page.hasNextPage,
                data.page.previousPage,
                data.page.nextPage,
                data.page.firstPage,
                data.page.lastPage
            )
        }

        return new StaffIndex(
            mappedStaff,
            page,
            data.sort.currentOrder
        )
    }
}