
<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'CarManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'CarManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title">{{'CreateCarTitle' | translate}}</h4>
                    <p>{{'CreateCarDescription' | translate}}</p>
                </div>
                <div class="card-content">
                    <div class="px-0">
                        <form class="form">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-3"> 
                                        <div class="form-group">
                                            <label for="carmake">{{'Make' | translate}} *</label>
                                            <input [(ngModel)]="car.make" placeholder="{{'EnterThe' | translate}}{{'Make' | translate}}" name="make" 
                                                    class="form-control" id="carmake" #make="ngModel" required
                                                    pattern="[a-zA-Zа-яА-Я0-9@_. -]{1,50}">
                                            <div [hidden]="make.valid || make.untouched" class="text-danger">
                                                {{'FieldValidation1-50' | translate}}
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="carmodel">{{'Model' | translate}} *</label>
                                            <input  [(ngModel)]="car.model" placeholder="{{'EnterThe' | translate}}{{'Model' | translate}}" name="model" 
                                                    class="form-control" id="carmodel" #model="ngModel" required
                                                    pattern="[a-zA-Zа-яА-Я0-9- ]{1,50}">
                                            <div [hidden]="model.valid || model.untouched" class="text-danger">
                                                {{'FieldValidation1-50' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="caryear">{{'Year' | translate}} *</label>
                                            <input type="number" [(ngModel)]="car.year" placeholder="{{'EnterThe' | translate}}{{'Year' | translate}}" name="year" 
                                                    class="form-control" id="caryear" #year="ngModel" required  min="1980" max="2030">
                                            <div [hidden]="year.valid || year.untouched" class="text-danger">
                                                {{'FieldRequired' | translate}}
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="carcolor">{{'Color' | translate}} *</label>
                                            <select class="form-control" [(ngModel)]="car.color" 
                                                    name="color" #color="ngModel" required>
                                                <option *ngFor="let color of colors" value="{{color}}">
                                                    {{color}}
                                                </option>
                                            </select>
                                            <div [hidden]="color.valid || color.untouched" class="text-danger">
                                                {{'FieldRequired' | translate}}
                                            </div>                          
                                        </div>
                                    </div>                     
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="cargovermentNumber">{{'GovermentNumber' | translate}} *</label>
                                            <input [(ngModel)]="car.govermentNumber" placeholder="{{'EnterThe' | translate}}{{'GovermentNumber' | translate}}" 
                                                    name="govermentNumber" class="form-control" id="cargovermentNumber" 
                                                    #govermentNumber="ngModel" required
                                                    pattern="[a-zA-Zа-яА-Я0-9-]{4,10}">
                                            <div [hidden]="govermentNumber.valid || govermentNumber.untouched" class="text-danger">
                                                {{'FieldValidation4-10' | translate}}
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="cargovermentNumber">Cab number *</label>
                                            <input [(ngModel)]="car.cabNumber" placeholder="{{'EnterThe' | translate}} " 
                                                    name="cabNumber" class="form-control" id="cabNumber" 
                                                    #cabNumber="ngModel" required
                                                    pattern="[a-zA-Zа-яА-Я0-9-]{4,10}">
                                            <div [hidden]="cabNumber.valid || cabNumber.untouched" class="text-danger">
                                                {{'FieldValidation4-10' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="carcountPlaces">{{'CountPlace' | translate}} *</label>
                                            <select class="form-control" [(ngModel)]="car.countPlaces" 
                                                    name="countPlaces" #countPlaces="ngModel" required>
                                                <option *ngFor="let place of places" value="{{place}}">
                                                    {{place}}
                                                </option>
                                            </select>
                                            <div [hidden]="countPlaces.valid || countPlaces.untouched" class="text-danger">
                                                {{'FieldRequired' | translate}}
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label for="carcolor">{{'Status' | translate}} *</label>
                                            <select class="form-control" [(ngModel)]="car.status" 
                                                    name="status" #status="ngModel" required>
                                                <option *ngFor="let status of statuses" value="{{status}}">
                                                    {{status}}
                                                </option>
                                            </select>    
                                            <div [hidden]="status.valid || status.untouched" class="text-danger">
                                                {{'FieldRequired' | translate}}
                                            </div>                      
                                        </div>
                                    </div>
                                </div> 
                                <div class="row">
                                    <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                        <div class="right">
                                            <button type="button" [disabled]="make.invalid || model.invalid || govermentNumber.invalid || 
                                                        cabNumber.invalid || year.invalid || countPlaces.invalid || color.invalid || status.invalid" 
                                                class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="createCar()"> 
                                                {{'Save' | translate}}
                                            </button>
                                            <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                                {{'Cancel' | translate}}
                                            </button>                          
                                        </div>
                                    </div>                                 
                                </div> 
                            </div>                       
                        </form>    
                    </div>
                </div>
            </div>
        </div>
    </div>    
</section>