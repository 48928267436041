import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import swal from 'sweetalert2';
import { FlatService } from "./flat.service";
import { Flat } from "app/models/flat/flat";
import { FlatActualContract } from "app/models/flat/flatContract";
import { AuthService } from "app/shared/auth/auth.service";
import { Role } from "app/models/enums/role";
import { InvoiceService } from "./invoice.service";
import { Invoice } from "app/models/invoice/invoice";

@Component({
    selector: 'app-order',
    templateUrl: './flat.component.html',
    providers: [FlatService, InvoiceService]
})
export class FlatComponent implements OnInit {
    
    //items: Flat[];
    items: FlatActualContract[];
    temp = [];
    flats: Flat[];
    invoices: Invoice[];
    userRole: Role | null = null;
    userId: number | null = null;
    viewType: string = '';
    balance: number;

    constructor(private flatService: FlatService,
                private invoiceService: InvoiceService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService,
                private toastr: ToastrService,
                private authService: AuthService) {
    }

    ngOnInit(): void {
        this.userRole = this.authService.getUserRole();
        this.userId = this.authService.getUserId();
        switch(this.userRole)
        {
            case Role.Admin:
                this.loadData();
                this.viewType = 'admin';
                break;
            case Role.Employee:
                this.loadData();
                this.viewType = 'employee';
                break;
            case Role.Landlord:
                this.loadLandlordFlats(this.userId);
                this.loadLandlordInvoices(this.userId);
                this.viewType = 'landlord';
                break;
            case Role.Tenant:
                this.loadTenantFlats(this.userId);
                this.viewType = 'tenant';
                break;
        }
    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        const temp = this.temp.filter(function (d) {
            return  d.flat.title.toLowerCase().indexOf(val) !== -1 ||
                    d.flat.city.toLowerCase().indexOf(val) !== -1 ||
                    d.flat.street.toLowerCase().indexOf(val) !== -1 || 
                    d.flat.building.toLowerCase().indexOf(val) !== -1 || 
                    d.flat.apartment.toLowerCase().indexOf(val) !== -1 || 
                    d.flat.postcode.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.items = temp;
    }

    private loadData(): void {
        this.flatService.getAllWithActualContract().subscribe((data: FlatActualContract[]) => {
            this.items = data;
            this.temp = [...data];
        })
    }

    private delete(id: number): void {
        this.flatService.delete(id).subscribe(data => {
            this.loadData();
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private loadLandlordFlats(id: number) {
        this.flatService.getAllWithActualContractByLandlordId(id).subscribe((data: FlatActualContract[]) => {
            this.items = data;
            this.temp = [...data];
        });
    }

    private loadTenantFlats(id: number) {
        this.flatService.getAllByTenantId(id).subscribe((data: Flat[]) => {
            this.flats = data;
            this.temp = [...data];
        })
    }

    private loadLandlordInvoices(id: number) {
        this.invoiceService.getAllByLandlordId(id).subscribe((data: Invoice[]) => {
            this.invoices = data;
            this.balance = this.invoices.map(x => x.value).reduce((sum, invoice) => sum + invoice, 0);
        })
    }


    viewPage(id: number) {
        this.router.navigate(['/flats/'+ id]);
    }

    viewPageEmployee(id:number){
        this.router.navigate(['/employees/'+ id]);
    }

    viewPageLandlord(id:number){
        this.router.navigate(['/landlords/'+ id]);
    }

    viewPageTenants(id:number){
        this.router.navigate(['/tenants/'+ id]);
    }

    confirmDelete(id: number) {
        swal.fire({
            title: this.translate.instant('DeleteIt?'),
            text: this.translate.instant('AbleRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.delete(id);
            }
        })
    }
    
}