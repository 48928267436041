import { Component, OnInit, ViewChild} from '@angular/core';
//import { Router } from '@angular/router';
import swal from 'sweetalert2';

import { Promocode } from '../models/promocode/promocode';
import { PromocodeService } from './promocode.service';
//import { Subscription } from 'rxjs/internal/Subscription';
//import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//import { PromocodeIndex } from 'app/models/promocode/promocodeIndex';
import { TranslateService } from '@ngx-translate/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { DatatableData } from '../data-tables/data/datatables.data';

@Component({
    selector: 'app-order',
    templateUrl: './promocode.component.html',
    styleUrls: ['./promocode.component.css', '../../assets/sass/libs/datatables.scss'],
    providers: [PromocodeService]
})
export class PromocodeComponent implements OnInit {

    rows: any[];

    codes: Promocode[];
    code: Promocode;

    private tempData: any[];
    @ViewChild(DatatableComponent) table: DatatableComponent;

    //item: PromocodeIndex;

    //page: number;
    //size: number;

    //private routeSubscription: Subscription;
    //private querySubscription: Subscription;

    constructor(private promocodeService: PromocodeService,
                //private route: ActivatedRoute,
                //private router: Router,
                private toastr: ToastrService,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.loadPromcodes();
        /* old version. delete it after tests 14.01.24
        this.querySubscription = this.route.queryParams.subscribe(
            (queryParam: any) => {
                this.page = queryParam['page'];
                this.size = queryParam['size'];
                //this.sort = queryParam['sort'];
            }
        );
        this.loadPartPromocodes(this.page, this.size);*/
    }

    private loadPromcodes() {
        this.promocodeService.getPromocodes().subscribe((data: Promocode[]) => {
            this.rows = data;
            this.tempData = data;
        })
    }

    /*private loadPartPromocodes(page: number = 1, size: number): void {
        this.promocodeService.getPage(page, size).subscribe((data: PromocodeIndex) => {
            this.item = data;
        })
    }*/

    getPromocode(id: number): void {
        this.promocodeService.getPromocode(id).subscribe((data: Promocode) => {
            this.code = data;
        })
    }

    deletePromocode(id: number): void {
        this.promocodeService.deletePromocode(id).subscribe(data => {
            this.loadPromcodes();
            //this.loadPartPromocodes(this.page, this.size);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    confirmDeletePromocode(id: number) {
        swal.fire({
            title: this.translate.instant('DeleteIt?'),
            text: this.translate.instant('NotRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.deletePromocode(id);
            }
        })
    }

    /**
     * filterUpdate
     *
     * @param code
     */
    filterUpdate(event) {
        const val = event.target.value.toLowerCase();
        const temp = this.tempData.filter(function (d) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.rows = temp;
        this.table.offset = 1;
    }

}