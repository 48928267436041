import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import swal from 'sweetalert2';
import { TransactionService } from "./transaction.service";
import { Transaction } from "app/models/transaction/transaction";

@Component({
    selector: 'app-order',
    templateUrl: './transaction.component.html',
    providers: [TransactionService]
})
export class TransactionComponent implements OnInit {
    
    items: Transaction[];
    temp = [];

    constructor(private TransactionService: TransactionService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService,
                private toastr: ToastrService) {

    }

    ngOnInit(): void {
        this.loadData();
    }

    private loadData(): void {
        this.TransactionService.getAll().subscribe((data: Transaction[]) => {
            this.temp = [...data];
            this.items = data;
        })
    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        const temp = this.temp.filter(function (d) {
            return  d.sum.toLowerCase().indexOf(val) !== -1 ||
                    d.description.toLowerCase().indexOf(val) !== -1 || 
                    d.id.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.items = temp;
    }

    private delete(id: number): void {
        this.TransactionService.delete(id).subscribe(data => {
            this.loadData();
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    viewPage(id: number) {
        this.router.navigate(['/transactions/'+ id]);
    }

    confirmDelete(id: number) {
        swal.fire({
            title: this.translate.instant('DeleteIt?'),
            text: this.translate.instant('AbleRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.delete(id);
            }
        })
    }
    
}