import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";

import { CommonCompanyData, CommonCompanyDataAdapter } from '../models/statistics/CommonCompanyData';
import { environment } from 'environments/environment';


@Injectable()
export class StatisticsService {
    private url = environment.baseApiurl + "/AdminStatistics";

    constructor(private http: HttpClient,
                private adapter: CommonCompanyDataAdapter) {
    }

    getDashboardData(startDate: number, endDate: number) {
        return this.http.get(this.url + '/GetDashboardData?startTime=' + startDate + '&endTime=' + endDate)
                .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }
}
