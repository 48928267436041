import { Injectable } from "@angular/core";
import { RentalContract, RentalContractAdapter } from "../rentalContract/rentalContract";
import { Flat, FlatAdapter } from "./flat";
import { Adapter } from "app/core/interfaces/adapter";

export class FlatActualContract {
    constructor(
        public flat?: Flat,
        public rent?: number,
        public actualContract?: RentalContract
    ){}
}

//TODO: fix it later
@Injectable({
    providedIn: 'root'
})
export class FlatContractAdapter implements Adapter<FlatActualContract> {

    constructor(private flatAdapter: FlatAdapter,
                private contractAdapter: RentalContractAdapter) {}

    adapt(item: any): FlatActualContract {
        console.log(item);
        var flat = this.flatAdapter.adapt(item.flat);
        if (!item.actualContract || item.actualContract === '')
        {
            return new FlatActualContract(flat, item.rent, null);
        }
        
        var contract = this.contractAdapter.adapt(item.actualContract);
        return new FlatActualContract(flat, item.rent, contract);
    }
}