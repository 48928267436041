import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Promocode, PromocodeAdapter } from '../models/promocode/promocode';
import { PromocodeCrete } from '../models/promocode/promocodeCreate';
import { PromocodeAutoGenerate } from '../models/promocode/promocodeAutoGenerate';
import { PromocodeIndexAdapter } from 'app/models/promocode/promocodeIndex';
import { environment } from 'environments/environment';

@Injectable()
export class PromocodeService {
    private url = environment.baseApiurl + "/AdminPromocode";

    constructor(private http: HttpClient,
                private adapter: PromocodeAdapter,
                private adapterIndex: PromocodeIndexAdapter) {
    }

    getPromocodes() {
        return this.http.get(this.url + '/getAll')
                .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getPage(page: number, size: number = 20) {
        return this.http.get(this.url + '/getpage?page=' + page  + '&size=' + size)
            .pipe(map((data: any) =>  this.adapterIndex.adapt(data)));
    }

    createPromocode(newPromo: PromocodeCrete) {
        return this.http.post(this.url + '/Create', newPromo);
    }

    deletePromocode(id: number) {
        return this.http.delete(this.url + '/Delete?id=' + id);
    }

    autoGeneratePromocodes(newPromos: PromocodeAutoGenerate) {
        return this.http.post(this.url + '/AutoGenerate', newPromos);
    }

    updatePromocode() {
        //add model and url
    }

    getPromocode(id: number){
        return this.http.get(this.url + '/getbyid?id=' + id)
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }
}