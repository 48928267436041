import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';

import { TaxiFareDynamic } from '../models/taxifare/taxifaredynamic';
import { TaxiFareService } from './taxifare.service';
import { TaxifareIcon } from 'app/models/taxifare/taxifareIcon';
import { Company } from 'app/models/settings/company';
import { CompanyService } from 'app/settings/company.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './profiledynamic-taxifare.component.html',
    styleUrls: ['./taxifare.component.css'],
    providers: [TaxiFareService, CompanyService]
})
export class ProfileDynamicTaxifareComponent implements OnInit {

    taxiFare: TaxiFareDynamic;
    icons: TaxifareIcon[] = [];
    places: number[] = [];
    selectedIcon: any;

    constructor(private taxiFareService: TaxiFareService,
                private companyService: CompanyService,
                private route: ActivatedRoute,
                private router: Router,
                private toastr: ToastrService,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
        let dynamicId : number = Number(this.route.snapshot.paramMap.get("id"));
        this.getById(dynamicId);
        this.taxiFareService.getIcons().subscribe((data: TaxifareIcon[]) => {
            this.icons = data;
        });
        this.SetArraySeats();
    }

    private getById(id: number): void {
        this.taxiFareService.getDynamicTaxiFare(id).subscribe((data: TaxiFareDynamic) => {
            this.taxiFare = data;
            this.taxiFare.minutesFreeWainting = this.taxiFare.minutesFreeWainting / 60; //convet sec to min
            this.selectedIcon = this.taxiFare.baseInfo;
        })
    }

    onIconChange() {
        this.selectedIcon = this.icons.find(x => x.name === this.taxiFare.baseInfo.numberIcon.toString());
    }

    updateDynamicTaxifare(): void {
        console.log(this.taxiFare);
        this.taxiFareService.updateDynamicTaxiFare(this.taxiFare).subscribe(data => {
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private SetArraySeats() {
        this.companyService.getCompany().subscribe((data: Company) => {
            if (data != null) {
                for (var i = data.minCountSeats; i <= data.maxCountSeats; i++) {
                    this.places.push(i);
                }
            }
        });
    }

    // Confirm Button Action
    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/taxifares/alltaxifares']);
            }
        })
    }

}
