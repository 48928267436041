<div class="row">
    <div class="col-12">
      <div class="content-header">{{'AccountSettings' | translate}}</div>
      <p class="content-sub-header mb-1">{{'AccountSettingsDescription' | translate}}</p>
    </div>
</div>

<div class="row">
    <div class="col-md-6">
        <div class="card">
            <div class="card-content">
                <div class="card-body">
                    <form class="form" ngNoForm>
                        <div class="form-group">
                            <label>{{'FirstName' | translate}} *</label>
                            <input [(ngModel)]="item.firstName" placeholder="{{'EnterThe' | translate}}{{'FirstName' | translate}}" 
                                name="firstName" #firstName="ngModel" class="form-control"
                                pattern="[a-zA-Zа-яА-Я- ]{1,50}" required>
                            <div [hidden]="firstName.valid || firstName.untouched" class="text-danger">
                                {{'FieldValidation1-50' | translate}}
                            </div>
                        </div>
                        <div class="form-group">
                            <label>{{'LastName' | translate}} *</label>
                            <input [(ngModel)]="item.lastName" placeholder="{{'EnterThe' | translate}}{{'LastName' | translate}}" name="name" 
                                class="form-control" id="lastName" #lastName="ngModel" 
                                pattern="[a-zA-Zа-яА-Я- ]{1,50}" required>
                            <div [hidden]="lastName.valid || lastName.untouched" class="text-danger">
                                {{'FieldValidation1-50' | translate}}
                            </div>
                        </div>
                        <div class="form-group">
                            <label>{{'Email' | translate}} *</label>
                            <input type="email" [(ngModel)]="item.email" placeholder="{{'EnterThe' | translate}}{{'Email' | translate}}" name="email" 
                                class="form-control" id="email" #email="ngModel" 
                                pattern="[a-zA-Z0-9@-_-.]{10,50}" required>
                            <div [hidden]="email.valid || email.untouched" class="text-danger">
                                {{'FieldEmailValidation' | translate}}
                            </div>
                        </div>
                        <div class="form-group">
                            <label>{{'PhoneNumber' | translate}} *</label>
                            <input [(ngModel)]="item.phoneNumber" 
                                placeholder="{{'EnterThe' | translate}} {{'PhoneNumber' | translate}}" 
                                name="phoneNumber" class="form-control" 
                                #phoneNumber="ngModel" maxlength="50" required>
                            <div [hidden]="phoneNumber.valid || phoneNumber.untouched" class="text-danger">
                                {{'FieldEmailValidation' | translate}}
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="old-password">{{'OldPasword' | translate}}</label>
                            <div class="controls">
                              <input [(ngModel)]="item.oldPassword"  #oldPassword="ngModel" 
                                  name="oldPassword"
                                  type="password" class="form-control" pattern="^.{6,20}$"
                                  placeholder="Old Password">
                              <div [hidden]="oldPassword.valid || oldPassword.untouched" class="text-danger">
                                  Field should be between 6 and 20 characters long
                              </div>
                            </div>
                          </div>
                        <div class="form-group">
                          <label for="new-password">{{'NewPasword' | translate}}</label>
                          <div class="controls">
                            <input [(ngModel)]="item.newPassword"  #newPassword="ngModel" 
                                name="newPassword"
                                type="password" class="form-control" pattern="^.{6,20}$"
                                placeholder="New Password">
                            <div [hidden]="newPassword.valid || newPassword.untouched" class="text-danger">
                                Field should be between 6 and 20 characters long
                            </div>
                          </div>
                        </div>
                        <div class="form-group">
                          <label for="retype-new-password">{{'RepeatNewPasword' | translate}}</label>
                          <div class="controls">
                            <input [(ngModel)]="item.repeatNewPassword"  #repeatNewPassword="ngModel" 
                                name="repeatNewPassword"
                                type="password" class="form-control" pattern="^.{6,20}$"
                                placeholder="New Password">
                            <div [hidden]="repeatNewPassword.valid || repeatNewPassword.untouched" class="text-danger">
                                Field should be between 6 and 20 characters long
                            </div>
                            <div class="text-danger">{{message}}</div>
                          </div>
                        </div>
                        <div class="d-flex flex-sm-row flex-column justify-content-end">
                            <button type="button"  [disabled]="firstName.invalid || lastName.invalid || 
                                    email.invalid || phoneNumber.invalid || oldPassword.invalid || 
                                    newPassword.invalid || repeatNewPassword.invalid" 
                                     class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="update()"> 
                                {{'Save' | translate}}
                            </button>                   
                            <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                {{'Cancel' | translate}}
                            </button>  
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>