<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'NotificationManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'NotificationManagerDescription' | translate}}</p>
        </div>
    </div>
    <form class="form">
        <div class="row text-left">
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header pb-2">
                        <h4 class="card-title" id="horz-layout-colored-controls">{{'CreateNotificationTitle' | translate}}</h4>
                        <p class="mb-0">{{'CreateNotificationDescription' | translate}}</p>
                    </div>
                    <div class="card-content">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="userinput1">{{'Title' | translate}}*: </label>
                                    <input [(ngModel)]="item.title" placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" name="title" 
                                            class="form-control" id="title" #title="ngModel" 
                                            required maxlength="100" minlength="1">
                                    <div [hidden]="title.valid || title.untouched" class="text-danger">
                                        {{'FieldValidation1-100' | translate}}
                                    </div>
                                </div>
                                    <!--<div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input type="checkbox" [(ngModel)]="item.isClient" checked="item.isClient" 
                                                name="isClient" class="custom-control-input" id="typePush">
                                            <label class="custom-control-label" for="typePush">{{'NotificationForClient' | translate}}</label>
                                        </div>
                                    </div>-->
                                <div class="form-group">
                                    <label for="projectinput8">{{'Description' | translate}}:</label>
                                    <textarea [(ngModel)]="item.description" id="bdescription" rows="5" class="form-control" name="description" 
                                                #description="ngModel" maxlength="5000" minlength="1"></textarea>
                                    <div [hidden]="description.valid || description.untouched" class="text-danger">
                                        {{'FieldValidation1-5000' | translate}}
                                    </div>
                                </div>
                            </div>
            
                            <div class="form-actions right">
                                <button type="button" [disabled]="title.invalid || description.invalid" 
                                        class="btn btn-raised btn-primary" (click)="sendNotification()"> 
                                    <i class="fa fa-check-square-o"></i> {{'Send' | translate}}
                                </button>
                            </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header pb-2">
                        <h4 class="card-title" id="horz-layout-colored-controls">{{'CreateNotificationTitle' | translate}}</h4>
                        <p class="mb-0">{{'CreateNotificationDescription' | translate}}</p>
                    </div>
                    <div class="card-content">
                        <div class="px-3">
                            <div class="form-body">
                                <div class="row">
                                    <div class="form-group">
                                        sdfasdf
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title" id="horz-layout-colored-controls">{{'AllNotificationTitle' | translate}}</h4>
                    <p class="mb-0">{{'AllNotificationDescription' | translate}}</p>
                </div>
                <div class="card-content">                  
                    <div class="col-md-12">
                        <fieldset class="form-group">
                            <label for="ngx-filter-ref">Search:
                            <input id="ngx-filter-ref" class="form-control form-control-sm d-inline-block width-200" type="text"
                                placeholder="Filter the Name column..." (keyup)="updateFilter1($event)" />
                            </label>
                        </fieldset>
                        <ngx-datatable class="bootstrap core-bootstrap" 
                            [rows]="rows" 
                            [columnMode]="'auto'" 
                            [headerHeight]="50" 
                            [footerHeight]="50"
                            [rowHeight]="'auto'" 
                            [limit]="15">
                            <ngx-datatable-column name="{{'Title' | translate}}" prop="title"[width]="400" ></ngx-datatable-column>
                            <ngx-datatable-column name="{{'Description' | translate}}" prop="description" [width]="800"></ngx-datatable-column>  
                            <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                </ng-template>
                            </ngx-datatable-column> 
                        </ngx-datatable>    
                    </div>                                                 
                </div>
            </div>
        </div>
    </div>    
</section>