import { TaxifareFixed } from "../taxifare/taxifareFixed";
import { TaxiFareDynamic } from "../taxifare/taxifaredynamic";
import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';

export class PreOrder {
    constructor(
        public dymanics?: TaxiFareDynamicWithCost[],
        public fixed?:  TaxiFareFixedWithCost[]
    ){}
}

export class TaxiFareDynamicWithCost {
    constructor(
        public taxiFareDynamic?: TaxiFareDynamic,
        public cost?: number,
        public discountedCost?: number,
        public discountAmount?: number
    ){}
}

export class TaxiFareFixedWithCost {
    constructor(
        public taxiFareFixed?: TaxifareFixed,
        public cost?: number,
        public discountedCost?: number,
        public discountAmount?: number
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class PreOrderAdapter implements Adapter<PreOrder> {
    adapt(item: any): PreOrder {
        return new PreOrder(
            item.dynamics,
            item.fixed
        )
    }
}