
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NGXToastrService {
    constructor(public toastr: ToastrService,
                private translate: TranslateService) { }

    // Success Type
    typeSuccess() {
        this.toastr.success('You are awesome!', 'Success!');
    }

    // Success Type
    typeInfo() {
        this.toastr.info('We do have the Kapua suite available.', 'Turtle Bay Resort');
    }

    // Success Type
    typeWarning() {
        this.toastr.warning('My name is Inigo Montoya. You killed my father, prepare to die!');
    }

    // Success Type
    typeError() {
        this.toastr.error('I do not think that word means what you think it means.', 'Inconceivable!');
    }

    // Custom Type
    typeCustom() {
        this.toastr.success('<span class="text-danger">Message in red.</span>', null, { enableHtml: true });
    }

    //Progress bar
    progressBar() {
        this.toastr.info('We do have the Kapua suite available.', 'Turtle Bay Resort', { "progressBar": true });
    }

    // Timeout
    timeout() {
        this.toastr.error('I do not think that word means what you think it means.', 'Timeout!', { "timeOut": 2000 });
    }


    //Dismiss toastr on Click
    dismissToastOnClick() {
        this.toastr.info('We do have the Kapua suite available.', 'Turtle Bay Resort', { "tapToDismiss": true });
    }
    // Remove current toasts using animation
    clearToast() {
        this.toastr.clear()
    }

    // Show close button
    showCloseButton() {
        this.toastr.info('Have fun storming the castle!', 'Miracle Max Says', { closeButton: true });
    }
    // Enable  HTML
    enableHtml() {
        this.toastr.info('<i>Have fun <b>storming</b> the castle!</i>', 'Miracle Max Says', { enableHtml: true });
    }
    // Title Class
    titleClass() {
        this.toastr.info('Have fun storming the castle!', 'Miracle Max Says', { titleClass: 'h3' });
    }
    // Message Class
    messageClass() {
        this.toastr.info('Have fun storming the castle!', 'Miracle Max Says', { messageClass: 'text-uppercase' });
    }

    //server errors
    err101Unauthorized() {
        this.toastr.error(this.translate.instant('Error') + ' E101', this.translate.instant('err101Unauthorized'));
    }

    err102WrongData() {
        this.toastr.error(this.translate.instant('Error') + ' E102', this.translate.instant('err102WrongData'));
    }

    err103EmailOccupaid() {
        this.toastr.error(this.translate.instant('Error') + ' E103', this.translate.instant('err103EmailIsOccupied'));
    }

    err104Validation() {
        this.toastr.error(this.translate.instant('Error') + ' E104', this.translate.instant('err104ValidationError'));
    }

    err106UserNotFound() {
        this.toastr.error(this.translate.instant('Error') + ' E106', this.translate.instant('err106UserNotFound'));
    }

    err107InvalidToken() {
        this.toastr.error(this.translate.instant('Error') + ' E107', this.translate.instant('err107InvalidToken'));
    }

    err108UserBlocked() {
        this.toastr.error(this.translate.instant('Error') + ' E108', this.translate.instant('err108UserBlocked'));
    }

    err109ProfileNotVerifyed() {
        this.toastr.error(this.translate.instant('Error') + ' E109', this.translate.instant('err109ProfileNotVerified'));
    }

    err110RateNotFound() {
        this.toastr.error(this.translate.instant('Error') + ' E110', this.translate.instant('err110RateNotFound'));
    }

    err111RateNotSupportPaymentType() {
        this.toastr.error(this.translate.instant('Error') + ' E111', this.translate.instant('err111RateNotSupportedPaymentType'));
    }

    err114PhoneBusy() {
        this.toastr.error(this.translate.instant('Error') + ' E114', this.translate.instant('err114ClientPhoneBusy'));
    }

    err115OrderHasFeedback() {
        this.toastr.error(this.translate.instant('Error') + ' E115', this.translate.instant('err115OrderAlreadyHasFeedback'));
    }

    err116PromoNotFound() {
        this.toastr.error(this.translate.instant('Error') + ' E116', this.translate.instant('err116PromoNotFound'));
    }

    err117PromoExpired() {
        this.toastr.error(this.translate.instant('Error') + ' E117', this.translate.instant('err117PromoExpired'));
    }

    err118ClientHasCode() {
        this.toastr.error(this.translate.instant('Error') + ' E118', this.translate.instant('err118ClientAlreadyHasPromo'));
    }

    err201InvalidData() {
        this.toastr.error(this.translate.instant('Error') + ' E201', this.translate.instant('err201InvalidParam'));
    }

    err202ItemNotFound() {
        this.toastr.error(this.translate.instant('Error') + ' E202', this.translate.instant('err202ItemNotFound'));
    }

    err203ItemBlocked() {
        this.toastr.error(this.translate.instant('Error') + ' E203', this.translate.instant('err203ItemBlocked'));
    }

    err301CheckOrderStatus() {
        this.toastr.error(this.translate.instant('Error') + ' E301', this.translate.instant('err301NoCancelOrder'));
    }

    err302CheckOrderStatus() {
        this.toastr.error(this.translate.instant('Error') + ' E302', this.translate.instant('err302CheckStatusOrder'));
    }

    err303CardNotFound() {
        this.toastr.error(this.translate.instant('Error') + ' E303', this.translate.instant('err303CardNotFound'));
    }

    err304DebtCard() {
        this.toastr.error(this.translate.instant('Error') + ' E304', this.translate.instant('err304CardHasDebt'));
    }

    err401DriverNotFound() {
        this.toastr.error(this.translate.instant('Error') + ' E401', this.translate.instant('err401DriverNotFound'));
    }

    err402DriverBlocked() {
        this.toastr.error(this.translate.instant('Error') + ' E402', this.translate.instant('err402DriverBlocked'));
    }

    err403DriverNotVerified() {
        this.toastr.error(this.translate.instant('Error') + ' E403', this.translate.instant('err403DriverNotVerified'));
    }

    err406CarHasOrders() {
        this.toastr.error(this.translate.instant('Error') + ' E406', this.translate.instant('err406CarHasOrders'));
    }

    err407CarAlreadyUse() {
        this.toastr.error(this.translate.instant('Error') + ' E407', this.translate.instant('err407DriverUseSelectedCat'));
    }

    err408StatusPrivateCar() {
        this.toastr.error(this.translate.instant('Error') + ' E408', this.translate.instant('err408ForbiddenStatusPrivateCar'));
    }

    err409DriverHasOrders() {
        this.toastr.error(this.translate.instant('Error') + ' E409', this.translate.instant('err409DriverHasOrders'));
    }

    err410DriverHasCar() {
        this.toastr.error(this.translate.instant('Error') + ' E410', this.translate.instant('err410DriverHasPrivateCar'));
    }

    err411DriverPhoneBusy() {
        this.toastr.error(this.translate.instant('Error') + ' E411', this.translate.instant('err411PhoneBusy'));
    }

    err412DriverInline() {
        this.toastr.error(this.translate.instant('Error') + ' E412', this.translate.instant('err412DriverInline'));
    }

    err413MissingCar() {
        this.toastr.error(this.translate.instant('Error') + ' E413', this.translate.instant('err413DriverWithoutCar'));
    }

    err414MissingRate() {
        this.toastr.error(this.translate.instant('Error') + ' E414', this.translate.instant('err414DriverWithoutRate'));
    }

    err415DriverActiveOrder() {
        this.toastr.error(this.translate.instant('Error') + ' E415', this.translate.instant('err415DriverHasActiveOrders'));
    }

    err416PromoAlreadyHas() {
        this.toastr.error(this.translate.instant('Error') + ' E416', this.translate.instant('err416InvalidPromoName'));
    }

    err417DriverTypeCar() {
        this.toastr.error(this.translate.instant('Error') + ' E417', this.translate.instant('err417DriverUsePublicCar'));
    }

    err418MissingTrustedContact() {
        this.toastr.error(this.translate.instant('Error') + ' E418', this.translate.instant('err418MissingTrustedContacts'));
    }

    err701FileNotFound() {
        this.toastr.info(this.translate.instant('Error') + ' E701', this.translate.instant('err701FileNotFound'));
    }

    errForbidden() {
        this.toastr.error(this.translate.instant('Error') + ' H403', this.translate.instant('errForbidden'));
    }

    successResetPassword() {
        this.toastr.success(this.translate.instant('Success'), this.translate.instant('PasswordResetResult'));
    }

}