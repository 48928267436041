import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";
import { MeterType } from "../enums/meterType";
import { MeterCategory } from "../enums/meterCategory";

export class Meter{
    constructor(
        public id: number,
        public files: File[],
        public createTime?: Date,
        public type?: MeterType,
        public category?: MeterCategory,
        public value?: number,
        public fileNames?: string[]
    ){}
}

@Injectable( {
    providedIn: 'root'
})
export class MeterAdapter implements Adapter<Meter> {
    adapt(item: any): Meter {
        return new Meter(
            item.id,
            item.files,
            new Date(item.createTime * 1000),
            item.type,
            item.category,
            item.value,
            item.fileNames
        )
    }

}