import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LandlordAdapter } from "app/models/landlord/landlord";
import { LandlordCreate } from "app/models/landlord/landlordCreate";
import { LandlordUpdate } from "app/models/landlord/landlordUpdate";
import { map } from "rxjs/operators";
import { environment } from 'environments/environment';

@Injectable()
export class LandlordService {
    private url = environment.baseApiurl + "/landlord";

    constructor(private http: HttpClient, 
                private adapter: LandlordAdapter) {
    }

    getAll() {
        return this.http.get(this.url + '/getAll')
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getById(id: number) {
        return this.http.get(this.url + '/getById?id=' + id)
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }

    create(newLandlord: LandlordCreate) {
        return this.http.post(this.url + '/create', newLandlord);
    }

    update(updatedLandlord: LandlordUpdate) {
        return this.http.put(this.url + '/update', updatedLandlord);
    }

    block(id: number, blocked: Boolean) {
        var request = this.url + '/Block?id=' + id + '&blocked=' + blocked;
        return this.http.put(request, {});
    }

    delete(id: number) {
        return this.http.delete(this.url + '/delete?id=' + id);
    }


}