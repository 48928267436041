import { PromoCodeState} from '../enums/promocodestate';
import { PromoCodeType} from '../enums/promocodetype';

import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';

export class PromocodeCrete {
    constructor(
        public name?: string,
        public discountPercentage?: number,
        public description?: string,
        public type?: PromoCodeType,
        public state?: PromoCodeState,
        public startAt?: any,
        public expirateAt?: any,
    ) {}
}

