import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
//import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader, TranslatePipe } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";
import { DragulaService } from "ng2-dragula";
import { NgxSpinnerModule } from 'ngx-spinner';
import { GoogleMapsModule } from '@angular/google-maps'


import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import * as fromApp from './store/app.reducer';
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { FormsModule } from "@angular/forms";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { AddUserComponent } from "./user/add-user.component";
import { AddCarComponent } from "./car/add-car.component";
import { ProfileCarComponent } from "./car/profile-car.component";
import { AddDriveComponent } from "./driver/add-driver.component";
import { ProfileUserComponent } from "./user/profile-user.component";
import { ProfileDynamicTaxifareComponent } from "./taxifare/profiledynamic-taxifare.component";
import { AddDynamicTaxifareComponent } from "./taxifare/add-dynamictaxifare.component";
import { FeedbackComponent } from "./feedbacks/feedback.component";
import { AutoGenPromocodesComponent } from "./promocode/autogen-promocode.component";
import { AddPromocodeComponent } from "./promocode/add-promocode.component";
import { PromocodeComponent } from "./promocode/promocode.component";
import { NotificationComponent } from "./notification/notification.component";
import { MainDashboardComponent } from "./statistics/mainDashboard.component";
import { GlobalHttpInterceptorService } from "./core/GlobalHttpInterceptorService";
import { GlobalErrorHandlerService } from "./core/global-error-handler.service";
import { NGXToastrService } from "./components/extra/toastr/toastr.service";
import { ProfileComponent } from "./profile/profile.components";
import { StaffComponent } from "./staff/staff.component";
import { AddStaffComponent } from "./staff/add-staff.component";
import { ProfileStaffComponent } from "./staff/profile-staff.component";
import { Dashboard1Component } from "./dashboard/dashboard1/dashboard1.component";
import { LandlordComponent } from "./landlord/landlord.component";
import { ProfileLandlordComponent } from "./landlord/profile-landlord.component";
import { AddLandlordComponent } from "./landlord/add-landlord.component";
import { TenantComponent } from "./tenant/tenant.component";
import { ProfileTenantComponent } from "./tenant/profile-tenant.component";
import { AddTenantComponent } from "./tenant/add-tenant.component";
import { EmployeeComponent } from "./employee/employee.component";
import { AddEmployeeComponent } from "./employee/add-employee.component";
import { ProfileEmployeeComponent } from "./employee/profile-employee.component";
import { DocumentComponent } from "./document/document.component";
import { AddDocumentComponent } from "./document/add-document.component";
import { AddFlatComponent } from "./flat/add-flat.component";
import { ProfileFlatComponent } from "./flat/profile-flat.component";
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { FlatComponent } from "./flat/flat.component";
import { TransactionComponent } from "./transaction/transaction.component";

var firebaseConfig = {
  apiKey: "AIzaSyC9XfnIpwNoSv7cyAsoccFQ5EYPd7lZXrk", //YOUR_API_KEY
  authDomain: "apex-angular.firebaseapp.com", //YOUR_AUTH_DOMAIN
  databaseURL: "https://apex-angular.firebaseio.com", //YOUR_DATABASE_URL
  projectId: "apex-angular", //YOUR_PROJECT_ID
  storageBucket: "apex-angular.appspot.com", //YOUR_STORAGE_BUCKET
  messagingSenderId: "447277845463", //YOUR_MESSAGING_SENDER_ID
  appId: "1:447277845463:web:9a7db7aaeaf3a7217a9992", //YOUR_APP_ID
  measurementId: "G-ZVSYZRJ211" //YOUR_MEASUREMENT_ID
};


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent, 
    FullLayoutComponent, 
    ContentLayoutComponent,
    AddUserComponent,
    AddCarComponent,
    ProfileCarComponent,
    AddDriveComponent,
    ProfileUserComponent,
    ProfileDynamicTaxifareComponent,
    AddDynamicTaxifareComponent,
    FeedbackComponent,
    AutoGenPromocodesComponent,
    AddPromocodeComponent,
    PromocodeComponent,
    NotificationComponent,
    MainDashboardComponent,
    ProfileComponent,
    StaffComponent,
    AddStaffComponent,
    ProfileStaffComponent,

    LandlordComponent,
    ProfileLandlordComponent,
    AddLandlordComponent,
    TenantComponent,
    ProfileTenantComponent,
    AddTenantComponent,
    FlatComponent,
    //EmployeeComponent,
    AddEmployeeComponent,
    ProfileEmployeeComponent,
    DocumentComponent,
    AddDocumentComponent,
    AddFlatComponent,
    ProfileFlatComponent,
    TransactionComponent
  ],
  imports: [
    BrowserAnimationsModule,
    StoreModule.forRoot(fromApp.appReducer),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    ToastrModule.forRoot(),
    NgbModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    /*AgmCoreModule.forRoot({
      //apiKey: "AIzaSyCERobClkCv1U4mDijGm1FShKva_nxsGJY"
      apiKey: "AIzaSyBPNzpHXpiceTI2Na-JrX_GfkR6450ykgc" //my personal google-key
    }),*/
    PerfectScrollbarModule,
    FormsModule,
    NgxDatatableModule,
    GoogleMapsModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    AuthService,
    AuthGuard,
    DragulaService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS,    useClass: GlobalHttpInterceptorService,    multi: true  },
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService},
    WINDOW_PROVIDERS,
    NGXToastrService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
