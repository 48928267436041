import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';
import { DriverOrders, DriverOrdersAdapter } from '../driver/driverOrders';
import { ClientOrders } from '../client/clientOrders';

export class CommonCompanyData {
    constructor(
        public countDriversOnLine: number,
        public countRegisteredClients: number,
        public countRegisteredDrivers: number,
        public countCompletedOrders: number,
        public revenueDaily: number,
        public revenutMonth: number,
        public countNewClients: number,
        public countNewDrivers: number,
        public countNewOrders: number,
        public topDrivers: DriverOrders[],
        public topUsers: ClientOrders[]
    ) {}
}

@Injectable({
providedIn: 'root'
})
export class CommonCompanyDataAdapter implements Adapter<CommonCompanyData> {
    constructor(private adapterDriver: DriverOrdersAdapter) {}

    adapt(item: any): CommonCompanyData {

        return new CommonCompanyData(
            item.countDriversOnLine,
            item.countRegisteredClients,
            item.countRegisteredDrivers	,
            item.countCompletedOrders,
            item.revenueDaily,
            item.revenutMonth,
            item.countNewClients,
            item.countNewDrivers,
            item.countNewOrders,
            item.topDrivers,
            item.topUsers
        )
    }
}