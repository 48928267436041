import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";

export class Inspection {
    constructor(
        public id?: number,
        public createTime?: Date,
        public flatId?: number
    ){}
}

@Injectable( {
    providedIn: 'root'
})
export class InspectionAdapter implements Adapter<Inspection> {
    adapt(item: any): Inspection {
        return new Inspection(
            item.id,
            new Date(item.createTime * 1000),
            item.flatId
        )
    }

}