export enum ClientSortState {
    IdAsc,
    IdDesc,
    TypeAsc,
    TypeDesc,
    MakeAsc,
    MakeDesc,
    ModelAsc,
    ModelDesc,
    YearAsc,
    YearDesc,
    GNAsc,
    GNDesc,
    StatusAsc,
    StatusDesc
}