export enum OrderSortState {
    IdAsc,
    IdDesc,
    StartDateAsc,
    StartDateDesc,
    EndDateAsc,
    EndDateDesc,
    StatusAsc,
    StatusDesc,
    TotalSumAsc,
    TotalSumDesc
}