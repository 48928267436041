<div class="row text-left">
  <div class="col-12">
    <div class="content-header">{{'ClientManagerTitle' | translate}}</div>
    <p class="content-sub-header">{{'ClientManagerDescription' | translate}}</p>
  </div>
</div>
<section id="all-clients">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-10">
              <h4 class="card-title">{{'AllClientsTitle' | translate}}</h4>
              <p>{{'AllClientsDescription' | translate}}</p>
            </div>
            <div class="col-md-2">
                <button type="button" class="btn btn-outline-primary mr-1 pull-right" 
                  (click)="getLinkOnReport()">{{'ExportToXLSX' | translate}}</button>
            </div> 
          </div>
        </div>     
        <div class="card-content">
          <div class="card-body">
              <!--
              <table class="table table-responsive-md text-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{'Phone' | translate}}</th>                  
                    <th>{{'Email' | translate}}</th>
                    <th>{{'Name' | translate}}</th>
                    <th>{{'IsBlocked' | translate}}</th>
                    <th>{{'RegistrationDate' | translate}}</th>
                    <th>{{'Actions' | translate}}</th>
                  </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of user.users">
                  <td><a href="/clients/{{user.id}}">{{user.id}}</a></td>
                  <td><a href="/clients/{{user.id}}">{{user.phoneNumber}}</a></td>                
                  <td>{{user.email}}</td>   
                  <td>{{user.name}}</td>            
                  <td>
                      <div *ngIf="user.isBlocked; else blockUserBadge">
                          <span _ngcontent-ykd-c258="" class="badge badge-danger">{{'Blocked' | translate}}</span>
                      </div>                      
                      <ng-template #blockUserBadge>
                          <span _ngcontent-ykd-c258="" class="badge badge-light">{{'Active' | translate}}</span>
                      </ng-template>
                  </td>
                  <td>{{user.registrationDate | date:'dd/MM/yy h:mm:ss a'}}</td>
                  <td>
                    <a class="success p-0" data-original-title="" title="" href="/clients/{{user.id}}">
                      <i class="ft-edit-2 font-medium-3 mr-2"></i>
                    </a>

                    <ng-container *ngIf="user.isBlocked; else blockUserBtn">                     
                      <a class="danger p-0" data-original-title="" title="" (click)="confirmUnblockClient(user.id)">
                        <i class="ft-unlock font-medium-3 mr-2"></i>
                      </a>
                    </ng-container>                      
                    <ng-template #blockUserBtn>
                      <a class="danger p-0" data-original-title="" title="" (click)="confirmBlockClient(user.id)">
                        <i class="ft-lock font-medium-3 mr-2"></i>
                      </a> 
                    </ng-template>       

                  </td>
                </tr>
                </tbody>
              </table>

              <div class="row">
                  <div class="col-sm-12 col-md-5">
                      <div class="dataTables_info">{{'Showing' | translate}}  {{user.page.showingItems}} {{'from' | translate}} {{user.page.totalItems}} {{'items' | translate}}</div>
                      <div class="dataTables_info">{{'TotalPages' | translate}}: {{user.page.totalPages}}</div>
                  </div>
                  <div class="col-sm-12 col-md-7">
                      <nav aria-label="Page navigation mb-3">
                          <ul class="pagination justify-content-end">
                            <li class="paginate_button page-item first">
                              <a href="clients/allclients?page={{user.page.firstPage}}" class="page-link">{{'First' | translate}}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="clients/allclients?page={{user.page.previousPage}}" aria-label="Previous">
                                <span aria-hidden="true">« {{'Prev' | translate}}</span>
                                <span class="sr-only">{{'Previous' | translate}}</span>
                              </a>
                            </li>
  
                            <div *ngIf="user.page.hasPreviousPage">
                                <li class="page-item"><a class="page-link" href="clients/allclients?page={{user.page.previousPage}}">{{user.page.previousPage}}</a></li>
                            </div>                           
                            <li class="page-item active"><a class="page-link" href="#">{{user.page.pageNumber}}</a></li>                         
                            <div *ngIf="user.page.hasNextPage">
                                <li class="page-item"><a class="page-link" href="clients/allclients?page={{user.page.nextPage}}">{{user.page.nextPage}}</a></li>
                            </div>  
                            
                            <li class="page-item">
                              <a class="page-link" href="clients/allclients?page={{user.page.nextPage}}" aria-label="Next">
                                <span aria-hidden="true">{{'Next' | translate}} »</span>
                                <span class="sr-only">{{'Next' | translate}}</span>
                              </a>
                            </li>
                            <li class="paginate_button page-item last">
                              <a href="clients/allclients?page={{user.page.lastPage}}"  class="page-link">{{'Last' | translate}}</a>
                            </li>
                          </ul>
                      </nav>
                  </div>
              </div>
              -->
              <ngx-datatable 
                [rows]="user.users" 
                [columnMode]="'force'"
                class="ngx-datatable bootstrap core-bootstrap fixed-header fixed-row virtualized scroll-horz" 
                [headerHeight]="50" 
                [footerHeight]="50"
                [externalPaging]="true"
                [count]="user.page.totalItems"
                [offset]="page"
                [limit]="size"
                rowHeight="auto" 
                (page)="setPage($event)">
                <ngx-datatable-column name="#" prop="id" [width]="50"></ngx-datatable-column>
                <ngx-datatable-column name="{{'Name' | translate}}" prop="name" ></ngx-datatable-column>
                <ngx-datatable-column name="{{'Phone' | translate}}" prop="phoneNumber" ></ngx-datatable-column>
                <ngx-datatable-column name="{{'Email' | translate}}" prop="email" ></ngx-datatable-column>                
                <ngx-datatable-column name="{{'IsBlocked' | translate}}" prop="isBlocked" >
                  <ng-template let-row="row" ngx-datatable-cell-template>
                    <div *ngIf="row.isBlocked; else blockBadge">
                      <span _ngcontent-ykd-c258="" class="badge bg-light-danger mb-1">
                        {{'Blocked' | translate}}
                      </span>
                    </div>                      
                    <ng-template #blockBadge>
                      <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                        {{'Active' | translate}}
                      </span>                       
                    </ng-template>
                  </ng-template>
                </ngx-datatable-column>   
                <ngx-datatable-column name="Actions">
                  <ng-template let-row="row"  ngx-datatable-cell-template>
                    <a class="danger p-0" data-original-title="" title="edit" (click)="viewPage(row.id)">
                      <i class="ft-edit text-primary cursor-pointer"></i>
                    </a>
                    <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDeleteCar(row.id)">
                      <i class="ft-trash text-primary cursor-pointer mr-2"></i>
                    </a>
                  </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    
    
    
