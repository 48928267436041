export enum StaffSortState {
    IdAsc,
    IdDesc,
    NameAsc,
    NameDesc,
    EmailAsc,
    EmailDesc,
    PhoneNumberAsc,
    PhoneNumberDesc,
    CreateTimeAsc,
    CreateTimeDesc,
    IsBlockedAsc,
    IsBlockedDesc
}