import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';

import { UserCreate } from '../models/client/userCreate';
import { ClientService } from './client.service';

@Component({
  selector: 'app-root',
  templateUrl: './add-user.component.html',
  providers: [ClientService]
})
export class AddUserComponent {

  user: UserCreate = new UserCreate();

  constructor(private router: Router,
              private clientService: ClientService,
              private translate: TranslateService) {

  }

  createUser(): void {
    this.clientService.createClient(this.user)
        .subscribe( data => {
          this.router.navigate(['/clients/allclients']);
        });
  };


  // Confirm Button Action
  confirmCancel() {
    swal.fire({
        title: this.translate.instant('AreYouSure'),
        text: this.translate.instant('AnyUnsavedDataWillBeLost'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('Cancel'),
        confirmButtonText: this.translate.instant('Yes')
    }).then((result) => {
        if (result.value) {
            this.router.navigate(['/clients/allclients']);
        }
    })
  }

}