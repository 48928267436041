<section  id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'FlatManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'FlatManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-content">
                    <div class="card-header pb-2">
                        <div class="row">
                            <div class="col-md-8">
                                <h4 class="card-title" id="horz-layout-colored-controls">{{'Flat' | translate}}: {{item.title}} (#{{item.internalId}})</h4>
                                <p class="secondary">{{'CreateTime' | translate}}: {{item.createTime | date:'dd/MM/yy h:mm:ss a'}} | {{'LastUpdate' | translate}}: {{item.updateTime | date:'dd/MM/yy h:mm:ss a'}}</p>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        <div *ngIf="viewType === 'admin'">
                            <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs justify-content-left"> 
                                <li [ngbNavItem]="1">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                      <i class="ft-home mr-1"></i>
                                      <span class="d-none d-sm-block">{{'BaseInfo' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">
                                          <div class="col-xl-3 col-lg-6 col-12">
                                              <div class="card card-inverse bg-danger bg-lighten-3">
                                                  <div class="card-content">
                                                      <div class="card-body">
                                                          <div class="media">
                                                              <div class="media-body text-left danger">
                                                                  <h3 class="card-text danger">-5200 pln</h3>
                                                                  <a (click)="viewPageLandlord(landlord.id)">
                                                                      <span style="font-weight: 600;">{{landlord.firstName}} {{landlord.lastName}}</span>
                                                                      <br/><span style="font-size: 0.8rem">{{landlord.email}}, <br/> {{landlord.phoneNumber}}</span>
                                                                  </a>
                                                              </div>
                                                              <div class="media-right align-self-center">
                                                                  <i class="ft-home font-large-2 float-right"></i>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                          <div class="col-xl-3 col-lg-6 col-12">
                                              <div *ngIf="!rcontract || rcontract === ''; else nonTenantBadge">
                                                  <div class="card card-inverse bg-secondary bg-lighten-3">
                                                      <div class="card-content">
                                                          <div class="card-body">
                                                              <div class="media">
                                                                  <div class="media-body text-left">
                                                                      <h3 class="card-text secondary">Unknown</h3>
                                                                  </div>
                                                                  <div class="media-right align-self-center">
                                                                      <i class="ft-user font-large-2 float-right"></i>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>   
                                              </div>                      
                                              <ng-template #nonTenantBadge>
                                                  <div class="card card-inverse bg-success bg-lighten-3">
                                                      <div class="card-content">
                                                          <div class="card-body">
                                                              <div class="media">
                                                                  <div class="media-body text-left">
                                                                      <h3 class="card-text success">+4300 pln</h3>
                                                                      <a (click)="viewPageTenant(rcontract.tenant.id)">
                                                                          <span style="font-weight: 600;">{{rcontract.tenant.firstName}} {{rcontract.tenant.lastName}}</span>
                                                                          <br/><span style="font-size: 0.8rem">{{rcontract.tenant.email}}, <br/> {{rcontract.tenant.phoneNumber}}</span>
                                                                      </a>
                                                                  </div>
                                                                  <div class="media-right align-self-center">
                                                                      <i class="ft-user font-large-2 float-right"></i>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>              
                                              </ng-template> 
                                          </div>
                                          <div class="col-xl-3 col-lg-6 col-12">
                                              <div *ngIf="!rcontract || rcontract === ''; else nonContractBadge">
                                                  <div class="card card-inverse bg-secondary bg-lighten-3">
                                                      <div class="card-content">
                                                          <div class="card-body">
                                                              <div class="media">
                                                                  <div class="media-body text-left">
                                                                      <h3 class="card-text secondary">Unknown</h3>
                                                                  </div>
                                                                  <div class="media-right align-self-center">
                                                                      <i class="ft-file font-large-2 float-right"></i>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>                      
                                              <ng-template #nonContractBadge>
                                                  <div class="card card-inverse bg-info bg-lighten-3">
                                                      <div class="card-content">
                                                          <div class="card-body">
                                                              <div class="media">
                                                                  <div class="media-body text-left">
                                                                      <h3 class="card-text info">{{rcontract.status}}</h3>
                                                                      <span style="font-weight: 600;">{{rcontract.startDate | date:'dd/MM/yy'}} - {{rcontract.endDate | date:'dd/MM/yy'}}</span>
                                                                      <br/><span style="font-size: 0.8rem">{{'RentPrice' | translate}}: 3500 pln <br/> {{'HMGTfee' | translate}}: 400 pln</span>
                                                                  </div>
                                                                  <div class="media-right align-self-center">
                                                                      <i class="ft-file font-large-2 float-right"></i>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>                   
                                              </ng-template>
                                          </div>
                                          <div class="col-xl-3 col-lg-6 col-12">
                                              <div class="card card-inverse bg-info bg-lighten-3">
                                                  <div class="card-content">
                                                      <div class="card-body">
                                                      <div class="media">
                                                          <div class="media-body text-left">
                                                              <h3 class="card-text info">{{monthlyPayment}} pln</h3>
                                                              <span style="font-weight: 600;">{{'MonthlyPayment' | translate}}</span>
                                                              <br/><span style="font-size: 0.8rem">{{'AdminFees' | translate}}: {{monthlyAdminPayment}} PLN</span>
                                                          </div>
                                                          <div class="media-right align-self-center">
                                                              <i class="ft-file font-large-2 float-right"></i>
                                                          </div>
                                                      </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      
                                      <h4 class="card-title" style="margin-top: 50px;">{{'CostsAareement' | translate}}</h4>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="actualExpenses" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                      <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                          {{row.status}}
                                                      </span>
                                                  </div>                      
                                                  <ng-template #nonActiveBadge>
                                                      <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                          {{row.status}}
                                                      </span>                       
                                                  </ng-template>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Type' | translate}}" prop="type"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'AccountNumber' | translate}}" prop="accountNumber"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                              <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('costs-of-agreements', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                              </a>
                                              <a class="danger p-0" data-original-title="" title="edit" (click)="openExpensesEditDialog(row.id)">
                                                  <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                              </a>
                                              <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 7)">
                                                  <i class="ft-trash cursor-pointer mr-2"></i>
                                              </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>   
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="9">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-edit mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Edit' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <form class="form">
                                          <div class="form-body">
                                              <div class="row">                                  
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                      <h4 class="mb-3"><i class="ft-home mr-2"></i>{{'BaseInfo' | translate}}</h4>
                                                      <div class="form-group">
                                                          <label>{{'InternalId' | translate}} *</label>
                                                          <input [(ngModel)]="item.internalId" placeholder="{{'EnterThe' | translate}}{{'internalId' | translate}}" 
                                                              name="internalId" class="form-control" 
                                                              id="internalId" #internalId="ngModel" 
                                                              type="number" required>
                                                          <div [hidden]="internalId.valid || internalId.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input [(ngModel)]="item.title" placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" 
                                                              name="title" class="form-control" 
                                                              id="position" #title="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="title.valid || title.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'YearBuilt' | translate}} *</label>
                                                          <input [(ngModel)]="item.yearBuilt" placeholder="{{'EnterThe' | translate}}{{'YearBuilt' | translate}}" 
                                                              name="yearBuilt" class="form-control" 
                                                              id="position" #yearBuilt="ngModel" 
                                                              type="number" required>
                                                          <div [hidden]="yearBuilt.valid || yearBuilt.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="item.description" id="bdescription" rows="8" 
                                                              class="form-control" name="description"
                                                              #description="ngModel" maxlength="200"></textarea>
                                                      </div>
                                                  </div>
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">                                       
                                                      <h4 class="mb-3"><i class="ft-map mr-2"></i>{{'Location' | translate}}</h4>
                                                      <div class="form-group">
                                                          <label>{{'City' | translate}} *</label>
                                                          <input [(ngModel)]="item.city" placeholder="{{'EnterThe' | translate}}{{'City' | translate}}" 
                                                              name="city" class="form-control" 
                                                              id="position" #city="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="city.valid || city.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Street' | translate}} *</label>
                                                          <input [(ngModel)]="item.street" placeholder="{{'EnterThe' | translate}}{{'Street' | translate}}" 
                                                              name="street" class="form-control" 
                                                              id="street" #street="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="street.valid || street.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Building' | translate}} *</label>
                                                          <input [(ngModel)]="item.building" placeholder="{{'EnterThe' | translate}}{{'Building' | translate}}" 
                                                              name="building" class="form-control" 
                                                              id="building" #building="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="building.valid || building.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Apartment' | translate}} *</label>
                                                          <input [(ngModel)]="item.apartment" placeholder="{{'EnterThe' | translate}}{{'Apartment' | translate}}" 
                                                              name="apartment" class="form-control" 
                                                              id="apartment" #apartment="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="apartment.valid || apartment.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Postcode' | translate}} *</label>
                                                          <input [(ngModel)]="item.postcode" placeholder="{{'EnterThe' | translate}}{{'Postcode' | translate}}" 
                                                              name="postcode" class="form-control" 
                                                              id="postcode" #postcode="ngModel" 
                                                              minlength="1" maxlength="50" required>
                                                          <div [hidden]="postcode.valid || postcode.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                      <h4 class="mb-3"><i class="ft-layout mr-2"></i>{{'Size' | translate}}</h4>
                                                      <div class="form-group">
                                                          <label>{{'Size' | translate}} *</label>
                                                          <input [(ngModel)]="item.size" placeholder="{{'EnterThe' | translate}}{{'Size' | translate}}" 
                                                              name="size" class="form-control" 
                                                              id="size" #size="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                              <div [hidden]="size.valid || size.untouched" class="text-danger">
                                                                  {{'FieldRequired' | translate}}
                                                              </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Floor' | translate}} *</label>
                                                          <input [(ngModel)]="item.floor" placeholder="{{'EnterThe' | translate}}{{'Floor' | translate}}" 
                                                              name="floor" class="form-control" 
                                                              id="floor" #floor="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                          <div [hidden]="floor.valid || floor.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'CountRooms' | translate}} *</label>
                                                          <input [(ngModel)]="item.countRooms" placeholder="{{'EnterThe' | translate}}{{'CountRooms' | translate}}" 
                                                              name="countRooms" class="form-control" 
                                                              id="countRooms" #countRooms="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                          <div [hidden]="countRooms.valid || countRooms.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'CountParkingSpots' | translate}} *</label>
                                                          <input [(ngModel)]="item.countParkingSpots" placeholder="{{'EnterThe' | translate}}{{'CountParkingSpots' | translate}}" 
                                                              name="countParkingSpots" class="form-control" 
                                                              id="countParkingSpots" #countParkingSpots="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                          <div [hidden]="countParkingSpots.valid || countParkingSpots.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>                                        
                                                  </div>
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                      <h4 class="mb-3"><i class="ft-dollar-sign mr-2"></i>{{'Costs' | translate}}</h4>
                                                      <div class="form-group">
                                                          <label>{{'Price' | translate}} *</label>
                                                          <input [(ngModel)]="item.price" placeholder="{{'EnterThe' | translate}}{{'price' | translate}}" 
                                                              name="price" class="form-control" 
                                                              id="position" #price="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                              <div [hidden]="price.valid || price.untouched" class="text-danger">
                                                                  {{'FieldRequired' | translate}}
                                                              </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Deposit' | translate}} *</label>
                                                          <input [(ngModel)]="item.deposit" placeholder="{{'EnterThe' | translate}}{{'Deposit' | translate}}" 
                                                              name="deposit" class="form-control" 
                                                              id="deposit" #deposit="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                              <div [hidden]="deposit.valid || deposit.untouched" class="text-danger">
                                                                  {{'FieldRequired' | translate}}
                                                              </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'AdminFees' | translate}} *</label>
                                                          <input [(ngModel)]="item.adminFees" placeholder="{{'EnterThe' | translate}}{{'AdminFees' | translate}}" 
                                                              name="adminFees" class="form-control" 
                                                              id="adminFees" #adminFees="ngModel" 
                                                              type="number" min="0" max="10000000" required>
                                                              <div [hidden]="adminFees.valid || adminFees.untouched" class="text-danger">
                                                                  {{'FieldRequired' | translate}}
                                                              </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'AccountNumber' | translate}} *</label>
                                                          <input [(ngModel)]="item.accountNumber" placeholder="{{'EnterThe' | translate}}{{'AccountNumber' | translate}}" 
                                                              name="accountNumber" class="form-control" 
                                                              id="accountNumber" #accountNumber="ngModel" 
                                                              mask="00 0000 0000 0000 0000 0000 0000"
                                                              required >
                                                          <div [hidden]="accountNumber.valid || accountNumber.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'AccountNumber2' | translate}} *</label>
                                                          <input [(ngModel)]="item.accountNumber2" placeholder="{{'EnterThe' | translate}}{{'AccountNumber2' | translate}}" 
                                                              name="accountNumber2" class="form-control" 
                                                              id="accountNumber2" #accountNumber2="ngModel" 
                                                              mask="00 0000 0000 0000 0000 0000 0000"
                                                              required>
                                                          <div [hidden]="accountNumber2.valid || accountNumber2.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'AccountNumber3' | translate}} *</label>
                                                          <input [(ngModel)]="item.accountNumber3" placeholder="{{'EnterThe' | translate}}{{'AccountNumber3' | translate}}" 
                                                              name="accountNumber3" class="form-control" 
                                                              id="accountNumber3" #accountNumber3="ngModel" 
                                                              mask="00 0000 0000 0000 0000 0000 0000"
                                                              required>
                                                          <div [hidden]="accountNumber3.valid || accountNumber3.untouched" class="text-danger">
                                                              {{'FieldValidation1-50' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Balance' | translate}} *</label>
                                                          <input type="number"  [(ngModel)]="item.balance"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Balance' | translate}}" 
                                                              name="itemBalance" class="form-control"  required>
                                                      </div>               
                                                  </div>
                                                  
                                              </div>
                                              <div class="row">                                  
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                      <h4 class="mb-3"><i class="ft-settings mr-2"></i>{{'Features' | translate}}</h4>
                                                      <div class="form-group">
                                                          <div class="custom-control custom-switch custom-control-inline">
                                                              <input type="checkbox" [(ngModel)]="item.hasBalcony" checked="item.hasBalcony"
                                                                  class="custom-control-input" name="hasBalcony" id="hasBalcony">
                                                              <label class="custom-control-label" for="hasBalcony">
                                                                  {{'HasBalcony' | translate}}
                                                              </label>
                                                          </div>                                   
                                                      </div>
                                                      <div class="form-group">
                                                          <div class="custom-control custom-switch custom-control-inline">
                                                              <input type="checkbox" [(ngModel)]="item.hasTerrace" checked="item.hasTerrace"
                                                                  class="custom-control-input" name="hasTerrace" id="hasTerrace">
                                                              <label class="custom-control-label" for="hasTerrace">
                                                                  {{'HasTerrace' | translate}}
                                                              </label>
                                                          </div>                                   
                                                      </div>
                                                      <div class="form-group">
                                                          <div class="custom-control custom-switch custom-control-inline">
                                                              <input type="checkbox" [(ngModel)]="item.petAllowed" checked="item.petAllowed"
                                                                  class="custom-control-input" name="petAllowed" id="petAllowed">
                                                              <label class="custom-control-label" for="petAllowed">
                                                                  {{'PetAllowed' | translate}}
                                                              </label>
                                                          </div>                                   
                                                      </div>                                             
                                                  </div>
                                                  <div class="col-12 col-md-6 mb-3 mb-md-0">
                                                      <h4 class="mb-3"><i class="ft-user mr-2"></i>{{'Landlord' | translate}}</h4>
                                                      <div class="form-group">
                                                          <label>{{'Landlord' | translate}} *</label>                                  
                                                          <select class="form-control" [(ngModel)]="item.landlordId" 
                                                                  name="landlordId" #landlordId="ngModel" required>
                                                              <option *ngFor="let landlord of landlords" value="{{landlord.id}}" 
                                                                  [selected]="landlord.id === currentLandlordId"> 
                                                                  ID#{{landlord.id}} {{landlord.firstName}} {{landlord.lastName}}
                                                              </option>
                                                          </select>
                                                          <div [hidden]="landlordId.valid || landlordId.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="row">   
                                                  <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                                      <button type="button" [disabled]="title.invalid || yearBuilt.invalid || city.invalid || 
                                                              street.invalid || building.invalid || apartment.invalid || postcode.invalid || price.invalid ||
                                                              size.invalid || countRooms.invalid || deposit.invalid || adminFees.invalid || floor.invalid || 
                                                              countParkingSpots.invalid || landlordId.invalid || accountNumber2.invalid || accountNumber3.invalid"  
                                                          class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="update()"> 
                                                          {{'Save' | translate}}
                                                      </button>
                                                      <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                                          {{'Cancel' | translate}}
                                                      </button> 
                                                  </div>
                                              </div> 
                                             
                                          </div>
                                      </form>
                                  </ng-template>
                                </li>
                
                                <li [ngbNavItem]="2">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-monitor mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Equipments' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">   
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                              <ng-template #contentEquipment let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewEquipment' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="equipment.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" 
                                                              id="equipmentCreateTitle" #equipmentCreateTitle="ngModel" 
                                                              name="equipmentTitle" class="form-control"  required>
                                                          <div [hidden]="equipmentCreateTitle.valid || equipmentCreateTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>  
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="equipment.description" rows="5" 
                                                              class="form-control" name="equipmentDescription"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="equipment.comment" rows="5" 
                                                              class="form-control" name="equipmentComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, equipment)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" 
                                                          [disabled]="equipmentCreateTitle.invalid"
                                                          (click)="addEquipment(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
      
                                              <ng-template #contentEquipmentEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} #{{equipmentEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="equipmentEdit.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}"
                                                              id="equipmentEditTitle" #equipmentEditTitle="ngModel"  
                                                              name="equipmentTitle" class="form-control"  required>
                                                          <div [hidden]="equipmentEditTitle.valid || equipmentEditTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="equipmentEdit.description" rows="5" 
                                                              class="form-control" name="equipmentDescription"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="equipmentEdit.comment" rows="5" 
                                                              class="form-control" name="equipmentComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group" *ngIf="equipmentEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of equipmentEdit.fileNames">
                                                                  <span class="fileName cursor-pointer" 
                                                                          (click)="openFilePreview('equipments', fileName, equipmentEdit.id)">
                                                                      {{ fileName }}
                                                                  </span>
                                                                  <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('equipments', fileName, equipmentEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                  </a>
                                                                  <a class="danger p-0" data-original-title="" title="delete" 
                                                                      (click)="removeFile('equipments', fileName, equipmentEdit.id)">
                                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                                  </a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, equipmentEdit)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" 
                                                      [disabled]="equipmentEditTitle.invalid"
                                                      class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateEquipment(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
                                              <button type="button" (click)="open(contentEquipment)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
                                          </div>
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="equipments" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Tile' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openEquipmentEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('equipments', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="edit" (click)="openEquipmentEditDialog(row.id)">
                                                  <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 1)">
                                                  <i class="ft-trash cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                      </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="3">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-file-text mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Documents' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">   
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                              <ng-template #contentDocument let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewDocument' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="agreement.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}"
                                                              id="agreementCreateTitle" #agreementCreateTitle="ngModel"  
                                                              name="agreementTitle" class="form-control"  required>
                                                          <div [hidden]="agreementCreateTitle.valid || agreementCreateTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>   
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} *</label>
                                                          <textarea [(ngModel)]="agreement.description" rows="5" 
                                                              class="form-control" name="eagreementDescription"
                                                              #agreementCreateDescription="ngModel" 
                                                              maxlength="200" required></textarea>
                                                          <div [hidden]="agreementCreateDescription.valid || agreementCreateDescription.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div> 
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Type' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="agreement.type" 
                                                              name="agreementType" required>
                                                              <option *ngFor="let type of agreementType" value="{{type}}">
                                                                  {{type}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'StartDate' | translate}}</label>
                                                          <input [(ngModel)]="agreement.startDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}"
                                                              id="agreementCreateStartDate" #agreementCreateStartDate="ngModel"  
                                                              name="agreementStartDate" class="form-control"                                                      
                                                              type="Date">
                                                          <div [hidden]="agreementCreateStartDate.valid || agreementCreateStartDate.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div> 
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDate' | translate}}</label>
                                                          <input [(ngModel)]="agreement.endDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}"
                                                              id="agreementCreateEndDate" #agreementCreateEndDate="ngModel"  
                                                              name="agreementEndDate" class="form-control"                                                      
                                                              type="Date">
                                                          <div [hidden]="agreementCreateEndDate.valid || agreementCreateEndDate.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div> 
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, agreement)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" 
                                                      [disabled]="agreementCreateTitle.invalid || agreementCreateStartDate.invalid || agreementCreateEndDate.invalid
                                                                   || agreementCreateDescription.invalid"
                                                      class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="addAgreement(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
      
                                              <ng-template #contentAgreementEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} #{{agreementEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="agreementEdit.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" 
                                                              id="agreementUpdateTitle" #agreementUpdateTitle="ngModel" 
                                                              name="agreementTitle" class="form-control"  required>
                                                          <div [hidden]="agreementUpdateTitle.valid || agreementUpdateTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>    
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} *</label>
                                                          <textarea [(ngModel)]="agreementEdit.description" rows="5" 
                                                              class="form-control" name="eagreementDescription"
                                                              #agreementUpdateDescription="ngModel"
                                                              maxlength="200" required></textarea>
                                                          <div [hidden]="agreementUpdateDescription.valid || agreementUpdateDescription.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div> 
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Type' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="agreementEdit.type" 
                                                              name="agreementType" required>
                                                              <option *ngFor="let type of agreementType" value="{{type}}">
                                                                  {{type}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'StartDate' | translate}}</label>
                                                          <input [(ngModel)]="agreementEdit.startDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}" 
                                                              id="agreementUpdateStartDate" #agreementUpdateStartDate="ngModel" 
                                                              name="agreementStartDate" class="form-control"                                                      
                                                              type="Date">
                                                          <div [hidden]="agreementUpdateStartDate.valid || agreementUpdateStartDate.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDate' | translate}}</label>
                                                          <input [(ngModel)]="agreementEdit.endDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}" 
                                                              id="agreementUpdateEndDate" #agreementUpdateEndDate="ngModel"
                                                              name="agreementEndDate" class="form-control"                                                      
                                                              type="Date">
                                                          <div [hidden]="agreementUpdateEndDate.valid || agreementUpdateEndDate.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group" *ngIf="agreementEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of agreementEdit.fileNames">
                                                                <span class="fileName cursor-pointer" 
                                                                      (click)="openFilePreview('agreements', fileName, agreementEdit.id)">
                                                                  {{ fileName }}
                                                                </span>
                                                                <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('agreements', fileName, agreementEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                </a>
                                                                <a class="danger p-0" data-original-title="" title="delete" 
                                                                    (click)="removeFile('agreements', fileName, agreementEdit.id)">
                                                                    <i class="ft-trash cursor-pointer mr-2"></i>
                                                                </a>
                                                              </li>
                                                          </ul>        
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, agreementEdit)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" 
                                                      [disabled]="agreementUpdateTitle.invalid || agreementUpdateStartDate.invalid || agreementUpdateEndDate.invalid 
                                                      || agreementUpdateDescription.invalid"
                                                      class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateAgreement(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
      
      
                                              <button type="button" (click)="open(contentDocument)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
                                          </div>
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="agreements" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Tile' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openAgreementEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'StartDate' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.startDate ? (row.startDate | date:'dd/MM/yy') : '-'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'EndDate' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.endDate ? (row.endDate | date:'dd/MM/yy') : '-'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Type' | translate}}" prop="type"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('agreements', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="edit" (click)="openAgreementEditDialog(row.id)">
                                                  <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 2)">
                                                  <i class="ft-trash cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="4">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-clipboard mr-1"></i>
                                    <span class="d-none d-sm-block"> {{'Inspections' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                          <ng-template #contentInspection let-c="close" let-d="dismiss">
                                              <div class="modal-header">
                                                <h4 class="modal-title">{{'AddNewInspections' | translate}}</h4>
                                                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                  <span aria-hidden="true">&times;</span>
                                                </button>
                                              </div>
                                              <div class="modal-body">
                                                <form class="form">
                                                  <div class="form-group">
                                                      <input id="files" type="file" (change)="handleFileSelection($event, inspection)" multiple />
                                                  </div>
                                                </form>
                                              </div>
                                              <div class="modal-footer">                                         
                                                <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="addInspection()">{{'Save' | translate}}</button>
                                                <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                              </div>
                                          </ng-template>
                                          <button type="button" (click)="open(contentInspection)"
                                              class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                              {{'Add' | translate}}
                                          </button>
                                      </div>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="inspections" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('inspections', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 3)">
                                                  <i class="ft-trash cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column>              
                                      </ngx-datatable>    
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="5">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-droplet mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Meters' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                              <ng-template #content let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewMeter' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Type' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="meter.type" 
                                                              name="meterType" #type="ngModel" required>
                                                              <option *ngFor="let type of meterTypes" value="{{type}}">
                                                                  {{type}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Category' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="meter.category" 
                                                              name="meterCategory" #category="ngModel" required>
                                                              <option *ngFor="let category of meterCategories" value="{{category}}">
                                                                  {{category}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Value' | translate}} *</label>
                                                          <input  [(ngModel)]="meter.value"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}" 
                                                              id="meterCreateValue" #meterCreateValue="ngModel" 
                                                              name="meterValue" class="form-control" 
                                                              type="number" required>
                                                          <div [hidden]="meterCreateValue.valid || meterCreateValue.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div> 
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, meter)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" 
                                                      [disabled]="meterCreateValue.invalid"
                                                      class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="addMeter(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
                                              <button type="button" (click)="open(content)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
      
                                              <ng-template #contentMeterEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Edit' | translate}} #{{meterEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Type' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="meterEdit.type" 
                                                              name="meterType" #type="ngModel" required>
                                                              <option *ngFor="let type of meterTypes" value="{{type}}">
                                                                  {{type}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Category' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="meterEdit.category" 
                                                              name="meterCategory" #category="ngModel" required>
                                                              <option *ngFor="let category of meterCategories" value="{{category}}">
                                                                  {{category}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Value' | translate}} *</label>
                                                          <input  [(ngModel)]="meterEdit.value"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}" 
                                                              id="meterUpdateValue" #meterUpdateValue="ngModel"
                                                              name="meterValue" class="form-control" 
                                                              type="number" required>
                                                          <div [hidden]="meterUpdateValue.valid || meterUpdateValue.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div> 
                                                      </div>
                                                      <div class="form-group" *ngIf="meterEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of meterEdit.fileNames">
                                                                  <span class="fileName cursor-pointer" 
                                                                          (click)="openFilePreview('meters', fileName, meterEdit.id)">
                                                                      {{ fileName }}
                                                                  </span>
                                                                  <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('meters', fileName, meterEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                  </a>
                                                                  <a class="danger p-0" data-original-title="" title="delete" 
                                                                      (click)="removeFile('meters', fileName, meterEdit.id)">
                                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                                  </a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, meterEdit)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" 
                                                      [disabled]="meterUpdateValue.invalid"    
                                                      class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateMeter(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
                                          </div>
                                      </div>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="meters" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openMeterEditDialog(row.id)">
                                                      {{row.type}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Category' | translate}}" prop="category"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('meters', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="edit" (click)="openMeterEditDialog(row.id)">
                                                  <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 4)">
                                                  <i class="ft-trash cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>    
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="6">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-file mr-1"></i>
                                    <span class="d-none d-sm-block"> {{'Contracts' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                              <ng-template #contentContract let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewContract' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'StartDate' | translate}} *</label>
                                                          <input [(ngModel)]="contract.startDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}" 
                                                              id="contractCreateStartDate" #contractCreateStartDate="ngModel"
                                                              name="contractStartDate" class="form-control"                                                      
                                                              type="Date" required>                                
                                                          <div [hidden]="contractCreateStartDate.valid || contractCreateStartDate.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div> 
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDate' | translate}} *</label>
                                                          <input [(ngModel)]="contract.endDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}"
                                                              id="contractCreateEndDate" #contractCreateEndDate="ngModel" 
                                                              name="contractEndDate" class="form-control"                                                      
                                                              type="Date" required>
                                                          <div [hidden]="contractCreateEndDate.valid || contractCreateEndDate.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Status' | translate}} *</label>
                                                          <select [(ngModel)]="contract.status" 
                                                              name="contractStatus" class="form-control"  required>
                                                              <option *ngFor="let status of contractStatus" value="{{status}}">
                                                                  {{status}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'RentPrice' | translate}} *</label>
                                                          <input  [(ngModel)]="contract.rentPrice"   
                                                              placeholder="{{'EnterThe' | translate}}{{'RentPrice' | translate}}" 
                                                              id="contractCreateRent" #contractCreateRent="ngModel"
                                                              name="contractRent" class="form-control" 
                                                              type="number" required>
                                                          <div [hidden]="contractCreateRent.valid || contractCreateRent.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'HgmtFee' | translate}} *</label>
                                                          <input  [(ngModel)]="contract.hgmtFee"   
                                                              placeholder="{{'EnterThe' | translate}}{{'HgmtFee' | translate}}" 
                                                              id="contractCreateHgmtFee" #contractCreateHgmtFee="ngModel"
                                                              name="contractHgmtFee" class="form-control" 
                                                              type="number" required>
                                                          <div [hidden]="contractCreateHgmtFee.valid || contractCreateHgmtFee.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Tenant' | translate}} *</label>                                  
                                                          <select class="form-control" [(ngModel)]="contract.tenantId" 
                                                                  name="contractTenantId" required>
                                                              <option *ngFor="let tenant of tenants" value="{{tenant.id}}"> 
                                                                  ID#{{tenant.id}} {{tenant.firstName}} {{tenant.lastName}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Employee' | translate}} *</label>                                  
                                                          <select class="form-control" [(ngModel)]="contract.employeeId" 
                                                                  name="contractEmployeeId" required>
                                                              <option *ngFor="let employee of employees" value="{{employee.id}}"> 
                                                                  ID#{{employee.id}} {{employee.firstName}} {{employee.lastName}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, contract)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit"
                                                      [disabled]="contractCreateStartDate.invalid || contractCreateEndDate.invalid || 
                                                                  contractCreateRent.invalid || contractCreateHgmtFee.invalid"
                                                      class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="addContract(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
                                              <button type="button" (click)="open(contentContract)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
      
                                              <ng-template #contentContractEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Edit' | translate}} #{{contractEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'StartDate' | translate}} *</label>
                                                          <input [(ngModel)]="contractEdit.startDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}" 
                                                              id="contractUpdateStartDate" #contractUpdateStartDate="ngModel"
                                                              name="contractStartDate" class="form-control"                                                      
                                                              type="Date" required>
                                                          <div [hidden]="contractUpdateStartDate.valid || contractUpdateStartDate.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDate' | translate}} *</label>
                                                          <input [(ngModel)]="contractEdit.endDate" 
                                                              placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}" 
                                                              id="contractUpdateEndDate" #contractUpdateEndDate="ngModel"
                                                              name="contractEndDate" class="form-control"                                                      
                                                              type="Date" required>
                                                          <div [hidden]="contractUpdateEndDate.valid || contractUpdateEndDate.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Status' | translate}} *</label>
                                                          <select [(ngModel)]="contractEdit.status" 
                                                              name="contractStatus" class="form-control"  required>
                                                              <option *ngFor="let status of contractStatus" value="{{status}}">
                                                                  {{status}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'RentPrice' | translate}} *</label>
                                                          <input  [(ngModel)]="contractEdit.rentPrice"   
                                                              placeholder="{{'EnterThe' | translate}}{{'RentPrice' | translate}}" 
                                                              id="contractUpdateRent" #contractUpdateRent="ngModel"
                                                              name="contractRent" class="form-control" 
                                                              type="number" required>
                                                          <div [hidden]="contractUpdateRent.valid || contractUpdateRent.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'HgmtFee' | translate}} *</label>
                                                          <input  [(ngModel)]="contractEdit.hgmtFee"   
                                                              placeholder="{{'EnterThe' | translate}}{{'HgmtFee' | translate}}" 
                                                              id="contractUpdateHgmtFee" #contractUpdateHgmtFee="ngModel"
                                                              name="contractHgmtFee" class="form-control" 
                                                              type="number" required>
                                                          <div [hidden]="contractUpdateHgmtFee.valid || contractUpdateHgmtFee.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Tenant' | translate}} *</label>                                  
                                                          <select class="form-control" [(ngModel)]="contractEdit.tenantId" 
                                                                  name="contractTenantId" required>
                                                              <option *ngFor="let tenant of tenants" value="{{tenant.id}}"> 
                                                                  ID#{{tenant.id}} {{tenant.firstName}} {{tenant.lastName}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Employee' | translate}} *</label>                                  
                                                          <select class="form-control" [(ngModel)]="contractEdit.employeeId" 
                                                                  name="contractEmployeeId" required>
                                                              <option *ngFor="let employee of employees" value="{{employee.id}}"> 
                                                                  ID#{{employee.id}} {{employee.firstName}} {{employee.lastName}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group" *ngIf="contractEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of contractEdit.fileNames">
                                                                  <span class="fileName cursor-pointer" 
                                                                          (click)="openFilePreview('rental-contracts', fileName, contractEdit.id)">
                                                                      {{ fileName }}
                                                                  </span>
                                                                  <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('rental-contracts', fileName, contractEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                  </a>
                                                                  <a class="danger p-0" data-original-title="" title="delete" 
                                                                      (click)="removeFile('rental-contracts', fileName, contractEdit.id)">
                                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                                  </a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, contractEdit)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" 
                                                      [disabled]="contractUpdateStartDate.invalid || contractUpdateEndDate.invalid || 
                                                                  contractUpdateRent.invalid || contractUpdateHgmtFee.invalid"
                                                      class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateContract(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
                                          </div>
                                      </div>
                                          <ngx-datatable class="bootstrap core-bootstrap" 
                                              [rows]="contracts" 
                                              [columnMode]="'force'" 
                                              [headerHeight]="50" 
                                              [footerHeight]="50"
                                              [rowHeight]="'auto'"
                                              [limit]="15">
                                              <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                          <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                              {{row.status}}
                                                          </span>
                                                      </div>                      
                                                      <ng-template #nonActiveBadge>
                                                          <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                              {{row.status}}
                                                          </span>                       
                                                      </ng-template>
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'StartDate' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      <a class="primary p-0" data-original-title="" (click)="openContractEditDialog(row.id)">
                                                          {{row.startDate | date:'dd/MM/yy'}}
                                                      </a>
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'EndDate' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      {{row.endDate | date:'dd/MM/yy'}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'RentPrice' | translate}}" prop="rentPrice"  ></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'HGMTFee' | translate}}" prop="hgmtFee"></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="Actions">
                                                  <ng-template let-row="row"  ngx-datatable-cell-template>
                                                    <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('rental-contracts', row.id)">
                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                    </a>
                                                    <a class="danger p-0" data-original-title="" title="edit" (click)="openContractEditDialog(row.id)">
                                                      <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                                    </a>
                                                    <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 5)">
                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                    </a>
                                                  </ng-template>
                                              </ngx-datatable-column> 
                                          </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="7">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-dollar-sign mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Invoices' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">
                                          <div class="col-2 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                              <h2 class="card-title text-right">
                                                  {{'Balance' | translate}}: {{balance}} PLN
                                              </h2>
                                          </div>   
                                          <div class="col-10 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                              <ng-template #contentInvoice let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewInvoice' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="invoice.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}"
                                                              id="invoiceCreateTitle" #invoiceCreateTitle="ngModel" 
                                                              name="invoiceTitle" class="form-control"  required>
                                                          <div [hidden]="invoiceCreateTitle.valid || invoiceCreateTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Value' | translate}} *</label>
                                                          <input type="number"  [(ngModel)]="invoice.value"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}"
                                                              id="invoiceCreateValue" #invoiceCreateValue="ngModel"  
                                                              name="invoiceValue" class="form-control"  required>
                                                          <div [hidden]="invoiceCreateValue.valid || invoiceCreateValue.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDate' | translate}} *</label>
                                                          <input [(ngModel)]="invoice.endTime" 
                                                              placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}"
                                                              id="invoiceCreateEndTime" #invoiceCreateEndTime="ngModel"  
                                                              name="invoiceEndTime" class="form-control"                                                      
                                                              type="Date" required>  
                                                          <div [hidden]="invoiceCreateEndTime.valid || invoiceCreateEndTime.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="invoice.description" rows="5" 
                                                              class="form-control" name="invoiceDescription"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="invoice.comment" rows="5" 
                                                              class="form-control" name="invoiceComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Status' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="invoice.status" 
                                                              name="invoiceStatus" required>
                                                              <option *ngFor="let status of invoiceStatus" value="{{status}}">
                                                                  {{status}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, invoice)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" 
                                                      [disabled]="invoiceCreateTitle.invalid || invoiceCreateValue.invalid || invoiceCreateEndTime.invalid"
                                                      class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="addInvoice(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
                                              <button type="button" (click)="open(contentInvoice)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
      
                                              <ng-template #contentInvoiceEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} #{{invoiceEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="invoiceEdit.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}"
                                                              id="invoiceUpdateTitle" #invoiceUpdateTitle="ngModel"
                                                              name="invoiceTitle" class="form-control"  required>
                                                          <div [hidden]="invoiceUpdateTitle.valid || invoiceUpdateTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Value' | translate}} *</label>
                                                          <input type="number"  [(ngModel)]="invoiceEdit.value"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}"
                                                              id="invoiceUpdateValue" #invoiceUpdateValue="ngModel" 
                                                              name="invoiceValue" class="form-control"  required>
                                                          <div [hidden]="invoiceUpdateValue.valid || invoiceUpdateValue.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'EndDate' | translate}} *</label>
                                                          <input [(ngModel)]="invoiceEdit.endTime" 
                                                              placeholder="{{'EnterThe' | translate}}{{'EndDate' | translate}}" 
                                                              id="contractUpdateEndTime" #contractUpdateEndTime="ngModel"
                                                              name="contractEndTime" class="form-control"                                                      
                                                              type="Date" required>
                                                          <div [hidden]="contractUpdateEndTime.valid || contractUpdateEndTime.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="invoiceEdit.description" rows="5" 
                                                              class="form-control" name="invoiceDescription"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="invoiceEdit.comment" rows="5" 
                                                              class="form-control" name="invoiceComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Status' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="invoiceEdit.status" 
                                                              name="invoiceStatus" required>
                                                              <option *ngFor="let status of invoiceStatus" value="{{status}}">
                                                                  {{status}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group" *ngIf="invoiceEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of invoiceEdit.fileNames">
                                                                  <span class="fileName cursor-pointer" 
                                                                          (click)="openFilePreview('invoices', fileName, invoiceEdit.id)">
                                                                      {{ fileName }}
                                                                  </span>
                                                                  <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('invoices', fileName, invoiceEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                  </a>
                                                                  <a class="danger p-0" data-original-title="" title="delete" 
                                                                      (click)="removeFile('invoices', fileName, invoiceEdit.id)">
                                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                                  </a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, invoiceEdit)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" 
                                                          [disabled]="invoiceUpdateTitle.invalid || invoiceUpdateValue.invalid || contractUpdateEndTime.invalid"
                                                          class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateInvoice(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
                                          </div>
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="invoices" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                      {{row.status}}
                                                  </span>
                                                  </div>                      
                                                  <ng-template #nonActiveBadge>
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                      {{row.status}}
                                                  </span>                       
                                                  </ng-template>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Title' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openInvoiceEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('invoices', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="edit" (click)="openInvoiceEditDialog(row.id)">
                                                  <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                                </a>
                                                <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 6)">
                                                  <i class="ft-trash cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>
                                  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="8">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-briefcase mr-1"></i>
                                    <span class="d-none d-sm-block">{{'CostsAareement' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">   
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                              <ng-template #contentExpenses let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewExpenses' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Value' | translate}} *</label>
                                                          <input type="number"  [(ngModel)]="expense.value"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}"
                                                              id="expenseCreateValue" #expenseCreateValue="ngModel"  
                                                              name="expenseValue" class="form-control"  required>
                                                          <div [hidden]="expenseCreateValue.valid || expenseCreateValue.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'AccountNumber' | translate}} *</label>
                                                          <input  [(ngModel)]="expense.accountNumber"   
                                                              placeholder="{{'EnterThe' | translate}}{{'AccountNumber' | translate}}"
                                                              id="expenseCreateAccountNumber" #expenseCreateAccountNumber="ngModel"  
                                                              name="expenseAccountNumber" class="form-control"  required
                                                              mask="00 0000 0000 0000 0000 0000 0000">
                                                          <div [hidden]="expenseCreateAccountNumber.valid || expenseCreateAccountNumber.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="expense.comment" rows="5" 
                                                              class="form-control" name="expenseComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Type' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="expense.type" 
                                                              name="expensesType" required>
                                                              <option *ngFor="let type of expensesType" value="{{type}}">
                                                                  {{type}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Status' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="expense.status" 
                                                              name="expensesStatus" required>
                                                              <option *ngFor="let status of expensesStatus" value="{{status}}">
                                                                  {{status}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'StartDate' | translate}} *</label>
                                                          <input type="Date"  [(ngModel)]="expense.startDate"   
                                                              placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}"
                                                              id="expensesCreateStartDate" #expensesCreateStartDate="ngModel"  
                                                              name="expensesStartDate" class="form-control">
                                                          <div [hidden]="expensesCreateStartDate.valid || expensesCreateStartDate.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, expense)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button 
                                                          [disabled]="expenseCreateValue.invalid || expenseCreateAccountNumber.invalid || expensesCreateStartDate.invalid"
                                                          type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="addExpenses(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
                                              <button type="button" (click)="open(contentExpenses)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
      
                                              <ng-template #contentExpensesEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} #{{expensesEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Value' | translate}} *</label>
                                                          <input type="number"  [(ngModel)]="expensesEdit.value"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}"
                                                              id="expenseUpdateValue" #expenseUpdateValue="ngModel" 
                                                              name="expenseValue" class="form-control"  required>
                                                          <div [hidden]="expenseUpdateValue.valid || expenseUpdateValue.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'AccountNumber' | translate}} *</label>
                                                          <input  [(ngModel)]="expensesEdit.accountNumber"   
                                                              placeholder="{{'EnterThe' | translate}}{{'AccountNumber' | translate}}"
                                                              id="expenseUpdateAccountNumber" #expenseUpdateAccountNumber="ngModel" 
                                                              name="expenseAccountNumber" class="form-control"  required
                                                              mask="00 0000 0000 0000 0000 0000 0000">
                                                          <div [hidden]="expenseUpdateAccountNumber.valid || expenseUpdateAccountNumber.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="expensesEdit.comment" rows="5" 
                                                              class="form-control" name="expenseComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Type' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="expensesEdit.type" 
                                                              name="expensesType" required>
                                                              <option *ngFor="let type of expensesType" value="{{type}}">
                                                                  {{type}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Status' | translate}} *</label>
                                                          <select class="form-control" [(ngModel)]="expensesEdit.status" 
                                                              name="expensesStatus" required>
                                                              <option *ngFor="let status of expensesStatus" value="{{status}}">
                                                                  {{status}}
                                                              </option>
                                                          </select>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'StartDate' | translate}} *</label>
                                                          <input type="Date"  [(ngModel)]="expensesEdit.startDate"   
                                                              placeholder="{{'EnterThe' | translate}}{{'StartDate' | translate}}"
                                                              id="expensesUpdateStartDate" #expensesUpdateStartDate="ngModel"  
                                                              name="expensesStartDate" class="form-control"  required>
                                                          <div [hidden]="expensesUpdateStartDate.valid || expensesUpdateStartDate.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group" *ngIf="expensesEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of expensesEdit.fileNames">
                                                                  <span class="fileName cursor-pointer" 
                                                                          (click)="openFilePreview('costs-of-agreements', fileName, expensesEdit.id)">
                                                                      {{ fileName }}
                                                                  </span>
                                                                  <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('costs-of-agreements', fileName, expensesEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                  </a>
                                                                  <a class="danger p-0" data-original-title="" title="delete" 
                                                                      (click)="removeFile('costs-of-agreements', fileName, expensesEdit.id)">
                                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                                  </a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, expensesEdit)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" 
                                                          [disabled]="expenseUpdateValue.invalid || expenseUpdateAccountNumber.invalid || expensesUpdateStartDate.invalid"
                                                          class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateExpenses(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
                                          </div>
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="expenses" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                      <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                      {{row.status}}
                                                  </span>
                                              </div>                      
                                              <ng-template #nonActiveBadge>
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                      {{row.status}}
                                                  </span>                       
                                              </ng-template>
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              <a class="primary p-0" data-original-title="" (click)="openExpensesEditDialog(row.id)">
                                                  {{row.type}}
                                              </a>
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'AccountNumber' | translate}}" prop="accountNumber"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="Actions">
                                          <ng-template let-row="row"  ngx-datatable-cell-template>
                                            <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('costs-of-agreements', row.id)">
                                              <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                            </a>
                                            <a class="danger p-0" data-original-title="" title="edit" (click)="openExpensesEditDialog(row.id)">
                                              <i class="ft-edit text-primary cursor-pointer mr-2"></i>
                                            </a>
                                            <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id, 7)">
                                              <i class="ft-trash cursor-pointer mr-2"></i>
                                            </a>
                                          </ng-template>
                                      </ngx-datatable-column> 
                                      </ngx-datatable> 
                                  </ng-template>
                           
                                </li>                        
                            </ul>
                            <div class="tab-content">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div><!----./admin-->

                        <div *ngIf="viewType === 'landlord'">
                            <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs justify-content-left"> 
                                <li [ngbNavItem]="1">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-monitor mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Equipments' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">   
                                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">                                  
                                              <ng-template #contentEquipment let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'AddNewEquipment' | translate}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="equipment.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" 
                                                              id="equipmentCreateTitle" #equipmentCreateTitle="ngModel" 
                                                              name="equipmentTitle" class="form-control"  required>
                                                          <div [hidden]="equipmentCreateTitle.valid || equipmentCreateTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>  
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="equipment.description" rows="5" 
                                                              class="form-control" name="equipmentDescription"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="equipment.comment" rows="5" 
                                                              class="form-control" name="equipmentComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, equipment)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" 
                                                          [disabled]="equipmentCreateTitle.invalid"
                                                          (click)="addEquipment(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
      
                                              <ng-template #contentEquipmentEdit let-c="close" let-d="dismiss" let-modal>
                                                  <div class="modal-header">
                                                    <h4 class="modal-title">{{'Update' | translate}} #{{equipmentEdit.id}}</h4>
                                                    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                  </div>
                                                  <div class="modal-body">
                                                    <form class="form">
                                                      <div class="form-group">
                                                          <label>{{'Title' | translate}} *</label>
                                                          <input  [(ngModel)]="equipmentEdit.title"   
                                                              placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}"
                                                              id="equipmentEditTitle" #equipmentEditTitle="ngModel"  
                                                              name="equipmentTitle" class="form-control"  required>
                                                          <div [hidden]="equipmentEditTitle.valid || equipmentEditTitle.untouched" class="text-danger">
                                                              {{'FieldRequired' | translate}}
                                                          </div>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Description' | translate}} </label>
                                                          <textarea [(ngModel)]="equipmentEdit.description" rows="5" 
                                                              class="form-control" name="equipmentDescription"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group">
                                                          <label>{{'Comment' | translate}} </label>
                                                          <textarea [(ngModel)]="equipmentEdit.comment" rows="5" 
                                                              class="form-control" name="equipmentComment"
                                                              maxlength="200"></textarea>
                                                      </div>
                                                      <div class="form-group" *ngIf="equipmentEdit.fileNames?.length > 0">
                                                          <label>{{'UploadedFiles' | translate}}:</label>
                                                          <ul>
                                                              <li *ngFor="let fileName of equipmentEdit.fileNames">
                                                                  <span class="fileName cursor-pointer" 
                                                                          (click)="openFilePreview('equipments', fileName, equipmentEdit.id)">
                                                                      {{ fileName }}
                                                                  </span>
                                                                  <a class="danger p-0" data-original-title="" title="download" 
                                                                      (click)="downloadFile('equipments', fileName, equipmentEdit.id)">
                                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                  </a>
                                                                  <a class="danger p-0" data-original-title="" title="delete" 
                                                                      (click)="removeFile('equipments', fileName, equipmentEdit.id)">
                                                                      <i class="ft-trash cursor-pointer mr-2"></i>
                                                                  </a>
                                                              </li>
                                                          </ul>
                                                      </div>
                                                      <div class="form-group">
                                                          <input id="files" type="file" (change)="handleFileSelection($event, equipmentEdit)" multiple />
                                                      </div>
                                                    </form>
                                                  </div>
                                                  <div class="modal-footer">                                         
                                                    <button type="submit" 
                                                      [disabled]="equipmentEditTitle.invalid"
                                                      class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateEquipment(modal)">{{'Save' | translate}}</button>
                                                    <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                  </div>
                                              </ng-template>
                                              <button type="button" (click)="open(contentEquipment)"
                                                  class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                  {{'Add' | translate}}
                                              </button>
                                          </div>
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="equipments" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Tile' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openEquipmentEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('equipments', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                      </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="2">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-file-text mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Documents' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="agreements" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Tile' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openAgreementEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'StartDate' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.startDate ? (row.startDate | date:'dd/MM/yy') : '-'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'EndDate' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.endDate ? (row.endDate | date:'dd/MM/yy') : '-'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Type' | translate}}" prop="type"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('agreements', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="3">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-droplet mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Meters' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="meters" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openMeterEditDialog(row.id)">
                                                      {{row.type}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Category' | translate}}" prop="category"  ></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('meters', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>    
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="4">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-file mr-1"></i>
                                    <span class="d-none d-sm-block"> {{'Contracts' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                          <ngx-datatable class="bootstrap core-bootstrap" 
                                              [rows]="contracts" 
                                              [columnMode]="'force'" 
                                              [headerHeight]="50" 
                                              [footerHeight]="50"
                                              [rowHeight]="'auto'"
                                              [limit]="15">
                                              <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                          <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                              {{row.status}}
                                                          </span>
                                                      </div>                      
                                                      <ng-template #nonActiveBadge>
                                                          <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                              {{row.status}}
                                                          </span>                       
                                                      </ng-template>
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'StartDate' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      <a class="primary p-0" data-original-title="" (click)="openContractEditDialog(row.id)">
                                                          {{row.startDate | date:'dd/MM/yy'}}
                                                      </a>
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'EndDate' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      {{row.endDate | date:'dd/MM/yy'}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="{{'RentPrice' | translate}}" prop="rentPrice"  ></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'HGMTFee' | translate}}" prop="hgmtFee"></ngx-datatable-column>
                                              <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                                  <ng-template let-row="row" ngx-datatable-cell-template>
                                                      {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                                  </ng-template>
                                              </ngx-datatable-column>
                                              <ngx-datatable-column name="Actions">
                                                  <ng-template let-row="row"  ngx-datatable-cell-template>
                                                    <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('rental-contracts', row.id)">
                                                      <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                    </a>
                                                  </ng-template>
                                              </ngx-datatable-column> 
                                          </ngx-datatable>  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="7">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-dollar-sign mr-1"></i>
                                    <span class="d-none d-sm-block">{{'Invoices' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <div class="row">
                                          <div class="col-3 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                              <h2 class="card-title text-right">
                                                  {{'Balance' | translate}}: {{balance}} PLN
                                              </h2>
                                          </div>   
                                      </div> 
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="invoices" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                          <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                      {{row.status}}
                                                  </span>
                                                  </div>                      
                                                  <ng-template #nonActiveBadge>
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                      {{row.status}}
                                                  </span>                       
                                                  </ng-template>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Title' | translate}}" prop="title">
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  <a class="primary p-0" data-original-title="" (click)="openInvoiceEditDialog(row.id)">
                                                      {{row.title}}
                                                  </a>
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Description' | translate}}" prop="description"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                          <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                              <ng-template let-row="row" ngx-datatable-cell-template>
                                                  {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                              </ng-template>
                                          </ngx-datatable-column>
                                          <ngx-datatable-column name="Actions">
                                              <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('invoices', row.id)">
                                                  <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                              </ng-template>
                                          </ngx-datatable-column> 
                                      </ngx-datatable>
                                  
                                  </ng-template>
                                </li>
      
                                <li [ngbNavItem]="8">
                                  <a ngbNavLink class="nav-link d-flex align-items-center">
                                    <i class="ft-briefcase mr-1"></i>
                                    <span class="d-none d-sm-block">{{'CostsAareement' | translate}}</span>
                                  </a>
                                  <ng-template ngbNavContent>
                                      <ngx-datatable class="bootstrap core-bootstrap" 
                                          [rows]="expenses" 
                                          [columnMode]="'force'" 
                                          [headerHeight]="50" 
                                          [footerHeight]="50"
                                          [rowHeight]="'auto'"
                                          [limit]="15">
                                      <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                      {{row.status}}
                                                  </span>
                                              </div>                      
                                              <ng-template #nonActiveBadge>
                                                  <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                      {{row.status}}
                                                  </span>                       
                                              </ng-template>
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              <a class="primary p-0" data-original-title="" (click)="openExpensesEditDialog(row.id)">
                                                  {{row.type}}
                                              </a>
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'AccountNumber' | translate}}" prop="accountNumber"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                      <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                          <ng-template let-row="row" ngx-datatable-cell-template>
                                              {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                          </ng-template>
                                      </ngx-datatable-column>
                                      <ngx-datatable-column name="Actions">
                                          <ng-template let-row="row"  ngx-datatable-cell-template>
                                            <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('costs-of-agreements', row.id)">
                                              <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                            </a>
                                          </ng-template>
                                      </ngx-datatable-column> 
                                      </ngx-datatable> 
                                  </ng-template>
                           
                                </li>                        
                            </ul>
                            <div class="tab-content">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>

                        </div><!----./landlord-->

                        <div *ngIf="viewType === 'tenant'">
                            <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs justify-content-left"> 
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink class="nav-link d-flex align-items-center">
                                        <i class="ft-droplet mr-1"></i>
                                        <span class="d-none d-sm-block">{{'Meters' | translate}}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <div class="row">
                                            <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                                <ng-template #content let-c="close" let-d="dismiss" let-modal>
                                                    <div class="modal-header">
                                                        <h4 class="modal-title">{{'AddNewMeter' | translate}}</h4>
                                                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <form class="form">
                                                        <div class="form-group">
                                                            <label>{{'Type' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meter.type" 
                                                                name="meterType" #type="ngModel" required>
                                                                <option *ngFor="let type of meterTypes" value="{{type}}">
                                                                    {{type}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Category' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meter.category" 
                                                                name="meterCategory" #category="ngModel" required>
                                                                <option *ngFor="let category of meterCategories" value="{{category}}">
                                                                    {{category}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Value' | translate}} *</label>
                                                            <input  [(ngModel)]="meter.value"   
                                                                placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}" 
                                                                id="meterCreateValue" #meterCreateValue="ngModel" 
                                                                name="meterValue" class="form-control" 
                                                                type="number" required>
                                                            <div [hidden]="meterCreateValue.valid || meterCreateValue.untouched" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div> 
                                                        </div>
                                                        <div class="form-group">
                                                            <input id="files" type="file" (change)="handleFileSelection($event, meter)" multiple />
                                                        </div>
                                                        </form>
                                                    </div>
                                                    <div class="modal-footer">                                         
                                                        <button type="submit" 
                                                        [disabled]="meterCreateValue.invalid"
                                                        class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="addMeter(modal)">{{'Save' | translate}}</button>
                                                        <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                    </div>
                                                </ng-template>
                                                <button type="button" (click)="open(content)"
                                                    class="btn btn-outline-primary mb-2 mb-sm-0 mr-sm-2" > 
                                                    {{'Add' | translate}}
                                                </button>
        
                                                <ng-template #contentMeterEdit let-c="close" let-d="dismiss" let-modal>
                                                    <div class="modal-header">
                                                        <h4 class="modal-title">{{'Edit' | translate}} #{{meterEdit.id}}</h4>
                                                        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                                                        <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <form class="form">
                                                        <div class="form-group">
                                                            <label>{{'Type' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meterEdit.type" 
                                                                name="meterType" #type="ngModel" required>
                                                                <option *ngFor="let type of meterTypes" value="{{type}}">
                                                                    {{type}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Category' | translate}} *</label>
                                                            <select class="form-control" [(ngModel)]="meterEdit.category" 
                                                                name="meterCategory" #category="ngModel" required>
                                                                <option *ngFor="let category of meterCategories" value="{{category}}">
                                                                    {{category}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>{{'Value' | translate}} *</label>
                                                            <input  [(ngModel)]="meterEdit.value"   
                                                                placeholder="{{'EnterThe' | translate}}{{'Value' | translate}}" 
                                                                id="meterUpdateValue" #meterUpdateValue="ngModel"
                                                                name="meterValue" class="form-control" 
                                                                type="number" required>
                                                            <div [hidden]="meterUpdateValue.valid || meterUpdateValue.untouched" class="text-danger">
                                                                {{'FieldRequired' | translate}}
                                                            </div> 
                                                        </div>
                                                        <div class="form-group" *ngIf="meterEdit.fileNames?.length > 0">
                                                            <label>{{'UploadedFiles' | translate}}:</label>
                                                            <ul>
                                                                <li *ngFor="let fileName of meterEdit.fileNames">
                                                                    <span class="fileName cursor-pointer" 
                                                                            (click)="openFilePreview('meters', fileName, meterEdit.id)">
                                                                        {{ fileName }}
                                                                    </span>
                                                                    <a class="danger p-0" data-original-title="" title="download" 
                                                                        (click)="downloadFile('meters', fileName, meterEdit.id)">
                                                                        <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                                    </a>
                                                                    <a class="danger p-0" data-original-title="" title="delete" 
                                                                        (click)="removeFile('meters', fileName, meterEdit.id)">
                                                                        <i class="ft-trash cursor-pointer mr-2"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="form-group">
                                                            <input id="files" type="file" (change)="handleFileSelection($event, meterEdit)" multiple />
                                                        </div>
                                                        </form>
                                                    </div>
                                                    <div class="modal-footer">                                         
                                                        <button type="submit" 
                                                        [disabled]="meterUpdateValue.invalid"    
                                                        class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="updateMeter(modal)">{{'Save' | translate}}</button>
                                                        <button type="button" class="btn btn-secondary" (click)="c('Close click')">{{'Cancel' | translate}}</button>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <ngx-datatable class="bootstrap core-bootstrap" 
                                            [rows]="meters" 
                                            [columnMode]="'force'" 
                                            [headerHeight]="50" 
                                            [footerHeight]="50"
                                            [rowHeight]="'auto'"
                                            [limit]="15">
                                            <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <a class="primary p-0" data-original-title="" (click)="openMeterEditDialog(row.id)">
                                                        {{row.type}}
                                                    </a>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Category' | translate}}" prop="category"  ></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Actions">
                                                <ng-template let-row="row"  ngx-datatable-cell-template>
                                                    <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('meters', row.id)">
                                                        <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                    </a>
                                                </ng-template>
                                            </ngx-datatable-column> 
                                        </ngx-datatable>    
                                    </ng-template>
                                </li>
    
                                <li [ngbNavItem]="2">
                                    <a ngbNavLink class="nav-link d-flex align-items-center">
                                        <i class="ft-file mr-1"></i>
                                        <span class="d-none d-sm-block"> {{'Contracts' | translate}}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ngx-datatable class="bootstrap core-bootstrap" 
                                            [rows]="contracts" 
                                            [columnMode]="'force'" 
                                            [headerHeight]="50" 
                                            [footerHeight]="50"
                                            [rowHeight]="'auto'"
                                            [limit]="15">
                                            <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                        <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                            {{row.status}}
                                                        </span>
                                                    </div>                      
                                                    <ng-template #nonActiveBadge>
                                                        <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                            {{row.status}}
                                                        </span>                       
                                                    </ng-template>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'StartDate' | translate}}" >
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    <a class="primary p-0" data-original-title="" (click)="openContractEditDialog(row.id)">
                                                        {{row.startDate | date:'dd/MM/yy'}}
                                                    </a>
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'EndDate' | translate}}" >
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{row.endDate | date:'dd/MM/yy'}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="{{'RentPrice' | translate}}" prop="rentPrice"  ></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'HGMTFee' | translate}}" prop="hgmtFee"></ngx-datatable-column>
                                            <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                                <ng-template let-row="row" ngx-datatable-cell-template>
                                                    {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                                </ng-template>
                                            </ngx-datatable-column>
                                            <ngx-datatable-column name="Actions">
                                                <ng-template let-row="row"  ngx-datatable-cell-template>
                                                    <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('rental-contracts', row.id)">
                                                     <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                    </a>
                                                </ng-template>
                                            </ngx-datatable-column> 
                                        </ngx-datatable>  
                                    </ng-template>
                                </li>

                                <li [ngbNavItem]="3">
                                    <a ngbNavLink class="nav-link d-flex align-items-center">
                                        <i class="ft-briefcase mr-1"></i>
                                        <span class="d-none d-sm-block">{{'CostsAareement' | translate}}</span>
                                    </a>
                                    <ng-template ngbNavContent>
                                        <ngx-datatable class="bootstrap core-bootstrap" 
                                            [rows]="actualExpenses" 
                                            [columnMode]="'force'" 
                                            [headerHeight]="50" 
                                            [footerHeight]="50"
                                            [rowHeight]="'auto'"
                                            [limit]="15">
                                        <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Status' | translate}}" prop="status">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <div *ngIf="row.status == 'Active'; else nonActiveBadge">
                                                    <span _ngcontent-ykd-c258="" class="badge bg-light-success mb-1">
                                                        {{row.status}}
                                                    </span>
                                                </div>                      
                                                <ng-template #nonActiveBadge>
                                                    <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                                        {{row.status}}
                                                    </span>                       
                                                </ng-template>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Type' | translate}}" prop="type">
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                <a class="primary p-0" data-original-title="" (click)="openExpensesEditDialog(row.id)">
                                                    {{row.type}}
                                                </a>
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Value' | translate}}" prop="value"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'AccountNumber' | translate}}" prop="accountNumber"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>
                                        <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                            <ng-template let-row="row" ngx-datatable-cell-template>
                                                {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                            </ng-template>
                                        </ngx-datatable-column>
                                        <ngx-datatable-column name="Actions">
                                            <ng-template let-row="row"  ngx-datatable-cell-template>
                                                <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('costs-of-agreements', row.id)">
                                                    <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                                </a>
                                            </ng-template>
                                        </ngx-datatable-column> 
                                        </ngx-datatable> 
                                    </ng-template>
                                </li>                        
                            </ul>
                            <div class="tab-content">
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div><!----./tenant-->
                    </div><!----./card-body-->

                </div>
            </div>
        </div>
    </div>
</section>