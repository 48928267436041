import { MeterCategory } from "../enums/meterCategory";
import { MeterType } from "../enums/meterType";

export class MeterCreate {
    constructor(
        public files: File[] = [],
        public type?: MeterType,
        public category?: MeterCategory,
        public value?: number,
        public flatId?: number
    ) {}
}