import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StaffAdapter } from "app/models/staff/staff";
import { StaffCreate } from "app/models/staff/staffCreate";
import { StaffIndexAdapter } from "app/models/staff/staffIndex";
import { StaffSortState } from "app/models/staff/staffSortState";
import { StaffUpdate } from "app/models/staff/staffUpdate";
import { environment } from "environments/environment";
import { map } from "rxjs/operators";

@Injectable()
export class StaffService {
    private url = environment.baseApiurl + "/adminstaff";

    constructor(private http: HttpClient,
                private adapter: StaffAdapter,
                private adapterIndex: StaffIndexAdapter) {                
    }

    getPage(page: number, size: number = 1, sort: StaffSortState = StaffSortState.IdDesc) {
        return this.http.get(this.url + '/getpage?page=' + page + '&order=' + sort + '&size=' + size)
                    .pipe(map((data: any) =>  this.adapterIndex.adapt(data)));
    }

    getById(id: number) {
        return this.http.get(this.url + '/GetById?id=' + id)
      .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }

    create(newStaff: StaffCreate) {
        return this.http.post(this.url + '/create', newStaff);
    }

    update(updatedStaff: StaffUpdate) {
        return this.http.put(this.url + '/update', updatedStaff);
    }

    block(staffId: number) {
        var requestUrl = this.url + '/Block?staffId=' + staffId;
        return this.http.put(requestUrl, null);
    }

    unblock(staffId: number){
        var requestUrl = this.url + '/Unblock?staffId=' + staffId;
        return this.http.put(requestUrl, null);
    }
}