import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';

import { TaxiFareDynamicCreate } from '../models/taxifare/taxifareDynamicCreate';
import { TaxifareBase } from '../models/taxifare/taxifarebase';
import { TaxiFareService } from '../taxifare/taxifare.service';
import { TaxiFareType } from '../models/enums/taxifaretype';
import { TaxifareIcon } from '../models/taxifare/taxifareIcon';
import { CompanyService } from '../settings/company.service';
import { Company } from 'app/models/settings/company';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './add-dynamictaxifare.component.html',
    styleUrls: ['./taxifare.component.css'],
    providers: [TaxiFareService, CompanyService]
})
export class AddDynamicTaxifareComponent implements OnInit {

    tax: TaxiFareDynamicCreate = new TaxiFareDynamicCreate();
    base: TaxifareBase = new TaxifareBase();
    icons: TaxifareIcon[] = [];
    places: number[] = [];

    selectedIcon: any;

    constructor(private taxifareService: TaxiFareService,
                private companyService: CompanyService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.taxifareService.getIcons().subscribe((data: TaxifareIcon[]) => {
            this.icons = data;
            this.selectedIcon = this.icons[0];
        });
        this.SetArraySeats();
    }

    createDynamicTaxiFare(): void {
        this.tax.baseInfo = this.base;
        this.tax.baseInfo.id = 0;
        this.tax.baseInfo.type = TaxiFareType.Dynamic;
        this.taxifareService.createDynamicTaxiFare(this.tax).subscribe(data => {
            this.router.navigate(['/taxifares/alltaxifares']);
        })
    }

    onIconChange() {
        this.selectedIcon = this.icons.find(x => x.name === this.base.numberIcon.toString());
    }

    private SetArraySeats() {
        this.companyService.getCompany().subscribe((data: Company) => {
            if (data != null) {
                for (var i = data.minCountSeats; i <= data.maxCountSeats; i++) {
                    this.places.push(i);
                }
            }
        });
    }

    // Confirm Button Action
    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/taxifares/alltaxifares']);
            }
        })
    }

}