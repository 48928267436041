import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';

import { DriverService } from './driver.service';
import { DriverCreate } from '../models/driver/driverCreate';
import { TranslateService } from '@ngx-translate/core';
import { Sex } from 'app/models/enums/sex';

@Component({
    selector: 'app-root',
    templateUrl: './add-driver.component.html',
    providers: [DriverService]
})
export class AddDriveComponent implements OnInit {

    driver: DriverCreate = new DriverCreate();
    listSex: string[];

    constructor(private driverService: DriverService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
        this.listSex = Object.keys(Sex);
    }

    createDriver(): void {
        console.log(this.driver);
        this.driverService.createDriver(this.driver).subscribe(data => {
            this.router.navigate(['/drivers/alldrivers']);
        })
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/drivers/alldrivers']);
            }
        })
    }


}
