import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";

export class Flat {
    constructor(
        public id: number,
        public title?: string,
        public description?: string,
        public city?: string,
        public street?: string,
        public building?: string,
        public apartment?: string,
        public postcode?: string,
        public internalId?: number,
        public accountNumber?: string,
        public accountNumber2?: string,
        public accountNumber3?: string,
        public balance?: number,
        public price?: number,
        public size?: number,
        public countRooms?: number,
        public deposit?: number,
        public adminFees?: number,
        public floor?: number,
        public hasBalcony?: boolean,
        public hasTerrace?: boolean,
        public countParkingSpots?: number,
        public petAllowed?: boolean,
        public yearBuilt?: number,
        public createTime?: Date,
        public updateTime?: Date,
        public landlordId?: number
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class FlatAdapter implements Adapter<Flat> {
    adapt(item: any): Flat {
        var converterYear: Date = new Date(item.yearBuilt * 1000);
        return new Flat(
            item.id,
            item.title,
            item.description,
            item.city,
            item.street,
            item.building,
            item.apartment,
            item.postcode,
            item.internalId,
            item.accountNumber,
            item.accountNumber2,
            item.accountNumber3,
            item.balance,
            item.price,
            item.size,
            item.countRooms,
            item.deposit,
            item.adminFees,
            item.floor,
            item.hasBalcony,
            item.hasTerrace,
            item.countParkingSpots,
            item.petAllowed,
            converterYear.getFullYear(),
            new Date(item.createTime * 1000),
            new Date(item.updateTime * 1000),
            item.landlordId
        )
    }
    
}