import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";
import { InspectionAdapter } from "app/models/inspection/inspection";
import { InspectionCreate } from "app/models/inspection/inspectionCreate";

@Injectable()
export class InspectionService {
    private url = environment.baseApiurl + "/inspection";

    constructor(private http: HttpClient, 
                private adapter: InspectionAdapter) {
    }

    getAll(flatId: number) {
        return this.http.get(this.url + '/GetAllByFlatId?flatId=' + flatId)
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    
    create(newItem: InspectionCreate) {
        const formData: FormData = new FormData();     
        for (const key in newItem) {
            if (newItem.hasOwnProperty(key)) {
                formData.append(key, (newItem as any)[key]);
            }
        }

        newItem.files.forEach(file => {
          formData.append('files', file, file.name);
        });

        return this.http.post(this.url + '/create', formData);
    }

    delete(id: number) {
        return this.http.delete(this.url + '/delete?id=' + id);
    }
}