import { Injectable} from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor,HttpRequest,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import { Observable, of, throwError} from "rxjs";
import { catchError, map} from 'rxjs/operators';
import { Router } from '@angular/router';
import { ErrorModel } from "./ErrorModel";
import { NGXToastrService } from "../components/extra/toastr/toastr.service";

import * as alertFunctions from '../shared/data/sweet-alerts';
import swal from 'sweetalert2';
import { AuthService } from "app/shared/auth/auth.service";

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
  
  constructor(public router: Router, 
              private toast: NGXToastrService, 
              private authService: AuthService) {

  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.CurrentUserValue;
    if (token != null && token.accessToken != null) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token.accessToken) });
    }

    return next.handle(req).pipe(
      catchError((error) => {
        let handled: boolean = false;
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
          } else {
            console.log(`error status : ${error.status} ${error.statusText}`);
            switch (error.status) {
              case 401:      //login
                this.router.navigateByUrl("pages/login");
                handled = true;
                break;
              case 403:     //forbidden
                //OLD
                //this.router.navigateByUrl("pages/login");
                //handled = true;
                this.toast.errForbidden();
                break;
              case 400:
                this.getErrorCode(error); 
                break;
              case 404:     //page not found
              case 408:     //request time out
              case 415:     //Unsupported Media Type
                this.router.navigateByUrl("/pages/error");
                handled = true;
                break;
              case 500:
              case 501:
              case 502:
              case 503:
              case 504:
              case 505:
              case 506:
              case 507:
              case 508:
              case 509:
              case 510:
              case 511:
              case 520:
              case 521:
              case 523:
              case 524:
              case 525:
              case 526:
                this.router.navigateByUrl("/pages/servererror");
                handled = true;
                break;
            }
          }
        }
        else {
          this.toast.typeSuccess();
        }
 
        if (handled) {
          //console.log('return back ');
          return of(error);
        } else {
          //console.log('throw error back to to the subscriber');
          return throwError(error);
        }
 
      })
    )
  }

  getErrorCode(errorObject: HttpErrorResponse) {
    let errorModel: ErrorModel = errorObject.error;
    console.log(errorObject);
    switch(errorModel.code) {
      case 101:
        this.toast.err101Unauthorized();
        break;
      case 102:
        this.toast.err102WrongData();
        break;
      case 103:
        this.toast.err103EmailOccupaid();
        break;
      case 104:
        this.toast.err104Validation();
        break;
      case 106:
        this.toast.err106UserNotFound();
        break;
      case 107:
        this.toast.err107InvalidToken();
        break;
      case 108:
        this.toast.err108UserBlocked();
        break;
      case 109:
        this.toast.err109ProfileNotVerifyed();
        break;
      case 110:
        this.toast.err110RateNotFound();
        break;
      case 111:
        this.toast.err111RateNotSupportPaymentType();
        break;
      case 114:
        this.toast.err114PhoneBusy();
        break;
      case 115:
        this.toast.err115OrderHasFeedback();
        break;
      case 116:
        this.toast.err116PromoNotFound();
        break;
      case 117:
        this.toast.err117PromoExpired();
        break;
      case 118:
        this.toast.err118ClientHasCode();
        break;
      case 201:
        this.toast.err201InvalidData();
        break;
      case 202:
        this.toast.err202ItemNotFound();
        break;
      case 203:
        this.toast.err203ItemBlocked();
        break;
      case 301:
        this.toast.err301CheckOrderStatus();
        break;
      case 302:
        this.toast.err302CheckOrderStatus();
        break;
      case 303:
        this.toast.err303CardNotFound();
        break;
      case 304:
        this.toast.err304DebtCard();
        break;
      case 401:
        this.toast.err401DriverNotFound();
        break;
      case 402:
        this.toast.err402DriverBlocked();
        break;
      case 403:
        this.toast.err403DriverNotVerified();
        break;
      case 406:
        this.toast.err406CarHasOrders();
        break;
      case 407:
        this.toast.err407CarAlreadyUse();
        break;
      case 408:
        this.toast.err408StatusPrivateCar();
        break;
      case 409:
        this.toast.err409DriverHasOrders();
        break;
      case 410:
        this.toast.err410DriverHasCar();
        break;
      case 411:
        this.toast.err411DriverPhoneBusy();
        break;
      case 412:
        this.toast.err412DriverInline();
        break;
      case 413:
        this.toast.err413MissingCar();
        break;
      case 414:
        this.toast.err414MissingRate();
        break;
      case 415:
        this.toast.err415DriverActiveOrder();
        break;
      case 416:
        this.toast.err416PromoAlreadyHas();
        break;
      case 417:
        this.toast.err417DriverTypeCar();
        break;
      case 418:
        this.toast.err418MissingTrustedContact();
        break;
      case 701:
        this.toast.err701FileNotFound();
        break;
    }
  }

}