import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

import { PromocodeAutoGenerate } from '../models/promocode/promocodeAutoGenerate';
import { PromocodeService } from './promocode.service';
import { PromoCodeType } from 'app/models/enums/promocodetype';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './autogen-promocode.component.html',
    providers: [PromocodeService]
})
export class AutoGenPromocodesComponent {

    codes: PromocodeAutoGenerate = new PromocodeAutoGenerate();

    constructor(private router: Router,
                private promoService: PromocodeService,
                private translate: TranslateService) {

    }

    autoGeneration(): void {
        //warning! in the future, you need fix work with time in the models
        this.codes.expirateAt = (new Date(this.codes.expirateAt)).valueOf() / 1000;
        this.codes.startAt = (new Date(this.codes.startAt)).valueOf() / 1000;
        
        this.promoService.autoGeneratePromocodes(this.codes)
            .subscribe(data => {
                this.router.navigate(['/promocodes/allpromocodes']);
            });
    }

    // Confirm Button Action
    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/promocodes/allpromocodes']);
            }
        })
    }

}