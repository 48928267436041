import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";

import { Feedback, FeedbackAdapter } from '../models/feedback/feedback';
import { environment } from 'environments/environment';

@Injectable()
export class FeedbackService {
    private url = environment.baseApiurl + "/adminfeedback";

    constructor(private http: HttpClient,
                private adapter: FeedbackAdapter) {
    }

    getAll() {
        return this.http.get(this.url + '/GetAll')
                .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getDriverFeedbacks(id: number) {
        return this.http.get(this.url + '/GetDriverFeedbacks?id=' + id)
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getClientFeedbacks(id: number) {
        return this.http.get(this.url + '/GetClientFeedbacks?id=' + id)
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }


}