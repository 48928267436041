import { Component, OnInit } from '@angular/core';

import { Order } from '../models/order/order';
import { OrderService } from '../order/order.service';
import { OrderSortState } from 'app/models/order/orderSortState';
import { OrderIndex } from 'app/models/order/orderIndex';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
  providers: [OrderService]
})
export class FeedbackComponent implements OnInit {

  //orders: Order[];
  item: OrderIndex;

  page: number;
  size: number;
  sort: OrderSortState;

  private routeSubscription: Subscription;
  private querySubscription: Subscription;

  constructor(private orderService: OrderService,
              private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.querySubscription = this.route.queryParams.subscribe(
      (queryParam: any) => {
          this.page = queryParam['page'];
          this.size = queryParam['size'];
          this.sort = queryParam['sort'];
      }
    );
    this.size = 20;
    //this.loadOrders();
    this.loadPartOrders(this.page, this.size, this.sort);
  }

  /*old method. delete after check (without pagination)
  private loadOrders(): void {
    this.orderService.getOrders().subscribe((data: Order[]) => {
      this.orders = data;
    })
  }
  */

  private loadPartOrders(page: number = 1, size: number, sort: OrderSortState = OrderSortState.IdDesc): void {
    this.orderService.getOrdersWithFeedbackPage(page, size, sort).subscribe((data: OrderIndex) => {
      this.item = data;
      console.log(data);
    })
  }

}
