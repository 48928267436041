import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from "rxjs/operators";

import { Order, OrderDetails, OrderAdapter} from '../models/order/order';
import { OrderIndexAdapter } from 'app/models/order/orderIndex';
import { OrderSortState } from 'app/models/order/orderSortState';

import { environment } from 'environments/environment';
import { PreOrderAdapter } from 'app/models/order/preorder';

@Injectable()
export class OrderService {
    private url = environment.baseApiurl + "/adminorder";

    constructor(private http: HttpClient,
                private adapter: OrderAdapter,
                private adapterIndex: OrderIndexAdapter,
                private adapterPreOrder: PreOrderAdapter) {
    }

    getOrders() {
        return this.http.get(this.url + '/getAll')
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getPage(page: number, size: number = 20, sort: OrderSortState = OrderSortState.IdDesc) {
        return this.http.get(this.url + '/getpage?page=' + page + '&order=' + sort + '&size=' + size)
            .pipe(map((data: any) =>  this.adapterIndex.adapt(data)));
    }

    getCompletedOrders() {
        return this.http.get(this.url + '/getCompletedPaidOrders')
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getCompletedOrdersPage(page: number, size: number = 1, sort: OrderSortState = OrderSortState.IdDesc) {
        return this.http.get(this.url + '/GetCompletedPaidOrdersPage?page=' + page + '&order=' + sort + '&size=' + size)
            .pipe(map((data: any) =>  this.adapterIndex.adapt(data)));
    }

    getOrdersWithFeedbackPage(page: number, size: number = 1, sort: OrderSortState = OrderSortState.IdDesc) {
        return this.http.get(this.url + '/GetOrdersWithFeedbackPage?page=' + page + '&order=' + sort + '&size=' + size)
            .pipe(map((data: any) =>  this.adapterIndex.adapt(data)));
    }

    getOrder(orderId: number) {
        return this.http.get(this.url + '/getbyid?orderid=' + orderId)
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }

    getDriverOrders(driverId: number) {
        return this.http.get(this.url + '/getDriverOrders?driverId=' + driverId)
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getXLSXwithOrders() {
        return this.http.get(this.url + '/exportOrdersToXLSX');
    }

    getXLSXwithReports() {
        return this.http.get(this.url + '/exportReportsToXLSX');
    }

    getCost(newOrder: any) {
        //return this.http.post(this.url + '/getCost', newOrder);
        return this.http.post(environment.baseApiurl + '/clientOrder/GetGuestCosts', newOrder);
            //.pipe(map((data: any[]) => data.map(item => item.adapterPreOrder.adapt(item))));
    }

    createOrderManually(newOrder: any) {
        return this.http.post(this.url + '/CreateOrder', newOrder);
    }

}