<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'DocumentManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'DocumentManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title" id="horz-layout-colored-controls">{{'AllDocumentsTitle' | translate}}</h4>
                    <p class="mb-0">{{'AllDocumentsDescription' | translate}}</p>
                    <div class="row">
                        <div class="col-md-11">
                            <fieldset class="form-group">
                                <label>{{'Search' | translate}}</label>
                                <input id="ngx-filter-ref" 
                                    class="form-control form-control-sm width-200" 
                                    type="text"
                                    placeholder="{{'FilterTheName' | translate}}" 
                                    (keyup)="updateFilter($event)" />
                            </fieldset> 
                        </div>
                        <div class="col-md-1">
                            <a class="btn btn-outline-primary" href="/documents/adddocument">{{'Add' | translate}}</a>
                        </div>
                    </div>                
                </div>
                <div class="card-content">                  
                    <div class="col-md-12">
                        <ngx-datatable class="bootstrap core-bootstrap" 
                            [rows]="items" 
                            [columnMode]="'force'" 
                            [headerHeight]="50" 
                            [footerHeight]="50"
                            [rowHeight]="'auto'"
                            [limit]="15">
                            <ngx-datatable-column name="#" prop="id"></ngx-datatable-column>
                            <ngx-datatable-column name="{{'Title' | translate}}" prop="title"></ngx-datatable-column>
                            <ngx-datatable-column name="{{'Description' | translate}}" prop="description"  ></ngx-datatable-column>
                            <ngx-datatable-column name="{{'Comment' | translate}}" prop="comment"></ngx-datatable-column>   
                            <ngx-datatable-column name="{{'CreateTime' | translate}}" >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row.createTime | date:'dd/MM/yy h:mm a'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Actions">
                                <ng-template let-row="row"  ngx-datatable-cell-template>
                                  <a class="danger p-0" data-original-title="" title="download" (click)="downloadArchive('documents', row.id)">
                                    <i class="ft-download text-primary cursor-pointer mr-2"></i>
                                  </a>
                                  <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDelete(row.id)">
                                    <i class="ft-trash cursor-pointer mr-2"></i>
                                  </a>
                                </ng-template>
                            </ngx-datatable-column> 
                        </ngx-datatable>    
                    </div>                                                 
                </div>
            </div>
        </div>
    </div>
</section>