import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";
import { AgreementType } from "../enums/agreementType";

export class AgreementUpdate {
    constructor(
        public files: File[] = [],
        public id: number,
        public title?: string,
        public description?: string,
        public type?: AgreementType,
        public startDate?: number,
        public endDate?: number
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class AgreementUpdateAdapter implements Adapter<AgreementUpdate> {
    adapt(item: any): AgreementUpdate {
       return new AgreementUpdate(
        item.files,
        item.id,
        item.title,
        item.description,
        item.type,
        item.startDate,
        item.endDate
       )
    }

}