import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";
import { FlatAdapter } from "app/models/flat/flat";
import { FlatCreate } from "app/models/flat/flatCreate";
import { FlatUpdate } from "app/models/flat/flatUpdate";
import { FlatContractAdapter } from "app/models/flat/flatContract";

@Injectable()
export class FlatService {
    private url = environment.baseApiurl + "/flat";

    constructor(private http: HttpClient, 
                private adapter: FlatAdapter,
                private adapterContract: FlatContractAdapter) {
    }

    getAll() {
        return this.http.get(this.url + '/getAll')
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getAllWithActualContract() {
        return this.http.get(this.url + '/GetAllWithActualContract')
            //.pipe(map((data: any[]) => data.map(item => item)));
            .pipe(map((data: any[]) => data.map(item => this.adapterContract.adapt(item))));
    }

    getAllWithActualContractByLandlordId(id: number) {
        console.log('getAllWithActualContractByLandlordId');
        return this.http.get(this.url + '/GetAllWithActualContractByLandlordId?landlordId=' + id)
            //.pipe(map((data: any[]) => data.map(item => item)));
            .pipe(map((data: any[]) => data.map(item => this.adapterContract.adapt(item))));
    }

    getAllByLandlordId(id: number) {
        return this.http.get(this.url + '/getAllByLandlordId?landlordId=' + id)
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getAllByTenantId(id: number) {
        return this.http.get(this.url + '/getAllByTenantId?tenantId=' + id)
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getAllByEmployeeId(id: number) {
        return this.http.get(this.url + '/GetAllEmployeeId?employeeId=' + id)
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getById(id: number) {
        return this.http.get(this.url + '/getById?id=' + id)
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }

    create(newItem: FlatCreate) {
        return this.http.post(this.url + '/create', newItem);
    }

    update(updatedItem: FlatUpdate) {
        return this.http.put(this.url + '/update', updatedItem);
    }

    delete(id: number) {
        return this.http.delete(this.url + '/delete?id=' + id);
    }
}