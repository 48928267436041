import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from 'environments/environment';
import { TenantAdapter } from "app/models/tenant/tenant";
import { TenantCreate } from "app/models/tenant/tenantCreate";
import { TenantUpdate } from "app/models/tenant/tenantUpdate";

@Injectable()
export class TenantService {
    private url = environment.baseApiurl + "/tenant";

    constructor(private http: HttpClient, 
                private adapter: TenantAdapter) {
    }

    getAll() {
        return this.http.get(this.url + '/getAll')
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getById(id: number) {
        return this.http.get(this.url + '/getById?id=' + id)
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }

    create(newTenant: TenantCreate) {
        return this.http.post(this.url + '/create', newTenant);
    }

    update(updatedTenant: TenantUpdate) {
        return this.http.put(this.url + '/update', updatedTenant);
    }

    block(id: number, blocked: Boolean) {
        var request = this.url + '/Block?id=' + id + '&blocked=' + blocked;
        return this.http.put(request, {});
    }

    delete(id: number) {
        return this.http.delete(this.url + '/delete?id=' + id);
    }


}