<!--Statistics cards Starts-->
<div class="row">
	<div class="col-xl-3 col-lg-6 col-md-6 col-12">
		<div class="card gradient-blackberry">
			<div class="card-content">
				<div class="card-body pt-2 pb-0">
					<div class="media">
						<div class="media-body white text-left">
							<h3 class="font-large-1 mb-0">{{item.countDriversOnLine}}</h3>
							<span>{{'DriversOnLine' | translate}}</span>
						</div>
						<div class="media-right white text-right">
							<i class="icon-pie-chart font-large-1"></i>
						</div>
					</div>
				</div>
				
				<!-- <div id="Widget-line-chart" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
					<x-chartist class="" [data]="WidgetlineChart.data" 
										[type]="WidgetlineChart.type" 
										[options]="WidgetlineChart.options">
					</x-chartist>
				</div>   -->
				
			</div>
		</div>
	</div>
	<div class="col-xl-3 col-lg-6 col-md-6 col-12">
		<div class="card gradient-ibiza-sunset">
			<div class="card-content">
				<div class="card-body pt-2 pb-0">
					<div class="media">
						<div class="media-body white text-left">
							<h3 class="font-large-1 mb-0">{{item.countCompletedOrders}}</h3>
							<span>Completed orders for day</span>
						</div>
						<div class="media-right white text-right">
							<i class="icon-bulb font-large-1"></i>
						</div>
					</div>
                </div>

				<!-- <div id="Widget-line-chart" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
					<x-chartist class="" [data]="WidgetlineChart.data" 
										[type]="WidgetlineChart.type" 
										[options]="WidgetlineChart.options">
					</x-chartist>
				</div> -->

			</div>
		</div>
	</div>
	
	<div class="col-xl-3 col-lg-6 col-md-6 col-12">
		<div class="card gradient-green-tea">
			<div class="card-content">
				<div class="card-body pt-2 pb-0">
					<div class="media">
						<div class="media-body white text-left">
							<h3 class="font-large-1 mb-0">{{item.revenueDaily}}</h3>
							<span>Daily revenue</span>
						</div>
						<div class="media-right white text-right">
							<i class="icon-graph font-large-1"></i>
						</div>
					</div>
                </div>

				<!-- <div id="Widget-line-chart" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
					<x-chartist class="" [data]="WidgetlineChart.data" 
										[type]="WidgetlineChart.type" 
										[options]="WidgetlineChart.options">
					</x-chartist>
                </div> -->

			</div>
		</div>
	</div>
	<div class="col-xl-3 col-lg-6 col-md-6 col-12">
		<div class="card gradient-pomegranate">
			<div class="card-content">
				<div class="card-body pt-2 pb-0">
					<div class="media">
						<div class="media-body white text-left">
							<h3 class="font-large-1 mb-0">{{item.revenutMonth}}</h3>
							<span>Month revenue</span>
						</div>
						<div class="media-right white text-right">
							<i class="icon-wallet font-large-1"></i>
						</div>
					</div>
                </div>
<!--
				<div id="Widget-line-chart" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
					<x-chartist class="" [data]="WidgetlineChart.data" 
										[type]="WidgetlineChart.type" 
										[options]="WidgetlineChart.options">
					</x-chartist>
                </div>
-->
			</div>
		</div>
	</div>
</div>
<!--Statistics cards Ends-->