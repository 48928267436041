import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";

export class Equipment{
    constructor(
        public id: number,
        public files: File[],
        public title?: string,
        public description?: string,
        public comment?: string,
        public createTime?: Date,
        public fileNames?: string[],
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class EquipmentAdapter implements Adapter<Equipment> {
    adapt(item: any): Equipment {
        return new Equipment(
            item.id,
            item.files,
            item.title,
            item.description,
            item.comment,
            new Date(item.createTime * 1000),
            item.fileNames
        )
    }

}