import { Component, OnInit } from "@angular/core";
import { LandlordService } from "./landlord.service";
import { Landlord } from "app/models/landlord/landlord";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import swal from 'sweetalert2';

@Component({
    selector: 'app-order',
    templateUrl: './landlord.component.html',
    providers: [LandlordService]
})
export class LandlordComponent implements OnInit {
    
    items: Landlord[];
    temp = [];

    constructor(private landlordService: LandlordService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService,
                private toastr: ToastrService) {

    }

    ngOnInit(): void {
        this.loadData();
    }

    private loadData(): void {
        this.landlordService.getAll().subscribe((data: Landlord[]) => {
            this.temp = [...data];
            this.items = data;
        })
    }

    updateFilter(event) {
        const val = event.target.value.toLowerCase();
        const temp = this.temp.filter(function (d) {
            return  d.firstName.toLowerCase().indexOf(val) !== -1 ||
                    d.lastName.toLowerCase().indexOf(val) !== -1 || 
                    d.email.toLowerCase().indexOf(val) !== -1 || !val;
        });

        this.items = temp;
    }

    private delete(id: number): void {
        this.landlordService.delete(id).subscribe(data => {
            this.loadData();
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    viewPage(id: number) {
        this.router.navigate(['/landlords/'+ id]);
    }

    block(id: number, blocked: boolean): void {
        this.landlordService.block(id, blocked).subscribe(data => {
          this.loadData();
          this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    confirmDelete(id: number) {
        swal.fire({
            title: this.translate.instant('DeleteIt?'),
            text: this.translate.instant('AbleRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.delete(id);
            }
        })
    }

    confirmBlock(id: number, blocked: boolean) {
        swal.fire({
            title: this.translate.instant('BlockIt?'),
            text: this.translate.instant('AbleRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.block(id, blocked);
            }
        })
    }
    
}