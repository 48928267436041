import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";
import { AgreementType } from "../enums/agreementType";

export class Agreement {
    constructor(
        public id: number,
        public title?: string,
        public description?: string,
        public type?: AgreementType,
        public createTime?: Date,
        public startDate?: Date,
        public endDate?: Date,
        public fileNames?: string[]
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class AgreementAdapter implements Adapter<Agreement> {
    adapt(item: any): Agreement {
        const createTime = item.createTime ? new Date(item.createTime * 1000) : undefined;
        const startDate = item.startDate ? new Date(item.startDate * 1000) : undefined;
        const endDate = item.endDate ? new Date(item.endDate * 1000) : undefined;

        return new Agreement(
            item.id,
            item.title,
            item.description,
            item.type,
            createTime,
            startDate,
            endDate,
            item.fileNames
        )
    }

}