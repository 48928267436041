import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from 'ng-chartist';

import { CommonCompanyData } from '../models/statistics/CommonCompanyData';
import { StatisticsService } from './statistics.service';
import { ActivatedRoute } from '@angular/router';

declare var require: any;
const data: any = require('../shared/data/chartist.json');

export interface Chart {
    type: ChartType;
    data: Chartist.IChartistData;
    options?: any;
    responsiveOptions?: any;
    events?: ChartEvent;
}

@Component({
  selector: 'app-dashboard1',
  templateUrl: './mainDashboard.component.html',
  providers: [StatisticsService]
})
export class MainDashboardComponent /*implements OnInit*/ {
//https://stackoverflow.com/questions/39428132/custom-elements-schema-added-to-ngmodule-schemas-still-showing-error/40407697
    item: CommonCompanyData;
     // line chart configuration Starts
    WidgetlineChart: Chart = {
        type: 'Line',
        data: data['WidgetlineChart'],
        options: {
            axisX: {
                showGrid: true,
                showLabel: false,
                offset: 0,
            },
            axisY: {
                showGrid: false,
                low: 40,
                showLabel: false,
                offset: 0,
            },
            lineSmooth: Chartist.Interpolation.cardinal({
                tension: 0
            }),
            fullWidth: true,
        },
    };

    constructor(private statisticsService: StatisticsService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        var startDate = this.getForFirstDayOfPreviousMonthUnixTime();
        var endDate = this.getCurrentUnixTime();
        this.statisticsService.getDashboardData(startDate, endDate)
            .subscribe((data: CommonCompanyData) => {
                this.item = data;
            })
    }

    private getForFirstDayOfPreviousMonthUnixTime(): number {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() - 1;
        const date = new Date(year, month, 1);
    
        return Math.floor(date.getTime() / 1000);
    }
    
    private getCurrentUnixTime(): number {
        return Math.floor(Date.now() / 1000);
    }
}