import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TaxiFareDynamicCreate } from '../models/taxifare/taxifareDynamicCreate';
import { TaxifareFixedCreate } from '../models/taxifare/taxifareFixedCreate';
import { TaxiFareDynamic } from '../models/taxifare/taxifaredynamic';
import { TaxifareFixed } from '../models/taxifare/taxifareFixed';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class TaxiFareService {
    private url = environment.baseApiurl + "/taxifare";

    constructor(private http: HttpClient,
                private sanitizer: DomSanitizer) {
    }

    getBaseTaxiFares() {
        return this.http.get(this.url + '/GetOnlyBaseModels');
    }

    delete(id: number, isDynamic: boolean) {
        return this.http.delete(this.url + '/delete?id=' + id + '&isDynamic=' + isDynamic);
    }

    getDynamicTaxiFare(id: number) {
        return this.http.get(this.url + '/GetDynamicByID?id=' + id);
    }

    getFixedTaxiFare(id: number) {
        return this.http.get(this.url + '/GetFixedByID?id=' + id);
    }

    createDynamicTaxiFare(newTaxiFare: TaxiFareDynamicCreate) {
        return this.http.post(this.url + '/CreateDynamic', newTaxiFare);
    }

    createFixedTaxiFare(newTaxiFare: TaxifareFixedCreate) {
        return this.http.post(this.url + '/CreateFixed', newTaxiFare);
    }

    updateDynamicTaxiFare(updatedTaxiFare: TaxiFareDynamic) {
        return this.http.put(this.url + '/UpdateDynamic', updatedTaxiFare);
    }

    updateFixedTaxiFare(updatedTaxiFare: TaxifareFixed) {
        return this.http.put(this.url + '/UpdateFixed', updatedTaxiFare);
    }

    getIcons() {
        var serverUrl = this.url.replace('/api/taxifare', '');
        return this.http.get(this.url + '/GetIcons')
            .pipe(
                map((icons: any[]) => {
                    return icons.map(icon => ({
                        name: icon.name,
                        pathToIcon: this.sanitizer.bypassSecurityTrustUrl(serverUrl + '/' + icon.pathToIcon)
                    }));
                })
            );
    }

    uploadIcon(icon: File) {
        const formData: FormData = new FormData();
        formData.append('file', icon);
        const headers = new HttpHeaders({
            'Accept': 'application/json'           
          });
        const options = { headers: headers };
        var uri = this.url + '/uploadIcon';
        return this.http.post(uri, formData, options);
    }

    deleteIcon(name: string) {
        return this.http.delete(this.url + '/deleteicon?name=' + name);
    }
}