<div class="row text-left">
    <div class="col-12">
        <div class="content-header">{{'TaxiFareTitle' | translate}}</div>
        <p class="content-sub-header">{{'TaxiFareDescription' | translate}}</p>
    </div>
</div>
    
<form class="form">
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title" id="basic-layout-form">{{'TaxiFareDynamicBaseInfoTitle' | translate}}</h4>
                    <p class="mb-0">{{'TaxiFareDynamicBaseInfoDescription' | translate}}</p>
                </div>
                <div class="card-content">
                    <div class="px-3">
                        <div class="">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="projectinput1">{{'Name' | translate}} *</label>
                                        <input [(ngModel)]="base.name" id="bname" class="form-control" name="name" 
                                            #name="ngModel" required maxlength="50" minlength="1" 
                                            placeholder="{{'EnterThe' | translate}}{{'Name' | translate}}">
                                        <div [hidden]="name.valid || name.untouched" class="text-danger">
                                            {{'FieldValidation1-50' | translate}}
                                        </div>
                                    </div>                                
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="projectinput1">{{'PercentageComission' | translate}} *</label>
                                        <input type="number" [(ngModel)]="base.percentageCompany" id="bpercentcompany" class="form-control" name="percentageCompany"
                                            #percentageCompany="ngModel" required max="100" min="0"
                                            placeholder="{{'EnterThe' | translate}}{{'PercentageComission' | translate}}">
                                        <div [hidden]="percentageCompany.valid || percentageCompany.untouched" class="text-danger">
                                            {{'FieldBetween0-100' | translate}}
                                        </div>
                                    </div>                       
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="projectinput2">{{'PercentageCancellation' | translate}} *</label>
                                        <input type="number" [(ngModel)]="base.percentageCancellation" id="bpercentcancel" class="form-control" name="percentageCancellation"
                                            #percentageCancellation="ngModel" required max="100" min="0" 
                                            placeholder="{{'EnterThe' | translate}}{{'PercentageCancellation' | translate}}">
                                        <div [hidden]="percentageCancellation.valid || percentageCancellation.untouched" class="text-danger">
                                            {{'FieldBetween0-100' | translate}}
                                        </div>
                                    </div>                              
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="projectinput2">{{'CountPlace' | translate}} *</label>
                                        <select class="form-control" [(ngModel)]="base.numberSeats"
                                                name="numberSeats" #numberSeats="ngModel" required>
                                            <option *ngFor="let place of places" value="{{place}}">
                                                {{place}}
                                            </option>
                                        </select>
                                        <div [hidden]="numberSeats.valid || numberSeats.untouched" class="text-danger">
                                            {{'FieldRequired' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="description">{{'Description' | translate}}*:</label>
                                        <textarea [(ngModel)]="base.description" id="bdescription" rows="7" class="form-control" name="description"
                                                #description="ngModel" required maxlength="2000"></textarea>
                                        <div [hidden]="description.valid || description.untouched" class="text-danger">
                                            {{'FieldValidation1-2000' | translate}}
                                        </div>
                                    </div>                              
                                </div>
                                <div class="col-md-3">
                                    <label>{{'PaymentMethods' | translate}}</label>
                                    <div class="form-group">
                                        <div class="col-md-12">
                                            <div class="custom-control custom-switch custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="base.hasCash" [checked]="base.hasCash"
                                                        name="hasCash" class="custom-control-input" id="checkCash">
                                                <label class="custom-control-label" for="checkCash">{{'Cash' | translate}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="custom-control custom-switch custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="base.hasTerminal" [checked]="base.hasTerminal" 
                                                        name="hasTerminal" class="custom-control-input" id="checkTerminal">
                                                <label class="custom-control-label" for="checkTerminal">{{'Terminal' | translate}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="custom-control custom-switch custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="base.hasCard" [checked]="base.hasCard" 
                                                        name="hasCard" class="custom-control-input" id="checkCard">
                                                <label class="custom-control-label" for="checkCard">{{'PaymentCard' | translate}}</label>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                                <div class="col-md-3">
                                    <label for="carcountPlaces">{{'Image' | translate}} *</label>    
                                    <div class="form-group">                                
                                        <select class="form-control" [(ngModel)]="base.numberIcon" 
                                            name="numberIcon" #numberIcon="ngModel" required (change)="onIconChange()">
                                            <option *ngFor="let icon of icons" value="{{icon.name}}">
                                                <img src="{{icon.pathToIcon}}">{{icon.name}}
                                            </option>
                                        </select>
                                        <img [src]="selectedIcon?.pathToIcon"  class="selected-icon taxifare-icon">
                                    </div>                                                                                              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title" id="basic-layout-form">{{'Cost' | translate}}</h4>
                </div>
                <div class="card-content">
                    <div class="px-3">
                        <div class="form-body">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="projectinput1">{{'MinCost' | translate}}*:</label>
                                        <input type="number" [(ngModel)]="tax.minCost" id="tfmincost" class="form-control" name="minCost"
                                                #minCost="ngModel" required max="1000000" min="0" 
                                                placeholder="{{'EnterThe' | translate}}{{'MinCost' | translate}}">
                                        <div [hidden]="minCost.valid || minCost.untouched" class="text-danger">
                                            {{'FieldBetween0-1000000' | translate}}
                                        </div>
                                    </div>                            
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="projectinput2">{{'FreeDistance' | translate}}(km)*:</label>
                                        <input type="number" [(ngModel)]="tax.kmFree"  id="tfkmFree" class="form-control" name="kmFree"
                                                #kmFree="ngModel" required max="600" min="0" 
                                                placeholder="{{'EnterThe' | translate}}{{'FreeDistance' | translate}}">
                                        <div [hidden]="kmFree.valid || kmFree.untouched" class="text-danger">
                                            {{'FieldBetween0-600' | translate}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="projectinput1">{{'FreeWaiting' | translate}}(min)*:</label>
                                        <input type="number" [(ngModel)]="tax.minutesFreeWainting" id="tfminutesFreeWainting" class="form-control" name="minutesFreeWainting"
                                                #minutesFreeWainting="ngModel" required max="600" min="0" 
                                                placeholder="{{'EnterThe' | translate}}{{'FreeWaiting' | translate}}">
                                        <div [hidden]="minutesFreeWainting.valid || minutesFreeWainting.untouched" class="text-danger">
                                            {{'FieldBetween0-600' | translate}}
                                        </div> 
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="projectinput2">{{'PaidWaiting' | translate}}*:</label>
                                        <input type="number" [(ngModel)]="tax.costPaidWaiting"  id="tfcostPaidWaiting" class="form-control" name="costPaidWaiting"
                                                #costPaidWaiting="ngModel" required max="1000000" min="0" 
                                                placeholder="{{'EnterThe' | translate}}{{'PaidWaiting' | translate}}">
                                        <div [hidden]="costPaidWaiting.valid || costPaidWaiting.untouched" class="text-danger">
                                            {{'FieldBetween0-1000000' | translate}}
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="projectinput1">{{'Cost1kmCity' | translate}}*:</label>
                                        <input type="number" [(ngModel)]="tax.costKmCity" id="tfcostKmCity" class="form-control" name="costKmCity"
                                                #costKmCity="ngModel" required max="1000000" min="0" 
                                                placeholder="{{'EnterThe' | translate}}{{'Cost1kmCity' | translate}}">
                                        <div [hidden]="costKmCity.valid || costKmCity.untouched" class="text-danger">
                                            {{'FieldBetween0-1000000' | translate}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="projectinput2">{{'Cost1kmCountrySide' | translate}}*:</label>
                                        <input type="number" [(ngModel)]="tax.costKmCountrySide"  id="tfcostKmCountrySide" class="form-control" name="costKmCountrySide"
                                                #costKmCountrySide="ngModel" required max="1000000" min="0" 
                                                placeholder="{{'EnterThe' | translate}}{{'Cost1kmCountrySide' | translate}}">
                                        <div [hidden]="costKmCountrySide.valid || costKmCountrySide.untouched" class="text-danger">
                                            {{'FieldBetween0-1000000' | translate}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                    <div class="right media">
                                        <button type="button" class="btn btn-raised btn-secondary mr-1" (click)="confirmCancel()"> 
                                            {{'Cancel' | translate}}
                                        </button>
                                        <button type="button" [disabled]="name.invalid || description.invalid || percentageCancellation.invalid || percentageCompany.invalid ||
                                                    costKmCity.invalid || costKmCountrySide.invalid || costPaidWaiting.invalid || minCost.invalid || 
                                                    minutesFreeWainting.invalid || kmFree.invalid"
                                                class="btn btn-raised btn-primary" (click)="createDynamicTaxiFare()"> 
                                            {{'Save' | translate}}
                                        </button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>