import { PromoCodeState} from '../enums/promocodestate';
import { PromoCodeType} from '../enums/promocodetype';

import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';

export class Promocode {
    constructor(
        public id: number,
        public name: string,
        public discountPercentage: number,
        public description?: string,
        public type?: PromoCodeType,
        public state?: PromoCodeState,
        public createTime?: Date,
        public startAt?: Date,
        public expirateAt?: Date
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class PromocodeAdapter implements Adapter<Promocode> {
    adapt(item: any): Promocode {
        return new Promocode(
            item.id,
            item.name,
            item.discountPercentage,
            item.description,
            item.type,
            item.state,
            new Date(item.createTime * 1000),
            new Date(item.startAt * 1000),
            new Date(item.expirateAt * 1000),
        )
    }
}