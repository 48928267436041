import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { NG_VALIDATORS, Validator, AbstractControl, Validators } from '@angular/forms'

import { CarPublicCreate } from '../models/car/carPublicCreate';
import { CarService } from './car.service';
import { AutoColor } from 'app/models/enums/autocolor';
import { Object } from 'core-js';
import { AutoStatus } from 'app/models/enums/autostatus';
import { CompanyService } from 'app/settings/company.service';
import { Company } from 'app/models/settings/company';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './add-car.component.html',
    providers: [CarService, CompanyService]
})
export class AddCarComponent implements OnInit {

    car: CarPublicCreate = new CarPublicCreate();
    colors: string[];
    statuses: string[];
    places: number[] = [];
    company: Company;

    constructor(private carService: CarService,
                private companyService: CompanyService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
       this.colors = Object.keys(AutoColor);
       this.statuses = Object.keys(AutoStatus);
       this.SetArraySeats();
    }

    createCar(): void {
        this.car.year = new Date(this.car.year, 1, 1, 12, 0, 0, 0).valueOf() / 1000;
        this.carService.createPublicCar(this.car).subscribe(data => {
            this.router.navigate(['/cars/allcars']);
        })
    }


    private SetArraySeats() {
        this.companyService.getCompany().subscribe((data: Company) => {
            if (data != null) {
                for (var i = data.minCountSeats; i <= data.maxCountSeats; i++) {
                    this.places.push(i);
                }
            }
        });
    }

    // Confirm Button Action
    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/cars/allcars']);
            }
        })
    }

}