import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import swal from 'sweetalert2';
import { EmployeeService } from "./employee.service";
import { EmployeeCreate } from "app/models/employee/employeeCreate";
import { Role } from "app/models/enums/role";
import { UserStatus } from "app/models/enums/userStatus";


@Component({
    selector: 'app-root',
    templateUrl: './add-Employee.component.html',
    providers: [EmployeeService]
})
export class AddEmployeeComponent implements OnInit {
    
    item: EmployeeCreate = new EmployeeCreate();
    roles: string[];
    userStatus: string[];

    constructor(private employeeService: EmployeeService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
        this.roles = Object.keys(Role);
        this.userStatus = Object.keys(UserStatus);
    }
    
    create(): void {
        this.employeeService.create(this.item).subscribe(data => {
            this.router.navigate(['/employees/allemployees']);
        })
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/employees/allemployees']);
            }
        })
    }

}