import { AutoStatus } from '../enums/autostatus';
import { AutoColor } from '../enums/autocolor';

export class CarPublicUpdate {
    constructor(
        public id: number,
        public make: string,
        public model: string,
        public year: number,
        public govermentNumber: string,
        public cabNumber: string,
        public countPlaces: number,
        public status: AutoStatus,
        public color: AutoColor
    ) {};
}