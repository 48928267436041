import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";
import { UserStatus } from "../enums/userStatus";

export class Tenant {
    constructor(
    public id: number,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public phoneNumber?: string,
    public status?: UserStatus,
    public createTime?: Date,
    public updateTime?: Date,
    public role?: number,
    public comment?: string
    ){}
}

@Injectable( {
    providedIn: 'root'
})
export class TenantAdapter implements Adapter<Tenant> {
    adapt(item: any): Tenant {
        return new Tenant(
            item.id,
            item.firstName,
            item.lastName,
            item.email,
            item.phoneNumber,
            item.status,
            new Date(item.createTime * 1000),
            new Date(item.updateTime * 1000),
            item.role,
            item.comment
        )
    }
}