import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";
import { Role } from "../enums/role";
import { UserStatus } from "../enums/userStatus";

export class Employee {
    constructor(
    public id: number,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public phoneNumber?: string,
    public status?: UserStatus,
    public createTime?: Date,
    public updateTime?: Date,
    public role?: Role,
    public position?: string,
    public comment?: string
    ){}
}

@Injectable( {
    providedIn: 'root'
})
export class EmployeeAdapter implements Adapter<Employee> {
    adapt(item: any): Employee {
        return new Employee(
            item.id,
            item.firstName,
            item.lastName,
            item.email,
            item.phoneNumber,
            item.status,
            new Date(item.createTime * 1000),
            new Date(item.updateTime * 1000),
            item.role,
            item.position,
            item.comment
        )
    }
}