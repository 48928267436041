import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';

export class Revenue {
    constructor(
        public totalSumWithCommission?: number,
        public totalSumWithoutComission?: number,
        public averageCostOrder?: number,
        public countOrders?: number
    ) {}
}

export class Distance {
    constructor(
        public totalDistance?: number,
        public averageDistance?: number,
        public countOrders?: number
    ) {}
}

export class TimeSpent {
    constructor(
        public totalTime?: number,
        public averageFeedTime?: number,
        public averageTimeWaiting?: number,
        public averageLeadTime?: number,
        public countOrders?: number
    ) {}
}

export class AggregateStatistic {
    constructor(
        public revenue?: Revenue,
        public distance?: Distance,
        public time?: TimeSpent
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class AggregateStatisticAdapter implements Adapter<AggregateStatistic> {
    adapt(item: any): AggregateStatistic {
        let revenue = new Revenue(
            item.revenue.totalSumWithCommission,
            item.revenue.totalSumWithoutComission,
            item.revenue.averageCostOrder,
            item.revenue.countOrders
        )
        let distance = new Distance(
            item.distance.totalDistance,
            item.distance.averageDistance,
            item.distance.countOrders
        )
        let time = new TimeSpent(
            item.time.totalTime,
            item.time.averageFeedTime,
            item.time.averageTimeWaiting,
            item.time.averageLeadTime,
            item.time.countOrders
        )
        return new AggregateStatistic(
            revenue,
            distance,
            time
        )
    }
}