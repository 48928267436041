import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";
import { EquipmentAdapter } from "app/models/equipment/equipment";
import { EquipmentCreate } from "app/models/equipment/equipmentCreate";
import { EquipmentUpdate } from "app/models/equipment/equipmentUpdate";

@Injectable()
export class EquipmentService {
    private url = environment.baseApiurl + "/equipment";

    constructor(private http: HttpClient, 
                private adapter: EquipmentAdapter) {
    }

    getAll(flatId: number) {
        return this.http.get(this.url + '/GetAllByFlatId?flatId=' + flatId)
            .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
    }

    getById(id: number) {
        return this.http.get(this.url + '/getById?id=' + id)
            .pipe(map((data: any) =>  this.adapter.adapt(data)));
    }

    create(newItem: EquipmentCreate) {
        const formData: FormData = new FormData();     
        for (const key in newItem) {
            if (newItem.hasOwnProperty(key)) {
                formData.append(key, (newItem as any)[key]);
            }
        }

        newItem.files.forEach(file => {
          formData.append('files', file, file.name);
        });

        return this.http.post(this.url + '/create', formData);
    }

    update(updatedItem: EquipmentUpdate) {
        const formData: FormData = new FormData();     
        for (const key in updatedItem) {
            if (updatedItem.hasOwnProperty(key)) {
                formData.append(key, (updatedItem as any)[key]);
            }
        }

        updatedItem.files.forEach(file => {
          formData.append('files', file, file.name);
        });

        return this.http.put(this.url + '/update', formData);
    }

    delete(id: number) {
        return this.http.delete(this.url + '/delete?id=' + id);
    }
}