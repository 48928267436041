<div class="row text-left">
    <div class="col-12">
        <div class="content-header">{{'FeedbackManagerTitle' | translate}}</div>
        <p class="content-sub-header">{{'FeedbackManagerDescription' | translate}}</p>
    </div>
</div>
<section id="all-cars">
    <div class="row text-left">
        <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{'AllFeedbacksTitle' | translate}}</h4>          
                <p>{{'AllFeedbacksDescription' | translate}}</p>
            </div>    
            <div class="card-content"> 
            <div class="card-body">
                <table class="table table-responsive-md text-center">
                <thead>
                    <tr>
                        <th>{{'Trip date' | translate}}</th>
                        <th>{{'Rating' | translate}}</th>
                        <th>{{'Comment' | translate}}</th>
                        <th>{{'Client' | translate}}</th>
                        <th>{{'Driver' | translate}}</th>                      
                        <th>{{'Actions' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let order of item.items">    
                        <td>{{order.createTime | date:'dd/MM/yy h:mm:ss a'}}</td> 
                        <td>{{order.feedback.rating}}</td>                                                       
                        <td>{{order.feedback.comment}}</td>
                        <td><a href="clients/{{order.user.id}}">{{order.user.phoneNumber}}</a></td>
                        <td><a href="drivers/{{order.driver.id}}">{{order.driver.phoneNumber}}</a></td>                                    
                        <td>
                        <a class="info p-0" data-original-title="" title="Get more info or edit" href="orders/{{order.id}}">
                            <i class="ft-info font-medium-3 mr-2"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
                </table>
                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info">{{'Showing' | translate}} {{item.page.showingItems}} {{'from' | translate}} {{item.page.totalItems}} {{'items' | translate}}</div>
                        <div class="dataTables_info">{{'TotalPages' | translate}}: {{item.page.totalPages}}</div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                        <nav aria-label="Page navigation mb-3">
                            <ul class="pagination justify-content-end">
                                <li class="paginate_button page-item first">
                                    <a href="orders/allorders?page={{item.page.firstPage}}" class="page-link">{{'First' | translate}}</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="orders/allorders?page={{item.page.previousPage}}" aria-label="Previous">
                                        <span aria-hidden="true">« {{'Prev' | translate}}</span>
                                        <span class="sr-only">{{'Previous' | translate}}</span>
                                    </a>
                                </li>
    
                                <div *ngIf="item.page.hasPreviousPage">
                                    <li class="page-item"><a class="page-link" href="orders/allorders?page={{item.page.previousPage}}">{{item.page.previousPage}}</a></li>
                                </div>                           
                                <li class="page-item active"><a class="page-link" href="#">{{item.page.pageNumber}}</a></li>                         
                                <div *ngIf="item.page.hasNextPage">
                                    <li class="page-item"><a class="page-link" href="orders/allorders?page={{item.page.nextPage}}">{{item.page.nextPage}}</a></li>
                                </div>  
                                
                                <li class="page-item">
                                    <a class="page-link" href="corders/allorders?page={{item.page.nextPage}}" aria-label="Next">
                                        <span aria-hidden="true">{{'Next' | translate}} »</span>
                                        <span class="sr-only">{{'Next' | translate}}</span>
                                    </a>
                                </li>
                                <li class="paginate_button page-item last">
                                    <a href="orders/allorders?page={{item.page.lastPage}}"  class="page-link">{{'Last' | translate}}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
</section>
          