import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';

import { DriverWorkStatus } from '../enums/driverworkstatus';
import { Car } from '../car/car';
import { TaxifareBase } from '../taxifare/taxifarebase';
import { Sex } from '../enums/sex';

export class Driver {
    constructor(
        public id: number,
        public firstname: string,
        public secondname: string,
        public lastname: string,
        public email: string,
        public phoneNumber: string,
        public isBlocked: boolean,
        public isVerify: boolean,
        public createTime: Date,
        public updateTime: Date,
        public car: Car,
        public hasPrivateCar: boolean,
        public workStatus: DriverWorkStatus,
        public taxiFares: TaxifareBase[],
        public pathToAvatar: string,
        public sex: Sex,
        public lastLocationLat?: number,
        public lastLocationLng?: number,
        public pathToFrontSide?: string,
        public pathToBackSide?: string,
        public driverLisenceStartDate?: Date,
        public driverLisenceEndDate?: Date,
        public comment?: string,
        public rating?: string
    ) {};
}


@Injectable({
providedIn: 'root'
})
export class DriverAdapter implements Adapter<Driver> {
    adapt(item: any): Driver {
        let car: Car;
        if (item.car != null) {
            let converterYear: Date = (item.car.year == null) ? null : new Date(item.car.year * 1000);
            car = new Car(
                item.car.id,
                item.car.make,
                item.car.model,
                converterYear.getFullYear(),
                item.car.govermentNumber,
                item.car.cabNumber, 
                item.car.countPlaces,
                item.car.isPrivate,
                item.car.status,
                item.car.color
                )
        } else {
            car = null;
        }

        let licenseStartDate = (item.driverLisenceStartDate == null) ? null :  new Date(item.driverLisenceStartDate * 1000);
        let licenseEndDate = (item.driverLisenceEndDate == null) ? null : new Date(item.driverLisenceEndDate * 1000);
        let ratingValue = (item.rating == 0) ? '-' : item.rating.toFixed(1);

        return new Driver(
            item.id,
            item.firstname,
            item.secondname,
            item.lastname,
            item.email,
            item.phoneNumber,
            item.isBlocked,
            item.isVerify,
            new Date(item.createTime * 1000),
            new Date(item.updateTime * 1000),
            car,
            item.hasPrivateCar,
            item.workStatus,
            item.taxiFares,
            item.pathToAvatar,
            item.sex,
            item.lastLocationLat,
            item.lastLocationLng,
            item.pathToFrontSide,
            item.pathToBackSide,
            licenseStartDate,
            licenseEndDate,
            item.comment,
            ratingValue
            )
    }
}