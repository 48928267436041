import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';


export class Notification {
    constructor(
        public title?: string,
        public description?: string,
        public createTime?: Date
    ) {}
}


//Adapter for nested items https://stackoverflow.com/questions/59875080/in-angular-how-to-use-adapter-pattern-when-http-response-has-array-of-objects
@Injectable({
    providedIn: 'root'
})
export class NotificationAdapter implements Adapter<Notification> {
    adapt(item: any): Notification {
        let convertedTime = (item.createTime == null) ? null : new Date(item.createTime * 1000);
        return new Notification(
            item.title,
            item.description,
            convertedTime
        )
    }
}