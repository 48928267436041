import { RentalContractStatus } from "../enums/rentalContractStatus";

export class RentalContractCreate{
    constructor(
        public files: File[] = [],
        public status?: RentalContractStatus,
        public startDate?: number,
        public endDate?: number,
        public rentPrice?: number,
        public hgmtFee?: number,
        public flatId?: number,
        public tenantId?: number,
        public employeeId?: number
    ) {}
}