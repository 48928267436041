import { Component, OnInit } from "@angular/core";
import { StaffCreate } from "app/models/staff/staffCreate";
import { StaffService } from "./staff.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import swal from 'sweetalert2';
import { EmployeeRole } from "app/models/enums/employeeRole";

@Component({
    selector: 'app-root',
    templateUrl: './add-staff.component.html',
    providers: [StaffService]
})
export class AddStaffComponent implements OnInit {

    item: StaffCreate = new StaffCreate();
    roles: string[];

    constructor(
        private staffService: StaffService,
        private router: Router,
        private translate: TranslateService) {

    }

    ngOnInit(): void {
        this.roles = Object.keys(EmployeeRole);
    }

    createStaff(): void {
        this.staffService.create(this.item).subscribe(data => {
            this.router.navigate(['/staff/allstaff']);
        });
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/staff/allstaff']);
            }
        })
    }
}