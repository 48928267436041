import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";

export class UserProfile {
    constructor(
        public id: number,
        public firstName?: string,
        public lastName?: string,
        public email?: string,
        public phoneNumber?: string
    ){}
}

@Injectable( {
    providedIn: 'root'
})
export class UserProfileAdapter implements Adapter<UserProfile> {
    adapt(item: any): UserProfile {
        return new UserProfile(
            item.id,
            item.firstName,
            item.lastName,
            item.email,
            item.phoneNumber
        )
    } 
}