import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import swal from 'sweetalert2';
import { TenantService } from "./tenant.service";
import { TenantCreate } from "app/models/tenant/tenantCreate";
import { UserStatus } from "app/models/enums/userStatus";

@Component({
    selector: 'app-root',
    templateUrl: './add-tenant.component.html',
    providers: [TenantService]
})
export class AddTenantComponent implements OnInit {
    
    item: TenantCreate = new TenantCreate();
    userStatus: string[];

    constructor(private tenantService: TenantService,
                private route: ActivatedRoute,
                private router: Router,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
        this.userStatus = Object.keys(UserStatus);
    }
    
    create(): void {
        this.tenantService.create(this.item).subscribe(data => {
            this.router.navigate(['/tenants/alltenants']);
        })
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/tenants/alltenants']);
            }
        })
    }

}