import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';
import { map } from "rxjs/operators";

import { Car } from './car';
import { Page } from '../common/page';
import { CarSortState } from './carSortState';

export class CarIndex {
    constructor(
        public cars: Car[],
        public page: Page,
        public sort: CarSortState
    ){}
}


@Injectable({
    providedIn: 'root'
})
export class CarIndexAdapter implements Adapter<CarIndex> {
    adapt(data: any): CarIndex {
        let mappedCars: Car[] = [];
        if (data.cars != null) {
            for (let car of data.cars) {
                let converterYear: Date = new Date(car.year * 1000);
                let newCar: Car = new Car(
                    car.id,
                    car.make,
                    car.model,
                    converterYear.getFullYear(),
                    car.govermentNumber,
                    car.cabNumber,
                    car.countPlaces,
                    car.isPrivate,
                    car.status,
                    car.color
                )
                mappedCars.push(newCar);
            }
        }

        let page: Page;
        if (data.page != null) {
            page = new Page(
                data.page.pageNumber,
                data.page.totalPages,
                data.page.totalItems,
                mappedCars.length,
                data.page.hasPreviousPage,
                data.page.hasNextPage,
                data.page.previousPage,
                data.page.nextPage,
                data.page.firstPage,
                data.page.lastPage
            )
        }

        return new CarIndex(
            mappedCars,
            page,
            data.sort.currentOrder
        )
    }
}