import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";
import { MeterType } from "../enums/meterType";
import { MeterCategory } from "../enums/meterCategory";

export class MeterUpdate{
    constructor(
        public files: File[] = [],
        public id: number,
        public type?: MeterType,
        public category?: MeterCategory,
        public value?: number
    ){}
}

@Injectable( {
    providedIn: 'root'
})
export class MeterAdapterUpdate implements Adapter<MeterUpdate> {
    adapt(item: any): MeterUpdate {
        return new MeterUpdate(
            item.files,
            item.id,
            item.type,
            item.category,
            item.value
        )
    }

}