<div class="row text-left">
    <div class="col-12">
        <div class="content-header">{{'PromocodeManagerTitle' | translate}}</div>
        <p class="content-sub-header">{{'PromocodeManagerDescription' | translate}}</p>
    </div>
</div>
<section id="all-cars">
<div class="row text-left">
    <div class="col-sm-12">
    <div class="card">
        <div class="card-header">
            <h4 class="card-title">{{'AllPromocodesTitle' | translate}}</h4>
            <p>{{'AllPromocodesDescription' | translate}}</p>
        </div>    
        <div class="card-content">
            <div class="card-body">
                <!--<table class="table table-responsive-md text-center">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{{'Title' | translate}}</th>
                            <th>{{'Discount' | translate}}(%)</th>
                            <th>{{'StartDate' | translate}}</th>
                            <th>{{'EndDate' | translate}}</th>
                            <th>{{'Actions' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let code of item.items">
                            <td>
                                <a href="/promocodes/{{code.id}}">{{code.id}}</a>
                            </td>
                            <td>{{code.name}}</td>
                            <td>{{code.discountPercentage}}</td>
                            <td>{{code.startAt | date:'dd/MM/yy h:mm:ss a' }}</td>
                            <td>{{code.expirateAt | date:'dd/MM/yy h:mm:ss a' }}</td>
                            <td>
                                <a class="danger p-0" data-original-title="" title="you can remove it" (click)="confirmDeletePromocode(code.id)">
                                    <i class="ft-x font-medium-3 mr-2"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info">{{'Showing' | translate}} {{item.page.showingItems}} {{'from' | translate}} {{item.page.totalItems}} {{'items' | translate}}</div>
                        <div class="dataTables_info">{{'TotalPages' | translate}}: {{item.page.totalPages}}</div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                        <nav aria-label="Page navigation mb-3">
                            <ul class="pagination justify-content-end">
                                <li class="paginate_button page-item first">
                                    <a href="promocodes/allpromocodes?page={{item.page.firstPage}}" class="page-link">{{'First' | translate}}</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="promocodes/allpromocodes?page={{item.page.previousPage}}" aria-label="Previous">
                                    <span aria-hidden="true">« {{'Prev' | translate}}</span>
                                    <span class="sr-only">{{'Previous' | translate}}</span>
                                    </a>
                                </li>

                                <div *ngIf="item.page.hasPreviousPage">
                                    <li class="page-item"><a class="page-link" href="promocodes/allpromocodes?page={{item.page.previousPage}}">{{item.page.previousPage}}</a></li>
                                </div>                           
                                <li class="page-item active"><a class="page-link" href="#">{{item.page.pageNumber}}</a></li>                         
                                <div *ngIf="item.page.hasNextPage">
                                    <li class="page-item"><a class="page-link" href="promocodes/allpromocodes?page={{item.page.nextPage}}">{{item.page.nextPage}}</a></li>
                                </div>  
                                
                                <li class="page-item">
                                    <a class="page-link" href="promocodes/allpromocodes?page={{item.page.nextPage}}" aria-label="Next">
                                    <span aria-hidden="true">{{'Next' | translate}} »</span>
                                    <span class="sr-only">{{'Next' | translate}}</span>
                                    </a>
                                </li>
                                <li class="paginate_button page-item last">
                                    <a href="promocodes/allpromocodes?page={{item.page.lastPage}}"  class="page-link">{{'Last' | translate}}</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>-->
                <!--WORKING VERSION
                <ngx-datatable class="bootstrap core-bootstrap" [rows]="rows" 
                    [columns]="[{name:'id'}, {name:'name'}, {name:'discountPercentage'}, 
                                {name:'description'}, {name:'type'}, {name:'startAt'}, {name:'expirateAt'}]"
                    [columnMode]="'auto'" [headerHeight]="50" [footerHeight]="50"
                    [rowHeight]="'auto'" [limit]="3"> 
                </ngx-datatable> -->
                <fieldset class="form-group">
                    <label for="ngx-filter-ref">Search:
                    <input id="ngx-filter-ref" class="form-control form-control-sm d-inline-block width-200" type="text"
                        placeholder="Filter the Name column..." (keyup)="filterUpdate($event)" />
                    </label>
                </fieldset>
                <ngx-datatable class="bootstrap core-bootstrap"  
                    [columnMode]="'force'"
                    [headerHeight]="50" 
                    [footerHeight]="50" 
                    [rowHeight]="'auto'" 
                    [limit]="10"
                    [rows]="rows" 
                    [scrollbarH]="true" >
                    <ngx-datatable-column name="#" prop="id" [width]="50"></ngx-datatable-column>
                    <ngx-datatable-column name="{{'Title' | translate}}" prop="name" ></ngx-datatable-column> 
                    <ngx-datatable-column name="{{'Discount' | translate}}" >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.discountPercentage}}%
                        </ng-template>
                    </ngx-datatable-column> 
                    <ngx-datatable-column name="{{'Description' | translate}}" prop="description" [width]="400"></ngx-datatable-column> 
                    <ngx-datatable-column name="{{'Type' | translate}}" prop="type" >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            <div class="badge badge-pill" 
                                [ngClass]="{'bg-light-primary': row.type == 'Reusable', 
                                            'bg-light-success': row.type == 'Disposable'}">
                            {{row.type}}
                          </div>
                        </ng-template>
                    </ngx-datatable-column> 
                    <ngx-datatable-column name="{{'StartDate' | translate}}" >
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.startAt | date:'dd/MM/yy h:mm a'}}
                        </ng-template>
                    </ngx-datatable-column> 
                    <ngx-datatable-column name="{{'EndDate' | translate}}">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{row.expirateAt | date:'dd/MM/yy h:mm a'}}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Actions">
                        <ng-template let-row="row"  ngx-datatable-cell-template>
                            <a class="danger p-0" data-original-title="" title="delete" (click)="confirmDeletePromocode(row.id)">
                                <i class="ft-trash text-primary cursor-pointer mr-2"></i>
                            </a>
                        </ng-template>
                    </ngx-datatable-column>  
                </ngx-datatable> 
            </div>
        </div>
    </div>
    </div>
</div>
</section>
