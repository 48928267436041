import { Injectable } from '@angular/core';
import { DriverSortState } from './driverSortState';
import { Page } from '../common/page';
import { Driver } from './driver';
import { Adapter } from 'app/core/interfaces/adapter';
import { Car } from '../car/car';

export class DriverIndex {
    constructor(
        public items: Driver[],
        public page: Page,
        public sort: DriverSortState
    ) {}
}

@Injectable({
    providedIn: 'root'
})
export class DriverIndexAdapter implements Adapter<DriverIndex> {
    adapt(data: any): DriverIndex {
        let mappedDrivers: Driver[] = [];
        if (data.drivers != null) {
            for (let item of data.drivers) {
                let car: Car;
                if (item.car != null) {
                    let converterYear: Date = new Date(item.car.year * 1000);
                    car = new Car(
                        item.car.id, 
                        item.car.make,
                        item.car.model,
                        converterYear.getFullYear(),
                        item.car.govermentNumber,
                        item.car.cabNumber,
                        item.car.countPlaces,
                        item.car.isPrivate,
                        item.car.status,
                        item.car.color
                        )
                } else {
                    car = null;
                }

                let licenseStartDate = (item.driverLisenceStartDate == null) ? null :  new Date(item.driverLisenceStartDate * 1000);
                let licenseEndDate = (item.driverLisenceEndDate == null) ? null : new Date(item.driverLisenceEndDate * 1000);
                let ratingValue = (item.rating == 0) ? '-' : item.rating.toFixed(1);

                let newDriver = new Driver(
                    item.id,
                    item.firstname,
                    item.secondname,
                    item.lastname,
                    item.email,
                    item.phoneNumber,
                    item.isBlocked,
                    item.isVerify,
                    new Date(item.createTime * 1000),
                    new Date(item.updateTime * 1000),
                    car,
                    item.hasPrivateCar,
                    item.workStatus,
                    item.taxiFares,
                    item.pathToAvatar,
                    item.sex,
                    item.lastLocationLat,
                    item.lastLocationLng,
                    item.pathToFrontSide,
                    item.pathToBackSide,
                    licenseStartDate,
                    licenseEndDate,
                    item.comment,
                    ratingValue
                )
                mappedDrivers.push(newDriver);
            }
        }

        let page: Page;
        if (data.page != null) {
            page = new Page(
                data.page.pageNumber,
                data.page.totalPages,
                data.page.totalItems,
                mappedDrivers.length,
                data.page.hasPreviousPage,
                data.page.hasNextPage,
                data.page.previousPage,
                data.page.nextPage,
                data.page.firstPage,
                data.page.lastPage
            )
        }

        return new DriverIndex(
            mappedDrivers,
            page,
            data.sort.currentOrder
        )
    }
}