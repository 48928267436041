import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';

export class NotificationCreate {
    constructor(
        public title?: string,
        public description?: string,
        public isClient?: boolean
    ) {}
}

//Adapter for nested items https://stackoverflow.com/questions/59875080/in-angular-how-to-use-adapter-pattern-when-http-response-has-array-of-objects
//delete it after (example)
@Injectable({
    providedIn: 'root'
})
export class NotificationCreateAdapter implements Adapter<NotificationCreate> {
    adapt(item: any): NotificationCreate {
        return new NotificationCreate(
            item.title,
            item.description
        )
    }
}