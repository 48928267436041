import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import swal from 'sweetalert2';

import { PromocodeService } from './promocode.service';
import { PromocodeCrete } from 'app/models/promocode/promocodeCreate';
import { PromoCodeType } from 'app/models/enums/promocodetype';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './add-promocode.component.html',
  providers: [PromocodeService]
})
export class AddPromocodeComponent implements OnInit {

  code: PromocodeCrete = new PromocodeCrete();
  types: string[];

  constructor(private router: Router,
              private promoService: PromocodeService,
              private translate: TranslateService) {

  }

  ngOnInit(): void {
      this.types = Object.keys(PromoCodeType);
  }

  createPromo(): void {
    //warning! in the future, you need fix work with time in the models
    this.code.expirateAt = (new Date(this.code.expirateAt)).valueOf() / 1000;
    this.code.startAt = (new Date(this.code.startAt)).valueOf() / 1000;

    this.promoService.createPromocode(this.code)
        .subscribe( data => {
          this.router.navigate(['/promocodes/allpromocodes']);
        });
  };


  // Confirm Button Action
  confirmCancel() {
    swal.fire({
        title: this.translate.instant('AreYouSure'),
        text: this.translate.instant('AnyUnsavedDataWillBeLost'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.translate.instant('Cancel'),
        confirmButtonText: this.translate.instant('Yes')
    }).then((result) => {
        if (result.value) {
            this.router.navigate(['/promocodes/allpromocodes']);
        }
    })
  }

}