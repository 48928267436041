<section  id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'FlatManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'FlatManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-content">
                    <div class="card-header pb-2">
                        <h4 class="card-title" id="horz-layout-colored-controls">{{'AddFlatTitle' | translate}}</h4>
                        <p class="mb-0">{{'AddFlatDescription' | translate}}</p>
                    </div>
                    <div class="card-body">
                        <div class="mt-2">
                            <form class="form">
                                <div class="form-body">
                                    <div class="row">                                  
                                        <div class="col-12 col-md-6 mb-3 mb-md-0">
                                            <h4 class="mb-3"><i class="ft-home mr-2"></i>{{'BaseInfo' | translate}}</h4>
                                            <div class="form-group">
                                                <label>{{'InternalId' | translate}} *</label>
                                                <input [(ngModel)]="item.internalId" placeholder="{{'EnterThe' | translate}}{{'internalId' | translate}}" 
                                                    name="internalId" class="form-control" 
                                                    id="internalId" #internalId="ngModel" 
                                                    type="number" required>
                                                <div [hidden]="internalId.valid || internalId.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'Title' | translate}} *</label>
                                                <input [(ngModel)]="item.title" placeholder="{{'EnterThe' | translate}}{{'Title' | translate}}" 
                                                    name="title" class="form-control" 
                                                    id="position" #title="ngModel" 
                                                    minlength="1" maxlength="50" required>
                                                <div [hidden]="title.valid || title.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'YearBuilt' | translate}} *</label>
                                                <input [(ngModel)]="item.yearBuilt" placeholder="{{'EnterThe' | translate}}{{'YearBuilt' | translate}}" 
                                                    name="yearBuilt" class="form-control" type="number" 
                                                    id="position" #yearBuilt="ngModel" required>
                                                <div [hidden]="yearBuilt.valid || yearBuilt.untouched" class="text-danger">
                                                    {{'FieldRequired' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'Description' | translate}} </label>
                                                <textarea [(ngModel)]="item.description" id="bdescription" rows="8" 
                                                    class="form-control" name="description"
                                                    #description="ngModel" maxlength="200"></textarea>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 mb-3 mb-md-0">
                                            <h4 class="mb-3"><i class="ft-layout mr-2"></i>{{'Size' | translate}}</h4>
                                            <div class="form-group">
                                                <label>{{'Size' | translate}} *</label>
                                                <input [(ngModel)]="item.size" placeholder="{{'EnterThe' | translate}}{{'Size' | translate}}" 
                                                    name="size" class="form-control" 
                                                    id="size" #size="ngModel" 
                                                    type="number" min="0" max="10000000" required>
                                                    <div [hidden]="size.valid || size.untouched" class="text-danger">
                                                        {{'FieldRequired' | translate}}
                                                    </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'Floor' | translate}} *</label>
                                                <input [(ngModel)]="item.floor" placeholder="{{'EnterThe' | translate}}{{'Floor' | translate}}" 
                                                    name="floor" class="form-control" 
                                                    id="floor" #floor="ngModel" 
                                                    type="number" min="0" max="10000000" required>
                                                <div [hidden]="floor.valid || floor.untouched" class="text-danger">
                                                    {{'FieldRequired' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'CountRooms' | translate}} *</label>
                                                <input [(ngModel)]="item.countRooms" placeholder="{{'EnterThe' | translate}}{{'CountRooms' | translate}}" 
                                                    name="countRooms" class="form-control" 
                                                    id="countRooms" #countRooms="ngModel" 
                                                    type="number" min="0" max="10000000" required>
                                                <div [hidden]="countRooms.valid || countRooms.untouched" class="text-danger">
                                                    {{'FieldRequired' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'CountParkingSpots' | translate}} *</label>
                                                <input [(ngModel)]="item.countParkingSpots" placeholder="{{'EnterThe' | translate}}{{'CountParkingSpots' | translate}}" 
                                                    name="countParkingSpots" class="form-control" 
                                                    id="countParkingSpots" #countParkingSpots="ngModel" 
                                                    type="number" min="0" max="10000000" required>
                                                <div [hidden]="countParkingSpots.valid || countParkingSpots.untouched" class="text-danger">
                                                    {{'FieldRequired' | translate}}
                                                </div>
                                            </div>                                        
                                        </div>
                                        <div class="col-12 col-md-6 mb-3 mb-md-0">                                       
                                            <h4 class="mb-3"><i class="ft-map mr-2"></i>{{'Location' | translate}}</h4>
                                            <div class="form-group">
                                                <label>{{'City' | translate}} *</label>
                                                <input [(ngModel)]="item.city" placeholder="{{'EnterThe' | translate}}{{'City' | translate}}" 
                                                    name="city" class="form-control" 
                                                    id="position" #city="ngModel" 
                                                    minlength="1" maxlength="50" required>
                                                <div [hidden]="city.valid || city.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'Street' | translate}} *</label>
                                                <input [(ngModel)]="item.street" placeholder="{{'EnterThe' | translate}}{{'Street' | translate}}" 
                                                    name="street" class="form-control" 
                                                    id="street" #street="ngModel" 
                                                    minlength="1" maxlength="50" required>
                                                <div [hidden]="street.valid || street.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'Building' | translate}} *</label>
                                                <input [(ngModel)]="item.building" placeholder="{{'EnterThe' | translate}}{{'Building' | translate}}" 
                                                    name="building" class="form-control" 
                                                    id="building" #building="ngModel" 
                                                    minlength="1" maxlength="50" required>
                                                <div [hidden]="building.valid || building.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'Apartment' | translate}} *</label>
                                                <input [(ngModel)]="item.apartment" placeholder="{{'EnterThe' | translate}}{{'Apartment' | translate}}" 
                                                    name="apartment" class="form-control" 
                                                    id="apartment" #apartment="ngModel" 
                                                    minlength="1" maxlength="50" required>
                                                <div [hidden]="apartment.valid || apartment.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'Postcode' | translate}} *</label>
                                                <input [(ngModel)]="item.postcode" placeholder="{{'EnterThe' | translate}}{{'Postcode' | translate}}" 
                                                    name="postcode" class="form-control" 
                                                    id="postcode" #postcode="ngModel" 
                                                    minlength="1" maxlength="50" required>
                                                <div [hidden]="postcode.valid || postcode.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-6 mb-3 mb-md-0">
                                            <h4 class="mb-3"><i class="ft-dollar-sign mr-2"></i>{{'Costs' | translate}}</h4>
                                            <div class="form-group">
                                                <label>{{'Price' | translate}} *</label>
                                                <input [(ngModel)]="item.price" placeholder="{{'EnterThe' | translate}}{{'price' | translate}}" 
                                                    name="price" class="form-control" 
                                                    id="position" #price="ngModel" 
                                                    type="number" min="0" max="10000000" required>
                                                    <div [hidden]="price.valid || price.untouched" class="text-danger">
                                                        {{'FieldRequired' | translate}}
                                                    </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'Deposit' | translate}} *</label>
                                                <input [(ngModel)]="item.deposit" placeholder="{{'EnterThe' | translate}}{{'Deposit' | translate}}" 
                                                    name="deposit" class="form-control" 
                                                    id="deposit" #deposit="ngModel" 
                                                    type="number" min="0" max="10000000" required>
                                                    <div [hidden]="deposit.valid || deposit.untouched" class="text-danger">
                                                        {{'FieldRequired' | translate}}
                                                    </div>
                                            </div> 
                                            <div class="form-group">
                                                <label>{{'AdminFees' | translate}} *</label>
                                                <input [(ngModel)]="item.adminFees" placeholder="{{'EnterThe' | translate}}{{'AdminFees' | translate}}" 
                                                    name="adminFees" class="form-control" 
                                                    id="adminFees" #adminFees="ngModel" 
                                                    type="number" min="0" max="10000000" required>
                                                    <div [hidden]="adminFees.valid || adminFees.untouched" class="text-danger">
                                                        {{'FieldRequired' | translate}}
                                                    </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'AccountNumber' | translate}} *</label>
                                                <input [(ngModel)]="item.accountNumber" placeholder="{{'EnterThe' | translate}}{{'AccountNumber' | translate}}" 
                                                    name="accountNumber" class="form-control" 
                                                    id="accountNumber" #accountNumber="ngModel" 
                                                    minlength="1" maxlength="50" required>
                                                <div [hidden]="accountNumber.valid || accountNumber.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'AccountNumber2' | translate}} *</label>
                                                <input [(ngModel)]="item.accountNumber2" placeholder="{{'EnterThe' | translate}}{{'AccountNumber2' | translate}}" 
                                                    name="accountNumber2" class="form-control" 
                                                    id="accountNumber2" #accountNumber2="ngModel" 
                                                    minlength="1" maxlength="50" required>
                                                <div [hidden]="accountNumber2.valid || accountNumber2.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'AccountNumber3' | translate}} *</label>
                                                <input [(ngModel)]="item.accountNumber3" placeholder="{{'EnterThe' | translate}}{{'AccountNumber3' | translate}}" 
                                                    name="accountNumber3" class="form-control" 
                                                    id="accountNumber3" #accountNumber3="ngModel" 
                                                    minlength="1" maxlength="50" required>
                                                <div [hidden]="accountNumber3.valid || accountNumber3.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label>{{'Balance' | translate}} *</label>
                                                <input type="number"  [(ngModel)]="item.balance"   
                                                    placeholder="{{'EnterThe' | translate}}{{'Balance' | translate}}" 
                                                    name="itemBalance" class="form-control"  required>
                                            </div>                
                                        </div>
                                    </div>
                                    <div class="row">                                  
                                        <div class="col-12 col-md-6 mb-3 mb-md-0">
                                            <h4 class="mb-3"><i class="ft-settings mr-2"></i>{{'Features' | translate}}</h4>
                                            <div class="form-group">
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" [(ngModel)]="item.hasBalcony" checked="item.hasBalcony"
                                                        class="custom-control-input" name="hasBalcony" id="hasBalcony">
                                                    <label class="custom-control-label" for="hasBalcony">
                                                        {{'HasBalcony' | translate}}
                                                    </label>
                                                </div>                                   
                                            </div>
                                            <div class="form-group">
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" [(ngModel)]="item.hasTerrace" checked="item.hasTerrace"
                                                        class="custom-control-input" name="hasTerrace" id="hasTerrace">
                                                    <label class="custom-control-label" for="hasTerrace">
                                                        {{'HasTerrace' | translate}}
                                                    </label>
                                                </div>                                   
                                            </div>
                                            <div class="form-group">
                                                <div class="custom-control custom-switch custom-control-inline">
                                                    <input type="checkbox" [(ngModel)]="item.petAllowed" checked="item.petAllowed"
                                                        class="custom-control-input" name="petAllowed" id="petAllowed">
                                                    <label class="custom-control-label" for="petAllowed">
                                                        {{'PetAllowed' | translate}}
                                                    </label>
                                                </div>                                   
                                            </div>                                             
                                        </div>
                                        <div class="col-12 col-md-6 mb-3 mb-md-0">
                                            <h4 class="mb-3"><i class="ft-user mr-2"></i>{{'Landlord' | translate}}</h4>
                                            <div class="form-group">
                                                <label>{{'Landlord' | translate}} *</label>
                                                <select class="form-control" [(ngModel)]="item.landlordId" 
                                                        name="landlordId" #landlordId="ngModel" required>
                                                    <option *ngFor="let landlord of landlords" value="{{landlord.id}}" > 
                                                        ID#{{landlord.id}} {{landlord.firstName}} {{landlord.lastName}}
                                                    </option>
                                                </select>
                                                <div [hidden]="landlordId.valid || landlordId.untouched" class="text-danger">
                                                    {{'FieldRequired' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">   
                                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                            <button type="button" [disabled]="title.invalid || yearBuilt.invalid || city.invalid || 
                                            street.invalid || building.invalid || apartment.invalid || postcode.invalid || price.invalid ||
                                            size.invalid || countRooms.invalid || deposit.invalid || adminFees.invalid || floor.invalid || 
                                            countParkingSpots.invalid"
                                                class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="create()"> 
                                                {{'Save' | translate}}
                                            </button>
                                            <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                                {{'Cancel' | translate}}
                                            </button> 
                                        </div>
                                    </div> 
                                   
                                </div>
                            </form>
                          </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>