import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import swal from 'sweetalert2';
import { TenantService } from "./tenant.service";
import { Tenant, TenantAdapter } from "app/models/tenant/tenant";
import { FlatService } from "app/flat/flat.service";
import { Flat } from "app/models/flat/flat";
import { UserStatus } from "app/models/enums/userStatus";

@Component( {
    selector: 'app-root',
    templateUrl: './profile-tenant.component.html',
    providers: [TenantService, FlatService]
})
export class ProfileTenantComponent implements OnInit {
    
    item: Tenant;
    flats: Flat[];
    userStatus: string[];

    constructor(private tenantService: TenantService,
                private flatService: FlatService,
                private route: ActivatedRoute,
                private router: Router,
                private adapter: TenantAdapter,
                private toastr: ToastrService,
                private translate: TranslateService) {

    }
    
    ngOnInit(): void {
        let id: number = Number(this.route.snapshot.paramMap.get("id"));
        this.getById(id);
        this.loadFlats(id);
        this.userStatus = Object.keys(UserStatus);
    }

    private getById(id: number) {
        this.tenantService.getById(id).subscribe((data: Tenant) => {
            this.item = data;
        });
    }

    private loadFlats(id: number) {
        this.flatService.getAllByTenantId(id).subscribe((data: Flat[]) => {
            this.flats = data;
        })
    }

    viewPage(id: number) {
        this.router.navigate(['/flats/'+ id]);
    }

    update(): void {
        let updatedModel = this.adapter.adapt(this.item);
        this.tenantService.update(updatedModel).subscribe(data => {
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/tenants/alltenants']);
            }
        })

    }

}