import { EmployeeRole } from "../enums/employeeRole";

export class StaffCreate {
    constructor(
    public email?: string,
    public name?: string,
    public comment?: string,
    public role?: EmployeeRole,
    public phoneNumber?: string,
    public password?: string
    ){}
}