<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-12">
            <div class="content-header">Staff manager</div>
            <p class="content-sub-header">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> 
        </div>
    </div> 
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title" id="horz-layout-colored-controls">Profile staff (ID: {{item.id}})</h4>
                    <p class="mb-0">lorem ipsum dolor</p>
                </div>
                <div class="card-content">
                    <form class="form">
                        <div class="px-0">
                            <form class="form">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="userinput1">{{'Phone' | translate}} *</label>
                                                <input [(ngModel)]="item.phoneNumber" placeholder="{{'EnterThe' | translate}}{{'Phone' | translate}}" name="phoneNumber" 
                                                        class="form-control" id="phone" #phoneNumber="ngModel" 
                                                        required pattern="[+][0-9]{8,12}"> 
                                                <div [hidden]="phoneNumber.valid || phoneNumber.untouched" class="text-danger">
                                                    {{'FieldPhoneValidation' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="userinput1">{{'Name' | translate}} *</label>
                                                <input [(ngModel)]="item.name" placeholder="{{'EnterThe' | translate}}{{'Name' | translate}}" name="name" 
                                                        class="form-control" id="name" #name="ngModel" 
                                                        pattern="[a-zA-Zа-яА-Я- ]{1,50}" required>
                                                <div [hidden]="name.valid || name.untouched" class="text-danger">
                                                    {{'FieldValidation1-50' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="userinput2">{{'Email' | translate}} *</label>
                                                <input type="email" [(ngModel)]="item.email" placeholder="{{'EnterThe' | translate}}{{'Email' | translate}}" name="email" 
                                                    class="form-control" id="email" #email="ngModel" 
                                                    pattern="[a-zA-Z0-9@-_-.]{10,50}" required>
                                                <div [hidden]="email.valid || email.untouched" class="text-danger">
                                                    {{'FieldEmailValidation' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="carcolor">Role *</label>
                                                <select class="form-control" [(ngModel)]="item.role" 
                                                        name="role" #role="ngModel" required>
                                                    <option *ngFor="let role of roles" value="{{role}}">
                                                        {{role}}
                                                    </option>
                                                </select>
                                                <div [hidden]="role.valid || role.untouched" class="text-danger">
                                                    {{'FieldRequired' | translate}}
                                                </div>
                                            </div>
                                        </div>                          
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="projectinput8">{{'Comment' | translate}}:</label>
                                                <textarea [(ngModel)]="item.comment" id="bdescription" rows="5" class="form-control" name="comment" 
                                                            #comment="ngModel"maxlength="2000"></textarea>
                                                <div [hidden]="comment.valid || comment.untouched" class="text-danger">
                                                    {{'FieldValidation1-2000' | translate}}
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    <div class="row">
                                        <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                            <div class="right">   
                                                <button type="button" [disabled]="name.invalid || email.invalid || phoneNumber.invalid || 
                                                                                role.invalid" 
                                                        class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="update()"> 
                                                    {{'Save' | translate}}
                                                </button>                   
                                                <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                                    {{'Cancel' | translate}}
                                                </button>                                   
                                            </div>
                                        </div>
                                    </div>
                                </div>                   
                            </form>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div> 
</section>