import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable()
export class FileService {
    private url = environment.baseApiurl + "/file";

    constructor(private http: HttpClient) {
    }

    getFile(itemName: string, fileName: string, id: number): Observable<Blob> {
        const url = `${this.url}/GetFile?itemName=${itemName}&fileName=${fileName}&id=${id}`;
    
        return this.http.get(url, {
          responseType: 'blob', // Expect a binary response
          observe: 'response',  // To access headers, if needed
        }).pipe(map((response: HttpResponse<Blob>) => {
            return response.body as Blob; // Return the file as a Blob
          })
        );
    }

    getArhive(itemName: string, id: number): Observable<Blob> {
        const url = `${this.url}/GetFilesZip?entityName=${itemName}&id=${id}`;
        
        return this.http.get(url, {
          responseType: 'blob',
          observe: 'response', 
        }).pipe(map((response: HttpResponse<Blob>) => {
            return response.body as Blob; 
          })
        );
    }

    saveFile(blob: Blob, fileName: string): void {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
    }

    deleteFile(itemName: string, fileName: string, id: number) {
        return this.http.delete(this.url + '/Delete?itemName=' + itemName + '&fileName=' + fileName + '&id=' + id);
    }
}