<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'PromocodeManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'PromocodeManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
            <div class="card-header pb-2">
                <h4 class="card-title" id="horz-layout-colored-controls">{{'AutogenerationPromocodesTitle' | translate}}</h4>
                <div [innerHTML]="'AutogenerationPromocodesDescription' | translate"></div>
            </div>
            <div class="card-content">
                <div class="px-3">
                <form class="form">
                    <div class="form-body">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="userinput1">{{'Count' | translate}}*: </label>
                                    <input type="number" [(ngModel)]="codes.count" placeholder="{{'EnterThe' | translate}}{{'Count' | translate}}" name="count" 
                                            class="form-control" id="count" #count="ngModel" max="100" min="0" required>
                                    <div [hidden]="count.valid || count.untouched" class="text-danger">
                                        {{'FieldBetween0-100' | translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="userinput2">{{'Discount' | translate}}(%)*: </label>
                                    <input type="number" [(ngModel)]="codes.discountPercentage" placeholder="{{'EnterThe' | translate}}{{'Discount' | translate}}" name="discountPercentage" 
                                        class="form-control" id="discountPercentage" #discountPercentage="ngModel" 
                                        max="100" min="0" required>
                                    <div [hidden]="discountPercentage.valid || discountPercentage.untouched" class="text-danger">
                                        {{'FieldBetween0-100' | translate}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="userinput1">{{'StartDate' | translate}}*: </label>
                                    <input type="date" class="form-control" id="startAt" name="startAt"
                                        [ngModel]="codes.startAt | date:'yyyy-MM-dd'"
                                        (ngModelChange)="codes.startAt = $event"
                                         #startAt="ngModel" required>
                                    <div [hidden]="startAt.valid || startAt.untouched" class="text-danger">
                                        {{'FieldRequired' | translate}}
                                    </div>
                                </div> 
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="userinput1">{{'EndDate' | translate}}*: </label>
                                    <input type="date" class="form-control" id="expirateAt" name="expirateAt"
                                        [ngModel]="codes.expirateAt | date:'yyyy-MM-dd'"
                                        (ngModelChange)="codes.expirateAt = $event" 
                                        #expirateAt="ngModel" required>
                                    <div [hidden]="expirateAt.valid || expirateAt.untouched" class="text-danger">
                                        {{'FieldRequired' | translate}}
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="projectinput8">{{'Description' | translate}}*:</label>
                                    <textarea [(ngModel)]="codes.description" id="bdescription" rows="5" class="form-control" name="description" 
                                                #description="ngModel" maxlength="90" minlength="1" required></textarea>
                                    <div [hidden]="description.valid || description.untouched" class="text-danger">
                                        {{'FieldValidation1-90"' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
    
                    <div class="form-actions right">
                         <!--<a href="/clients/allclients" class="btn btn-raised btn-warning mr-1"><i class="ft-x"></i> Cancel</a>-->
                        <button type="button" class="btn btn-raised btn-warning mr-1" (click)="confirmCancel()"> 
                            <i class="ft-x"></i> {{'Cancel' | translate}}
                        </button>
                        <button type="button" [disabled]="description.invalid || discountPercentage.invalid || 
                                                        startAt.invalid || expirateAt.invalid || count.invalid" 
                                class="btn btn-raised btn-primary" (click)="autoGeneration()"> 
                            <i class="fa fa-check-square-o"></i> {{'Save' | translate}}
                        </button>
                    </div>
                </form>
    
                </div>
            </div>
            </div>
        </div>
    </div>    
</section>