import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'landlords',
    loadChildren: () => import('../../landlord/landlord.module').then(m => m.LandlordModule)
  },
  {
    path: 'tenants',
    loadChildren: () => import('../../tenant/tenant.module').then(m => m.TenantModule)
  },
  {
    path: 'employees',
    loadChildren: () => import('../../employee/employee.module').then(m => m.EmployeeModule)
  },
  {
    path: 'documents',
    loadChildren: () => import('../../document/document.module').then(m => m.DocumentModule)
  },
  {
    path: 'flats',
    loadChildren: () => import('../../flat/flat.module').then(m => m.FlatModule)
  },
  {
    path: 'transactions',
    loadChildren: () => import('../../transaction/transaction.module').then(m => m.TransactionModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)
  },
  {
    path: 'charts',
    loadChildren: () => import('../../charts/charts.module').then(m => m.ChartsNg2Module)
  },
   {
    path: 'forms',
    loadChildren: () => import('../../forms/forms.module').then(m => m.FormModule)
  },
  {
    path: 'maps',
    loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule)
  },
  {
    path: 'tables',
    loadChildren: () => import('../../tables/tables.module').then(m => m.TablesModule)
  },
  {
    path: 'datatables',
    loadChildren: () => import('../../data-tables/data-tables.module').then(m => m.DataTablesModule)
  },
  {
    path: 'uikit',
    loadChildren: () => import('../../ui-kit/ui-kit.module').then(m => m.UIKitModule)
  },
  {
    path: 'components',
    loadChildren: () => import('../../components/ui-components.module').then(m => m.UIComponentsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  },
  {
    path: 'cards',
    loadChildren: () => import('../../cards/cards.module').then(m => m.CardsModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'chat-ngrx',
    loadChildren: () => import('../../chat-ngrx/chat-ngrx.module').then(m => m.ChatNGRXModule)
  },
  {
    path: 'inbox',
    loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)
  },
  {
    path: 'taskboard',
    loadChildren: () => import('../../taskboard/taskboard.module').then(m => m.TaskboardModule)
  },
  {
    path: 'taskboard-ngrx',
    loadChildren: () => import('../../taskboard-ngrx/taskboard-ngrx.module').then(m => m.TaskboardNGRXModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('../../user/user.module').then(m => m.UserModule)
  },
  {
    path: 'cars',
    loadChildren: () => import('../../car/car.module').then(m => m.CarModule)
  },
  {
    path: 'drivers',
    loadChildren: () => import('../../driver/driver.module').then(m => m.DriverModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('../../settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'orders',
    loadChildren: () => import('../../order/order.module').then(m => m.OrderModule)
  },
  {
    path: 'taxifares',
    loadChildren: () => import('../../taxifare/taxifare.module').then(m => m.TaxifareModule)
  },
  {
    path: 'statistics',
    loadChildren: () => import('../../statistics/statistics-routing.module').then(m => m.StatisticsRoutingModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('../../notification/notification-routing.model').then(m => m.NotificationRoutingModule)
  },
  {
    path: 'feedbacks',
    loadChildren: () => import('../../feedbacks/feedback-routing.model').then(m => m.FeedbackRoutingModule)
  },
  {
    path: 'promocodes',
    loadChildren: () => import('../../promocode/promocode-routing.model').then(m => m.PromocodeRoutingModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('../../profile/profile-routing.model').then(m => m.ProfileRoutingModule)
  },
  {
    path: 'staff',
    loadChildren: () => import('../../staff/staff-routing.module').then(m => m.StaffRoutingModule)
  }
];
