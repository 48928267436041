import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  { 
    path: '/flats/allflats', title: 'Flats', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  { 
    path: '/landlords/alllandlords', title: 'Landlords', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
  },
  { 
    path: '/tenants/alltenants', title: 'Tenants', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
  },
  { 
    path: '/employees/allemployees', title: 'Employees', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
  },
  { 
    path: '/documents/alldocuments', title: 'Documents', icon: 'ft-file', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
  },
  { 
    path: '/transactions/alltransactions', title: 'Transactions', icon: 'ft-dollar-sign', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] 
  }
  
];
