export enum AutoColor {
    White = 'White',
    Yellow = 'Yellow',
    Beige = 'Beige',
    Orange = 'Orange',
    Red = 'Red',
    LightGreen = 'Light Green',
    Green = 'Green',
    LightBlue = 'Light Blue',
    Blue = 'Blue',
    DarkBlue = 'Dark Blue',
    Purple = 'Purple',
    LightGrey = 'Light Grey',
    Grey = 'Grey',
    DarkGrey = 'Dark grey',
    Brown = 'Brown',
    Black = 'Black'
}