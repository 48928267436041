import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { User, UserAdapter } from '../models/client/user';
import { ClientSortState } from '../models/client/clientSortState';
import { UserCreate} from '../models/client/userCreate';
import { UserUpdate } from '../models/client/userUpdate';
import { data } from 'app/shared/data/smart-data-table';

//for test of paging
import { PagedData } from '../models/common/paged-data';
import { PageItem } from '../models/common/pageItem';
import { UserIndexAdapter } from 'app/models/client/userIndex';
import { environment } from 'environments/environment';


@Injectable()
export class ClientService {
  private url = environment.baseApiurl + "/AdminClient";

  constructor(private http: HttpClient,
              private adapter: UserAdapter,
              private adaperIndex: UserIndexAdapter) {
  }

  getClients() {
    return this.http.get(this.url + '/getAll')
      .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))));
  }

  getPage(page: number, size: number = 1, sort: ClientSortState = ClientSortState.IdDesc) {
    return this.http.get(this.url + '/getpage?page=' + page + '&order=' + sort + '&size=' + size)
            .pipe(map((data: any) =>  this.adaperIndex.adapt(data)));
  }

  getClient(clientId: number) {
    return this.http.get(this.url + '/getClient?clientId=' + clientId)
      .pipe(map((data: any) =>  this.adapter.adapt(data)));
  }

  blockClient(clientId: number) {
    var requestUrl = this.url + '/BlockClient?clientId=' + clientId;
    return this.http.put(requestUrl, null);
  }

  unblockClient(clientId: number) {
    var requestUrl = this.url + '/UnblockClient?clientId=' + clientId;
    return this.http.put(requestUrl, null);
  }

  createClient(newClient: UserCreate) {
    return this.http.post(this.url + '/createClient', newClient);
  }

  updateClient(updatedClient: UserUpdate) {
    return this.http.put(this.url + '/updateClient', updatedClient);
  }

  getXLSXwithClients() {
    return this.http.get(this.url + '/exportClientsToXLSX');
  }
}
