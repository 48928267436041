<section id="horizontal-form-layouts">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="content-header">{{'ClientManagerTitle' | translate}}</div>
            <p class="content-sub-header">{{'ClientManagerDescription' | translate}}</p>
        </div>
    </div>
    <div class="row text-left">
        <div class="col-md-12">
            <div class="card">
                <div class="card-header pb-2">
                    <h4 class="card-title" id="horz-layout-colored-controls">{{'CreateClientTitle' | translate}}</h4>
                    <p class="mb-0">{{'CreateClientDescription' | translate}}</p>
                </div>
                <div class="card-content">
                    <div class="px-3">
                        <form class="form">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="userinput1">{{'Phone' | translate}} *</label>
                                            <input [(ngModel)]="user.phonenumber" placeholder="{{'EnterThe' | translate}}{{'Phone' | translate}}" name="phonenumber" 
                                                    class="form-control" id="phone" #phonenumber="ngModel" 
                                                    required pattern="[+][0-9]{8,12}"> 
                                            <div [hidden]="phonenumber.valid || phonenumber.untouched" class="text-danger">
                                                {{'FieldPhoneValidation' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="userinput1">{{'Name' | translate}}</label>
                                            <input [(ngModel)]="user.name" placeholder="{{'EnterThe' | translate}}{{'Name' | translate}}" name="name" 
                                                    class="form-control" id="name" #name="ngModel" 
                                                    pattern="[a-zA-Zа-яА-Я- ]{1,50}">
                                            <div [hidden]="name.valid || name.untouched" class="text-danger">
                                                {{'FieldValidation1-50' | translate}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="userinput2">{{'Email' | translate}}</label>
                                            <input type="email" [(ngModel)]="user.email" placeholder="{{'EnterThe' | translate}}{{'Email' | translate}}" name="email" 
                                                class="form-control" id="email" #email="ngModel" 
                                                pattern="[a-zA-Z0-9@-_-.]{10,50}">
                                            <div [hidden]="email.valid || email.untouched" class="text-danger">
                                                {{'FieldEmailValidation' | translate}}
                                            </div>
                                        </div>
                                    </div>                          
                                </div> 
                                <div class="row">
                                    <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                                        <div class="right">   
                                            <button type="button" [disabled]="name.invalid || email.invalid || phonenumber.invalid" 
                                                    class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="createUser()"> 
                                                {{'Save' | translate}}
                                            </button>                   
                                            <button type="button" class="btn btn-secondary" (click)="confirmCancel()"> 
                                                {{'Cancel' | translate}}
                                            </button>                                   
                                        </div>
                                    </div>
                                </div>
                            </div>                   
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</section>