import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";
import { RentalContractStatus } from "../enums/rentalContractStatus";
import { Tenant } from "../tenant/tenant";
import { Employee } from "../employee/employee";

export class RentalContract{
    constructor(
        public id: number,
        public status?: RentalContractStatus,
        public createTime?: Date,
        public startDate?: Date,
        public endDate?: Date,
        public rentPrice?: number,
        public hgmtFee?: number,
        public tenant?: Tenant,
        public employee?: Employee,
        public fileNames?: string[]
    ) {}
}

@Injectable( {
    providedIn: 'root'
})
export class RentalContractAdapter implements Adapter<RentalContract> {
    adapt(item: any): RentalContract {
       return new RentalContract(
            item.id,
            item.status,
            new Date(item.createTime * 1000),
            new Date(item.startDate * 1000),
            new Date(item.endDate * 1000),
            item.rentPrice,
            item.hgmtFee,
            item.tenant,
            item.employee,
            item.fileNames
       )
    }

}