import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';

import { UserUpdate } from './userUpdate';

export class User {
    constructor(
      public id?: number,
      public name?: string,
      public email?: string,
      public phoneNumber?: string,
      public isBlocked?: boolean,
      public isVerify?: boolean,
      public registrationDate?: Date
    ) {}
}

@Injectable({
  providedIn: 'root'
})
export class UserAdapter implements Adapter<User> {
  adapt(item: any): User {
    return new User(
      item.id,
      item.name,
      item.email,
      item.phoneNumber,
      item.isBlocked,
      item.isVerify,
      new Date(item.registrationDate * 1000)
    )
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserUpdateAdapter implements Adapter<UserUpdate> {
  adapt(item: any): UserUpdate {
    return new UserUpdate(
      item.id,
      item.name,
      item.email,
      item.phoneNumber
    )
  }
}