import { Component, OnInit } from "@angular/core";
import { StaffService } from "./staff.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { StaffSortState } from "app/models/staff/staffSortState";
import { StaffIndex } from "app/models/staff/staffIndex";
import { Subscription } from "rxjs/internal/Subscription";
import swal from 'sweetalert2';

@Component({
    selector: 'app-root',
    templateUrl: './staff.component.html',
    providers: [StaffService] 
})
export class StaffComponent implements OnInit { 

    item: StaffIndex;
    page: number;
    size: number;
    sort: StaffSortState;

    private querySubscription: Subscription;

    constructor(private staffService: StaffService,
        private route: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.querySubscription = this.route.queryParams.subscribe(
          (queryParam: any) => {
              this.page = queryParam['page'];
              this.size = queryParam['size'];
              this.sort = queryParam['sort'];
          }
        );
        this.size = 20;
        this.loadPartItems(this.page, this.size, this.sort);
    }

    viewPage(id: any){
        this.router.navigate(['/staff/'+ id]);
    }

    /**
     * Populate the table with new data based on the page number
     * @param page The page to select
     */
    setPage(pageInfo) {
        this.loadPartItems(this.page, this.size, this.sort);
    }

    private loadPartItems(page: number = 1, size: number, sort: StaffSortState = StaffSortState.IdDesc): void {
        this.staffService.getPage(page, size, sort).subscribe((data: StaffIndex) => {
          this.item = data;
        });
    }

    private block(id: number): void {
        this.staffService.block(id).subscribe(data => {
            this.loadPartItems(this.page, this.size, this.sort);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }
    
    private unblock(id: number): void {
        this.staffService.unblock(id).subscribe(data => {
            this.loadPartItems(this.page, this.size, this.sort);
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    confirmBlock(id: number) {
        swal.fire({
            title: this.translate.instant('BlockIt?'),
            text: this.translate.instant('AbleRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.block(id);
            }
        })
    }

    confirmUnblock(id: number) {
        swal.fire({
            title: this.translate.instant('UnblockIt?'),
            text: this.translate.instant('AbleRevert'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.unblock(id);
            }
        })
    }
}