import { Component, OnInit } from "@angular/core";
import { StaffService } from "./staff.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import swal from 'sweetalert2';
import { Staff } from "app/models/staff/staff";
import { EmployeeRole } from "app/models/enums/employeeRole";
import { StaffUpdate, StaffUpdateAdapter } from "app/models/staff/staffUpdate";

@Component({
    selector: 'app-root',
    templateUrl: './profile-staff.component.html',
    providers: [StaffService]
})
export class ProfileStaffComponent implements OnInit {

    item: Staff;
    roles: string[];

    constructor(private staffService: StaffService,
                private adapter: StaffUpdateAdapter,
                private route: ActivatedRoute,
                private router: Router,
                private toastr: ToastrService,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
        let id : number = Number(this.route.snapshot.paramMap.get("id"));
        this.getById(id);
        this.roles = Object.keys(EmployeeRole);
    }

    update(): void {
        let updatedStaff: StaffUpdate = this.adapter.adapt(this.item);
        this.staffService.update(updatedStaff).subscribe(data => {
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private getById(id: number): void {
        this.staffService.getById(id).subscribe((data: Staff) => {
            this.item = data
            console.log(this.item);
        });
    }

    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/staff/allstaff']);
            }
        })
    }

}