<div class="row text-left">
    <div class="col-12">
        <div class="content-header">Staff manager</div>
        <p class="content-sub-header">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> 
    </div>
</div> 

<section id="all-cars">
    <div class="row text-left">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col-md-10">
                            <h4 class="card-title">All staff</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacinia pulvinar 
                                lorem vitae fringilla. Praesent varius arcu mauris, ut lobortis turpis elementum nec. 
                                Integer id neque in turpis varius malesuada. </p>
                        </div>
                    </div>
                </div>    
                <div class="card-content">
                    <div class="card-body">
                        <ngx-datatable 
                            [rows]="item.staff" 
                            [columnMode]="'force'"
                            class="bootstrap core-bootstrap ngx-datatable" 
                            [headerHeight]="50" 
                            [footerHeight]="50"
                            [externalPaging]="true"
                            [count]="item.page.totalItems"
                            [offset]="page"
                            [limit]="size"
                            rowHeight="auto" 
                            (page)="setPage($event)">
                            <ngx-datatable-column name="ID" prop="id" [width]="40"></ngx-datatable-column>
                            <ngx-datatable-column name="{{'Name' | translate}}" prop="name" ></ngx-datatable-column>
                            <ngx-datatable-column name="Role" prop="role" ></ngx-datatable-column>
                            <ngx-datatable-column name="{{'Email' | translate}}" prop="email" ></ngx-datatable-column>
                            <ngx-datatable-column name="{{'Phone' | translate}}" prop="phoneNumber" ></ngx-datatable-column>
                            <ngx-datatable-column name="{{'IsBlocked' | translate}}" prop="isBlocked" >
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                <div *ngIf="row.isBlocked; else blockBadge">
                                    <span _ngcontent-ykd-c258="" class="badge bg-light-danger mb-1">
                                    {{'Blocked' | translate}}
                                    </span>
                                </div>                      
                                <ng-template #blockBadge>
                                    <span _ngcontent-ykd-c258="" class="badge bg-light-secondary mb-1">
                                    {{'Active' | translate}}
                                    </span>                       
                                </ng-template>
                                </ng-template>
                            </ngx-datatable-column>   
                            <ngx-datatable-column name="{{'Joined' | translate}}">
                                <ng-template let-row="row" ngx-datatable-cell-template>
                                    {{row.createTime | date:'dd/MM/yyyy'}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Actions">
                                <ng-template let-row="row"  ngx-datatable-cell-template>
                                    <a class="danger p-0" data-original-title="" title="edit" (click)="viewPage(row.id)">
                                        <i class="ft-edit text-primary cursor-pointer"></i>
                                    </a>
                                    <div *ngIf="row.isBlocked; else blockActions">
                                        <a class="danger p-0" data-original-title="" title="block" (click)="confirmUnblock(row.id)">
                                            <i class="ft-trash text-primary cursor-pointer mr-2"></i>
                                        </a>  
                                    </div>                      
                                    <ng-template #blockActions>
                                        <a class="danger p-0" data-original-title="" title="block" (click)="confirmBlock(row.id)">
                                            <i class="ft-trash text-primary cursor-pointer mr-2"></i>
                                        </a>                       
                                    </ng-template>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

  
  
  
      