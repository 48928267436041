import { TaxiFareType } from '../enums/taxifaretype';

export class TaxifareBase {
    constructor(
        public id?: number,
        public name?: string,
        public description?: string,
        public pathToIcon?: string,
        public numberSeats?: number,
        public percentageCompany?: number,
        public percentageCancellation?: number,
        public type?: TaxiFareType,
        public hasCash?: boolean ,
        public hasTerminal?: boolean,
        public hasCard?: boolean ,
        public numberIcon?: number
    ) { }
}