import { Injectable } from "@angular/core";
import { Adapter } from "app/core/interfaces/adapter";
import { ExpensesType } from "../enums/expensesType";
import { ExpensesStatus } from "../enums/expensesStatus";

export class Expenses {
    constructor(
        public id: number,
        public type: ExpensesType,
        public status: ExpensesStatus,
        public createTime?: Date,
        public updateTime?: Date,
        public startDate?: Date,
        public value?: number,
        public accountNumber?: string,
        public comment?: string,
        public fileNames?: string[]
    ){}
}

@Injectable({
    providedIn: 'root'
})
export class ExpensesAdapter implements Adapter<Expenses> {
    adapt(item: any): Expenses {
        return new Expenses(
            item.id,
            item.type,
            item.status,
            new Date(item.createTime * 1000),
            new Date(item.updateTime * 1000),
            new Date(item.startDate * 1000),
            item.value,
            item.accountNumber,
            item.comment,
            item.fileNames
        )
    }

}