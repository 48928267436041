import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';

import { Car, CarUpdateAdapter } from '../models/car/car';
import { CarPublicUpdate } from '../models/car/carPublicUpdate';
import { CarService } from './car.service';
import { Company } from 'app/models/settings/company';
import { CompanyService } from 'app/settings/company.service';
import { AutoColor } from 'app/models/enums/autocolor';
import { AutoStatus } from 'app/models/enums/autostatus';
import { TranslateService } from '@ngx-translate/core';

import { NgSelectModule, NgOption } from '@ng-select/ng-select';

@Component({
    selector: 'app-root',
    templateUrl: './profile-car.component.html',
    styleUrls: ['./profile-car.component.css'],
    providers: [CarService, CompanyService]
})
export class ProfileCarComponent implements OnInit {

    car: Car;
    colors: string[];
    statuses: string[];
    places: number[] = [];
    company: Company;

    constructor(private carService: CarService,
                private companyService: CompanyService,
                private route: ActivatedRoute,
                private router: Router,
                private adapter: CarUpdateAdapter,
                private toastr: ToastrService,
                private translate: TranslateService) {

    }

    ngOnInit(): void {
        let carId : number = Number(this.route.snapshot.paramMap.get("id"));
        this.getById(carId);
        this.colors = Object.keys(AutoColor);
        this.statuses = Object.keys(AutoStatus);
        this.SetArraySeats();
    }

    updateCar(): void {
        let updatedCar : CarPublicUpdate = this.adapter.adapt(this.car);
        this.carService.updatePublicCar(updatedCar).subscribe(data => {
            this.toastr.success(this.translate.instant('DataSavedSuccessfully'), this.translate.instant('Success'), { closeButton: true });
        })
    }

    private getById(id: number): void {
        this.carService.getCar(id).subscribe((data: Car) => {
            this.car = data
        })
    };

    private SetArraySeats() {
        this.companyService.getCompany().subscribe((data: Company) => {
            if (data != null) {
                for (var i = data.minCountSeats; i <= data.maxCountSeats; i++) {
                    this.places.push(i);
                }
            }
        });
    }

    // Confirm Button Action
    confirmCancel() {
        swal.fire({
            title: this.translate.instant('AreYouSure'),
            text: this.translate.instant('AnyUnsavedDataWillBeLost'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.translate.instant('Cancel'),
            confirmButtonText: this.translate.instant('Yes')
        }).then((result) => {
            if (result.value) {
                this.router.navigate(['/cars/allcars']);
            }
        })
    }
  }