import { TaxifareBase } from './taxifarebase';

export class TaxiFareDynamicCreate {
    constructor(
        public baseInfo?: TaxifareBase,
        public minCost?: number,
        public costKmCity?: number,
        public costKmCountrySide?: number,
        public minutesFreeWainting?: number,
        public costPaidWaiting?: number,
        public kmFree?: number,
    ){}
}