import { Injectable } from '@angular/core';
import { Adapter } from '../../core/interfaces/adapter';

export class Company {
    constructor(
        public name: string,
        public licenseNumber: string,
        public description: string,
        public phoneSupportClient: string,
        public emailSupportClient: string,
        public phoneSupportDriver: string,
        public emailSupportDriver: string,
        public radius: number,
        public timeZone: string,
        public minCountSeats: number,
        public maxCountSeats: number,
        public lat: number,
        public lng: number,
        public privacyPolicy: string,
        public termsOfUse: string,
        public requestTimer: number,
        public searchTimer: number,
        public searchRadiusL1: number,
        public searchRadiusL2: number,
        public searchRadiusL3: number
    ) {};
}

@Injectable({
    providedIn: 'root'
})
export class CompanyAdapter implements Adapter<Company> {
    adapt(item: any): Company {
        return new Company(
            item.name,
            item.licenseNumber,
            item.description,
            item.phoneSupportClient,
            item.emailSupportClient,
            item.phoneSupportDriver,
            item.emailSupportDriver,
            item.radius,
            item.timeZone,
            item.minCountSeats,
            item.maxCountSeats,
            item.lat,
            item.lng,
            item.privacyPolicy,
            item.termsOfUse,
            item.requestTimer,
            item.searchTimer,
            item.searchRadiusL1,
            item.searchRadiusL2,
            item.searchRadiusL3
        )
    }
}